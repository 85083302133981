.slider-controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 40%;
    width: 100%;
    transform: translateY(-50%);
    pointer-events: none; /* To allow clicks to pass through to the slider */
}


.slider-control-prev,
.slider-control-next {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 15px;
    cursor: pointer;
    z-index: 1;
    pointer-events: all; /* To make buttons clickable */
}

.slider-control-prev .arrow-icon {
    animation: moveLeft 2s infinite;
}

.slider-control-next .arrow-icon {
    animation: moveRight 2s infinite;
}

.slider-control-prev {
    left: 10px;
}

.slider-control-next {
    right: 10px;
}
