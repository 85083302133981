* {
    margin: 0;
    border: 0;
    outline: none;
}

:root {
    --primary: #e32a30;
    --secondary: #1f5ba4;
    --white: #ffffff;
    --black: #000000;
}

html, body {
    height: 100%;
    font-size: 18px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #5e6f92;
    vertical-align: baseline;
    line-height: 30px;
    font-smoothing: antialiased;
    direction: rtl;
}

p {
    margin: 0 0 15px;
}

img {
    max-width: 100%;
    height: auto;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-family: "Poppins", sans-serif;
    color: #393d48;
    font-style: normal;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

h1 {
    font-size: 120px;
    line-height: 132px;
    font-weight: 700;
}

h2 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
}

h3 {
    font-size: 36px;
    line-height: 43px;
    font-weight: 700;
}

h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
}

h5 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
}

h6 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
}

label {
    color: #5e6f92;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

button {
    cursor: pointer;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a, .button {
    text-decoration: none;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

a:focus, .button:focus {
    text-decoration: none;
    outline: none;
}

a:focus {
    color: var(--secondary);
    text-decoration: none;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

a, button {
    color: var(--secondary);
    outline: medium none;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

a:hover, button:hover {
    color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

button:focus, input:focus, input:focus, textarea, textarea:focus {
    outline: 0;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

::-moz-selection {
    background: var(--secondary);
    text-shadow: none;
    color: #fff;
}

::selection {
    background: var(--secondary);
    text-shadow: none;
    color: #fff;
}

textarea:focus, input:focus {
    outline: none;
}

.background-black {
    background-color: rgb(57, 61, 72);
}

.bg-light-black {
    background-color: rgb(49, 55, 63);
}

.background-blue {
    background-color: #003869;
}

.bg-dark-blue {
    background-color: rgb(14, 49, 81);
}

.background-light-blue {
    background-color: var(--secondary);
}

.background-skye {
    background-color: var(--secondary);
}

.background-gray {
    background-color: #eef5f8;
}

.background-light-gray {
    background-color: #f3f7fc;
}

.page-bg {
    background-color: #020a27;
}

.page-area {
    padding-top: 136px;
    padding-bottom: 124px;
}

.blog-area {
    padding-top: 144px;
    padding-bottom: 104px;
}

@media (max-width: 991px) {
    .page-area, .blog-area {
        padding-top: 100px;
        padding-bottom: 52px;
    }
}

@media (max-width: 767px) {
    .page-area, .blog-area {
        padding-top: 70px;
        padding-bottom: 22px;
    }
}

.page-area .page-links, .blog-area .page-links {
    margin-bottom: 20px;
}

.border-radius-12 {
    border-radius: 12px;
}

.text-white-100 {
    color: #c7cdda;
}

code {
    color: var(--primary);
}

.theme-btn, .wp-block-loginout a, input.theme-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-image: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
    color: #fff;
    padding: 11px 36px 11px 10px;
    text-align: center;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    font-size: 18px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 36px;
    border-radius: 6px;
    overflow: hidden;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .theme-btn, .wp-block-loginout a, input.theme-btn {
        padding: 5px 26px 5px 6px;
        gap: 30px;
        font-size: 16px;
    }
}

.theme-btn:before, .wp-block-loginout a:before, input.theme-btn:before {
    position: absolute;
    width: 200%;
    height: 0%;
    right: 50%;
    top: 50%;
    background-image: linear-gradient(45deg, var(--secondary) 0%, var(--primary) 100%);
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
    content: "";
    z-index: -1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.theme-btn i, .wp-block-loginout a i, input.theme-btn i {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 6px;
    background-color: var(--secondary);
    color: #fff;
    font-size: 16px;
}

.theme-btn:hover, .wp-block-loginout a:hover, input.theme-btn:hover {
    color: #fff;
}

.theme-btn:hover:before, .wp-block-loginout a:hover:before, input.theme-btn:hover:before {
    height: 350%;
    -webkit-transition: all 500ms linear;
    transition: all 500ms linear;
    background-color: transparent;
}

.theme-btn:focus, .wp-block-loginout a:focus, input.theme-btn:focus {
    color: #ffffff;
}

.theme-btn.simple-btn, .wp-block-loginout a.simple-btn, input.theme-btn.simple-btn {
    padding-right: 36px;
    padding-left: 36px;
}

.wp-block-loginout a {
    position: relative;
}

.wp-block-loginout a:after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: "\f105";
    font-family: "Font Awesome 6 Pro";
    width: 44px;
    height: 44px;
    border-radius: 6px;
    background-color: var(--secondary);
    color: #fff;
    font-size: 16px;
}

.contact-form-btn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    display: block;
    line-height: 42px;
}

.contact-form-btn .icon {
    float: left;
}

input.theme-btn {
    display: inline-block !important;
    width: inherit !important;
    border: 0 !important;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-105 {
    margin-top: 105px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-115 {
    margin-top: 115px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-125 {
    margin-top: 125px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-135 {
    margin-top: 135px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-145 {
    margin-top: 145px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-155 {
    margin-top: 155px;
}

.mt-160 {
    margin-top: 160px;
}

.mt-165 {
    margin-top: 165px;
}

.mt-170 {
    margin-top: 170px;
}

.mt-175 {
    margin-top: 175px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-185 {
    margin-top: 185px;
}

.mt-190 {
    margin-top: 190px;
}

.mt-195 {
    margin-top: 195px;
}

.mt-200 {
    margin-top: 200px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-105 {
    margin-bottom: 105px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-115 {
    margin-bottom: 115px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-125 {
    margin-bottom: 125px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-135 {
    margin-bottom: 135px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-145 {
    margin-bottom: 145px;
}

.mb-150 {
    margin-bottom: 150px;
}

.mb-155 {
    margin-bottom: 155px;
}

.mb-160 {
    margin-bottom: 160px;
}

.mb-165 {
    margin-bottom: 165px;
}

.mb-170 {
    margin-bottom: 170px;
}

.mb-175 {
    margin-bottom: 175px;
}

.mb-180 {
    margin-bottom: 180px;
}

.mb-185 {
    margin-bottom: 185px;
}

.mb-190 {
    margin-bottom: 190px;
}

.mb-195 {
    margin-bottom: 195px;
}

.mb-200 {
    margin-bottom: 200px;
}

.mb-205 {
    margin-bottom: 205px;
}

.mb-210 {
    margin-bottom: 210px;
}

.mb-215 {
    margin-bottom: 215px;
}

.mb-220 {
    margin-bottom: 220px;
}

.mb-245 {
    margin-bottom: 245px;
}

.mb-250 {
    margin-bottom: 250px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-21 {
    padding-top: 21px;
}

.pt-22 {
    padding-top: 22px;
}

.pt-23 {
    padding-top: 23px;
}

.pt-24 {
    padding-top: 24px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-85 {
    padding-top: 85px;
}

.pt-86 {
    padding-top: 85px;
}

.pt-87 {
    padding-top: 85px;
}

.pt-88 {
    padding-top: 85px;
}

.pt-89 {
    padding-top: 85px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-95 {
    padding-top: 95px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-125 {
    padding-top: 125px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-135 {
    padding-top: 135px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-145 {
    padding-top: 145px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}

.pt-160 {
    padding-top: 160px;
}

.pt-165 {
    padding-top: 165px;
}

.pt-170 {
    padding-top: 170px;
}

.pt-175 {
    padding-top: 175px;
}

.pt-180 {
    padding-top: 180px;
}

.pt-185 {
    padding-top: 185px;
}

.pt-190 {
    padding-top: 190px;
}

.pt-195 {
    padding-top: 195px;
}

.pt-200 {
    padding-top: 200px;
}

.pt-250 {
    padding-top: 250px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-21 {
    padding-bottom: 21px;
}

.pb-22 {
    padding-bottom: 22px;
}

.pb-23 {
    padding-bottom: 23px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-135 {
    padding-bottom: 135px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-155 {
    padding-bottom: 155px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pb-165 {
    padding-bottom: 165px;
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-175 {
    padding-bottom: 175px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-185 {
    padding-bottom: 185px;
}

.pb-190 {
    padding-bottom: 190px;
}

.pb-195 {
    padding-bottom: 195px;
}

.pb-200 {
    padding-bottom: 200px;
}

.pl-0 {
    padding-right: 0px;
}

.pl-5 {
    padding-right: 5px;
}

.pl-10 {
    padding-right: 10px;
}

.pl-15 {
    padding-right: 15px;
}

.pl-20 {
    padding-right: 20px;
}

.pl-25 {
    padding-right: 35px;
}

.pl-30 {
    padding-right: 30px;
}

.pl-35 {
    padding-right: 35px;
}

.pl-35 {
    padding-right: 35px;
}

.pl-40 {
    padding-right: 40px;
}

.pl-45 {
    padding-right: 45px;
}

.pl-50 {
    padding-right: 50px;
}

.pl-55 {
    padding-right: 55px;
}

.pl-60 {
    padding-right: 60px;
}

.pl-65 {
    padding-right: 65px;
}

.pl-70 {
    padding-right: 70px;
}

.pl-75 {
    padding-right: 75px;
}

.pl-80 {
    padding-right: 80px;
}

.pl-85 {
    padding-right: 80px;
}

.pl-90 {
    padding-right: 90px;
}

.pl-95 {
    padding-right: 95px;
}

.pl-100 {
    padding-right: 100px;
}

.pl-105 {
    padding-right: 105px;
}

.pl-110 {
    padding-right: 110px;
}

.pl-115 {
    padding-right: 115px;
}

.pl-120 {
    padding-right: 120px;
}

.pl-125 {
    padding-right: 125px;
}

.pl-130 {
    padding-right: 130px;
}

.pl-135 {
    padding-right: 135px;
}

.pl-140 {
    padding-right: 140px;
}

.pl-145 {
    padding-right: 145px;
}

.pl-150 {
    padding-right: 150px;
}

.pl-155 {
    padding-right: 155px;
}

.pl-160 {
    padding-right: 160px;
}

.pl-165 {
    padding-right: 165px;
}

.pl-170 {
    padding-right: 170px;
}

.pl-180 {
    padding-right: 180px;
}

.pl-185 {
    padding-right: 185px;
}

.pl-190 {
    padding-right: 190px;
}

.pl-195 {
    padding-right: 195px;
}

.pl-100 {
    padding-right: 100px;
}

.pl-200 {
    padding-right: 200px;
}

.pr-0 {
    padding-left: 0px;
}

.pr-5 {
    padding-left: 5px;
}

.pr-10 {
    padding-left: 10px;
}

.pr-15 {
    padding-left: 15px;
}

.pr-20 {
    padding-left: 20px;
}

.pr-25 {
    padding-left: 35px;
}

.pr-30 {
    padding-left: 30px;
}

.pr-35 {
    padding-left: 35px;
}

.pr-35 {
    padding-left: 35px;
}

.pr-40 {
    padding-left: 40px;
}

.pr-45 {
    padding-left: 45px;
}

.pr-50 {
    padding-left: 50px;
}

.pr-55 {
    padding-left: 55px;
}

.pr-60 {
    padding-left: 60px;
}

.pr-65 {
    padding-left: 65px;
}

.pr-70 {
    padding-left: 70px;
}

.pr-75 {
    padding-left: 75px;
}

.pr-80 {
    padding-left: 80px;
}

.pr-85 {
    padding-left: 80px;
}

.pr-90 {
    padding-left: 90px;
}

.pr-95 {
    padding-left: 95px;
}

.pr-100 {
    padding-left: 100px;
}

.pr-105 {
    padding-left: 105px;
}

.pr-110 {
    padding-left: 110px;
}

.pr-115 {
    padding-left: 115px;
}

.pr-120 {
    padding-left: 120px;
}

.pr-125 {
    padding-left: 125px;
}

.pr-130 {
    padding-left: 130px;
}

.pr-135 {
    padding-left: 135px;
}

.pr-140 {
    padding-left: 140px;
}

.pr-145 {
    padding-left: 145px;
}

.pr-150 {
    padding-left: 150px;
}

.pr-155 {
    padding-left: 155px;
}

.pr-165 {
    padding-left: 165px;
}

.pr-170 {
    padding-left: 170px;
}

.pr-175 {
    padding-left: 175px;
}

.pr-180 {
    padding-left: 180px;
}

.pr-185 {
    padding-left: 185px;
}

.pr-190 {
    padding-left: 190px;
}

.pr-195 {
    padding-left: 195px;
}

.pr-200 {
    padding-left: 200px;
}

.py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.py-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.py-45 {
    padding-top: 45px;
    padding-bottom: 45px;
}

.py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.py-55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.py-65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.py-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.py-75 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.py-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.py-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.py-95 {
    padding-top: 95px;
    padding-bottom: 95px;
}

.py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.py-105 {
    padding-top: 105px;
    padding-bottom: 105px;
}

.py-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.py-115 {
    padding-top: 115px;
    padding-bottom: 115px;
}

.py-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.py-125 {
    padding-top: 125px;
    padding-bottom: 125px;
}

.py-130 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.py-135 {
    padding-top: 135px;
    padding-bottom: 135px;
}

.py-140 {
    padding-top: 140px;
    padding-bottom: 140px;
}

.py-145 {
    padding-top: 145px;
    padding-bottom: 145px;
}

.py-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.py-155 {
    padding-top: 155px;
    padding-bottom: 155px;
}

.py-160 {
    padding-top: 160px;
    padding-bottom: 160px;
}

.py-165 {
    padding-top: 165px;
    padding-bottom: 165px;
}

.py-170 {
    padding-top: 170px;
    padding-bottom: 170px;
}

.py-175 {
    padding-top: 175px;
    padding-bottom: 175px;
}

.py-180 {
    padding-top: 180px;
    padding-bottom: 180px;
}

.py-185 {
    padding-top: 185px;
    padding-bottom: 185px;
}

.py-190 {
    padding-top: 190px;
    padding-bottom: 190px;
}

.py-195 {
    padding-top: 195px;
    padding-bottom: 195px;
}

.py-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.py-205 {
    padding-top: 205px;
    padding-bottom: 205px;
}

.py-210 {
    padding-top: 210px;
    padding-bottom: 210px;
}

.py-215 {
    padding-top: 215px;
    padding-bottom: 215px;
}

.py-220 {
    padding-top: 220px;
    padding-bottom: 220px;
}

.py-225 {
    padding-top: 225px;
    padding-bottom: 225px;
}

.py-230 {
    padding-top: 230px;
    padding-bottom: 230px;
}

.py-235 {
    padding-top: 235px;
    padding-bottom: 235px;
}

.py-240 {
    padding-top: 240px;
    padding-bottom: 240px;
}

.py-250 {
    padding-top: 250px;
    padding-bottom: 250px;
}

.py-260 {
    padding-top: 260px;
    padding-bottom: 260px;
}

.py-270 {
    padding-top: 270px;
    padding-bottom: 270px;
}

.py-280 {
    padding-top: 280px;
    padding-bottom: 280px;
}

.py-290 {
    padding-top: 290px;
    padding-bottom: 290px;
}

.py-300 {
    padding-top: 300px;
    padding-bottom: 300px;
}

.py-310 {
    padding-top: 310px;
    padding-bottom: 310px;
}

.py-320 {
    padding-top: 320px;
    padding-bottom: 320px;
}

.py-330 {
    padding-top: 330px;
    padding-bottom: 330px;
}

.py-340 {
    padding-top: 340px;
    padding-bottom: 340px;
}

.py-350 {
    padding-top: 350px;
    padding-bottom: 350px;
}

.px-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.px-15 {
    padding-right: 15px;
    padding-left: 15px;
}

.px-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.px-25 {
    padding-right: 25px;
    padding-left: 25px;
}

.px-30 {
    padding-right: 30px;
    padding-left: 30px;
}

.px-35 {
    padding-right: 35px;
    padding-left: 35px;
}

.px-40 {
    padding-right: 40px;
    padding-left: 40px;
}

.px-45 {
    padding-right: 45px;
    padding-left: 45px;
}

.px-50 {
    padding-right: 50px;
    padding-left: 50px;
}

.px-55 {
    padding-right: 55px;
    padding-left: 55px;
}

.px-60 {
    padding-right: 60px;
    padding-left: 60px;
}

.px-65 {
    padding-right: 65px;
    padding-left: 65px;
}

.px-70 {
    padding-right: 70px;
    padding-left: 70px;
}

.px-75 {
    padding-right: 75px;
    padding-left: 75px;
}

.px-80 {
    padding-right: 80px;
    padding-left: 80px;
}

.px-85 {
    padding-right: 85px;
    padding-left: 85px;
}

.px-90 {
    padding-right: 90px;
    padding-left: 90px;
}

.px-95 {
    padding-right: 95px;
    padding-left: 95px;
}

.px-100 {
    padding-right: 100px;
    padding-left: 100px;
}

.px-105 {
    padding-right: 105px;
    padding-left: 105px;
}

.px-110 {
    padding-right: 110px;
    padding-left: 110px;
}

.px-115 {
    padding-right: 115px;
    padding-left: 115px;
}

.px-120 {
    padding-right: 120px;
    padding-left: 120px;
}

.px-125 {
    padding-right: 125px;
    padding-left: 125px;
}

.px-130 {
    padding-right: 130px;
    padding-left: 130px;
}

.px-135 {
    padding-right: 135px;
    padding-left: 135px;
}

.px-140 {
    padding-right: 140px;
    padding-left: 140px;
}

.px-145 {
    padding-right: 145px;
    padding-left: 145px;
}

.px-150 {
    padding-right: 150px;
    padding-left: 150px;
}

.px-155 {
    padding-right: 155px;
    padding-left: 155px;
}

.px-160 {
    padding-right: 160px;
    padding-left: 160px;
}

.px-165 {
    padding-right: 165px;
    padding-left: 165px;
}

.px-170 {
    padding-right: 170px;
    padding-left: 170px;
}

.px-175 {
    padding-right: 175px;
    padding-left: 175px;
}

.px-180 {
    padding-right: 180px;
    padding-left: 180px;
}

.px-185 {
    padding-right: 185px;
    padding-left: 185px;
}

.px-190 {
    padding-right: 190px;
    padding-left: 190px;
}

.px-195 {
    padding-right: 195px;
    padding-left: 195px;
}

.px-200 {
    padding-right: 200px;
    padding-left: 200px;
}

.px-205 {
    padding-right: 205px;
    padding-left: 205px;
}

.px-210 {
    padding-right: 210px;
    padding-left: 210px;
}

.px-215 {
    padding-right: 215px;
    padding-left: 215px;
}

.px-220 {
    padding-right: 220px;
    padding-left: 220px;
}

.px-225 {
    padding-right: 225px;
    padding-left: 225px;
}

.px-230 {
    padding-right: 230px;
    padding-left: 230px;
}

.px-235 {
    padding-right: 235px;
    padding-left: 235px;
}

.px-240 {
    padding-right: 240px;
    padding-left: 240px;
}

.px-250 {
    padding-right: 250px;
    padding-left: 250px;
}

.px-260 {
    padding-right: 260px;
    padding-left: 260px;
}

.px-270 {
    padding-right: 270px;
    padding-left: 270px;
}

.px-280 {
    padding-right: 280px;
    padding-left: 280px;
}

.px-290 {
    padding-right: 290px;
    padding-left: 290px;
}

.px-300 {
    padding-right: 300px;
    padding-left: 300px;
}

.px-310 {
    padding-right: 310px;
    padding-left: 310px;
}

.px-320 {
    padding-right: 320px;
    padding-left: 320px;
}

.px-330 {
    padding-right: 330px;
    padding-left: 330px;
}

.px-340 {
    padding-right: 340px;
    padding-left: 340px;
}

.px-350 {
    padding-right: 350px;
    padding-left: 350px;
}

body {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    overflow-x: hidden;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 25px;
}

ol {
    padding-right: 20px;
}

ul {
    list-style: disc;
    padding-right: 20px;
}

.post-password-form label {
    width: 100%;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
}

.post-password-form input[type=password] {
    font-size: 1.2rem;
    line-height: 20px;
    padding: 12px 20px;
    border: 1px solid #E4E4F2;
    width: 100%;
}

.post-password-form input[type=submit] {
    background-color: var(--secondary);
    border: none;
    padding: 10px 40px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 600;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-password-form input[type=submit]:hover {
    background-color: #393d48;
}

table {
    margin-bottom: 20px;
    width: 100%;
}

table caption {
    border: 1px solid #dfe2e9;
    padding: 6px 10px;
    border-top: 0;
}

table th, table td {
    background-color: transparent;
    border: 1px solid #dfe2e9;
    text-align: center;
    padding: 8px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

table th#today, table td#today {
    background: var(--secondary);
    color: #ffffff;
}

table th#today a, table td#today a {
    color: #ffffff;
}

.page-content .size-full.alignright {
    margin-bottom: 15px;
    margin-top: 20px;
}

.page-content figure.wp-caption.aligncenter {
    margin-top: 40px;
}

.page-content dl, .page-content ol, .page-content ul {
    margin-bottom: 11px;
}

.page-content .comment-respond {
    margin-top: 78px;
    margin-bottom: 20px;
}

.page-content .latest-comments .comment-respond {
    margin-bottom: 40px;
}

.page-content .post-comments {
    margin-top: 80px;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.screen-reader-text:focus {
    background-color: #eee;
    clip: auto !important;
    -webkit-clip-path: none;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    right: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}

.wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;
}

.wp-caption img[class*=wp-image-] {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.wp-caption .wp-caption-text {
    margin: 0.8075em 0;
    font-size: 14px;
    font-style: italic;
}

.wp-caption-text {
    text-align: center;
}

.gallery {
    margin-bottom: 10px;
}

.gallery-item {
    display: inline-block;
    max-width: 33.33%;
    text-align: center;
    vertical-align: top;
    width: 100%;
    padding-left: 5px !important;
}

.gallery-columns-1 .gallery-item {
    max-width: 100%;
    width: 100%;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery-caption {
    display: block;
}

.size-thumbnail {
    margin-bottom: 20px;
}

img.emoji {
    width: 15px;
    height: 15px;
}

.alignleft {
    display: inline;
    float: right;
    margin-left: 1.5em !important;
}

.alignright {
    display: inline;
    float: left;
    margin-right: 1.5em !important;
}

.aligncenter {
    clear: both;
    display: block;
    margin-right: auto !important;
    margin-left: auto !important;
    text-align: center;
}

.wp-block-search__input {
    border: 1px solid #dfe2e9 !important;
}

.wp-block-search__button {
    margin-right: 0 !important;
}

.wp-block-social-links {
    padding-right: 0 !important;
}

.wp-block-social-links li {
    padding-top: 0 !important;
}

.single-post-item.format-quote blockquote {
    padding: 45px;
}

@media (max-width: 767px) {
    .single-post-item.format-quote blockquote {
        padding: 35px;
    }
}

blockquote {
    overflow: hidden;
    margin: 40px 0;
    position: relative;
    z-index: 1;
    padding-right: 60px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    blockquote {
        padding-right: 30px;
    }
}

blockquote p {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    color: #5e6f92;
    line-height: 32px;
    font-weight: 400;
    font-style: italic;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    blockquote p {
        font-size: 18px;
        line-height: 28px;
    }
}

blockquote p a {
    color: var(--secondary);
    text-decoration: underline;
}

blockquote footer {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    color: #393d48;
    margin-top: 20px;
}

.wp-block-quote, blockquote {
    border-right: solid 5px var(--secondary);
}

.wp-block-pullquote {
    border-top: 5px solid var(--secondary) !important;
    border-bottom: 5px solid var(--secondary) !important;
    background-color: #eef5f8;
}

.wp-block-quote__citation, .wp-block-quote cite, .wp-block-quote footer {
    margin-top: 10px;
    display: block;
}

.wp-block-column:not(:first-child), .wp-block-column:nth-child(2n) {
    margin-right: 10px;
}

.wp-block-column:not(:last-child) {
    margin-left: 10px;
}

.wp-block-column:nth-child(2n+1) {
    margin-left: 10px;
}

.post-content > .alignwide {
    margin-right: 0;
    margin-left: 0;
    width: calc(100% + 20px);
    max-width: calc(100% + 20px);
}

.post-content p.has-large-font-size {
    font-size: 36px;
    line-height: 1.2;
}

.post-content .wp-block-search {
    margin-bottom: 15px;
}

.post-content .wp-block-file {
    margin-top: 20px;
}

.post-content .wp-block-group__inner-container p {
    margin-top: 0 !important;
}

.has-very-light-gray-color.has-very-light-gray-color {
    color: #fff;
    background: var(--secondary) !important;
}

.wp-block-button {
    margin-bottom: 10px;
}

.wp-block-archives select, .wp-block-categories select {
    height: 50px;
    width: 200px;
    border: 1px solid #ddd;
    padding: 0 10px;
    margin-bottom: 10px;
}

.wp-block-latest-comments {
    padding-right: 0 !important;
}

.wp-block-cover p.wp-block-cover-text, .wp-block-cover.is-light .wp-block-cover__inner-container {
    color: #fff !important;
}

.wp-block-cover-text a {
    color: #fff;
}

.wp-block-rss {
    padding-right: 0 !important;
}

.widget .wp-block-archives-dropdown, .widget .wp-block-categories-dropdown, .widget .wp-block-loginout, .widget .wp-block-social-links, .widget.widget_archive select, .widget.widget_categories select, .widget.widget_text select, .widget.widget_search .search-form, .widget.widget_tag_cloud .tagcloud, .widget .wp-block-tag-cloud {
    margin-bottom: 6px;
}

.wp-block-latest-posts .wp-block-latest-posts__post-title {
    display: inline-block;
    width: 100%;
}

nav.wp-calendar-nav {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

nav.wp-calendar-nav a {
    color: #5e6f92;
}

nav.wp-calendar-nav a:hover {
    color: var(--secondary);
}

.page-content .wp-smiley, .entry-content .wp-smiley, .comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

embed, iframe, object {
    max-width: 100%;
}

.comment-respond.style-two .wpcf7-form .wpcf7-not-valid-tip {
    color: #d9c0b8 !important;
}

.comment-respond .wpcf7-form .wpcf7-not-valid {
    border-color: #dc3232 !important;
}

.comment-respond .wpcf7-form .wpcf7-not-valid-tip {
    font-size: 16px;
    margin-top: 5px;
}

.comment-respond .wpcf7-form.sent .wpcf7-response-output {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #72DC60;
    padding: 15px 20px;
    text-align: center;
    position: relative;
    background: #F4FFF5;
    border: 1px solid #CDF5C8 !important;
    border-radius: 10px;
}

.comment-respond .wpcf7-form.invalid .wpcf7-response-output {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #dc3232;
    padding: 15px 20px;
    text-align: center;
    position: relative;
    background: #FFF7F7;
    border: 1px solid #FED8D8 !important;
    border-radius: 10px;
}

input[type=checkbox], input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    width: 22px !important;
    height: 22px !important;
    border-color: rgb(223, 226, 233);
    border-radius: 3px !important;
    background-color: transparent !important;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative !important;
    padding: 0 !important;
    cursor: pointer;
    margin-top: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    align-self: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

input[type=radio]::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: var(--secondary);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

input[type=checkbox]::before {
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.44899 7.76355C3.36328 7.84971 3.24641 7.89784 3.12495 7.89784C3.00349 7.89784 2.88662 7.84971 2.80091 7.76355L0.213156 5.17534C-0.0554271 4.90676 -0.0554271 4.47134 0.213156 4.20321L0.537198 3.87917C0.805781 3.61059 1.24074 3.61059 1.50932 3.87917L3.12495 5.4948L7.49057 1.12917C7.75916 0.860589 8.19457 0.860589 8.4627 1.12917L8.78674 1.45321C9.05532 1.7218 9.05532 2.15721 8.78674 2.42534L3.44899 7.76355Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 8px;
    height: 100%;
    width: 100%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: var(--secondary);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

input[type=checkbox]:checked::before, input[type=radio]:checked::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
}

input[type=checkbox]:checked, input[type=radio]:checked {
    border-color: var(--secondary) !important;
}

.wpcf7-form-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wpcf7-form-control .wpcf7-list-item {
    margin: 0;
}

.wpcf7-form-control .wpcf7-list-item label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    margin: 0;
    gap: 10px;
}

.wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
    font-size: 16px;
    font-weight: 400;
}

.logged-in .comment-respond textarea {
    margin-bottom: 12px;
}

.zoom-instagram-widget p {
    color: #ffffff;
}

.zoom-instagram-widget p a {
    color: var(--primary);
}

.zoom-instagram-widget ul {
    list-style: none;
}

.zoom-instagram-widget ul li {
    color: #ffffff;
}

body.admin-bar .header-menu-area.sticky_menu {
    top: 30px;
}

@media (max-width: 600px) {
    body.admin-bar .header-menu-area.sticky_menu {
        top: 0;
    }
}

.page-template-default.page-id-146 .page-content p:last-child {
    margin-bottom: 10px;
}

.page-template-default.page-id-1133 .page-content .comment-respond {
    margin-top: 78px;
    display: inline-block;
    width: 100%;
}

.page-template-default.page-id-3 .page-area {
    padding-top: 128px;
}

.post-template-default.postid-163 .blog-details-wrapper .single-post-item .post-content {
    margin-bottom: 34px;
}

.post-template-default.postid-150 .blog-details-wrapper .single-post-item .post-content {
    margin-bottom: 34px;
}

.post-template-default.postid-150 .blog-details-wrapper .single-post-item .post-content .wp-block-group.is-layout-constrained:last-child {
    border: 1px solid #000;
}

.post-template-default.postid-51 .blog-details-wrapper .single-post-item .post-content {
    margin-bottom: 25px;
}

.post-template-default.postid-51 .blog-details-wrapper .single-post-item .post-content p:last-child {
    margin-bottom: 0;
}

.post-template-default.postid-1163 .blog-details-wrapper .single-post-item .post-content .wp-caption, .post-template-default.postid-568 .blog-details-wrapper .single-post-item .post-content .wp-caption {
    margin-top: 20px;
}

.post-template-default.postid-1785 .blog-details-wrapper .single-post-item .post-content .wp-block-button__link {
    color: #ffffff !important;
}

.post-template-default.postid-1149 .blog-details-wrapper .latest-comments li .comments-box .comments-text p {
    margin-bottom: 0;
}

.post-template-default.postid-555 .blog-details-wrapper .single-post-item .post-content .gallery {
    margin-top: 20px;
}

.error-404.not-found {
    margin-top: -20px;
    margin-bottom: 40px;
}

.error-404.not-found .error-content .error-btn-bh {
    margin-top: 20px;
}

@-webkit-keyframes MovefadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(60px);
        transform: translateY(60px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes MovefadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(60px);
        transform: translateY(60px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.MovefadeUp {
    -webkit-animation-name: MovefadeUp;
    animation-name: MovefadeUp;
}

@-webkit-keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@-webkit-keyframes pulse {
    to {
        -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    }
}

@keyframes pulse {
    to {
        -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    }
}

@-webkit-keyframes animatelinebar {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    100% {
        -webkit-transform: translateX(-25px);
        transform: translateX(-25px);
    }
}

@keyframes animatelinebar {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    100% {
        -webkit-transform: translateX(-25px);
        transform: translateX(-25px);
    }
}

@-webkit-keyframes slideUpIn {
    0% {
        -webkit-transform: translateY(40px);
    }
    100% {
        -webkit-transform: translateY(0);
    }
    0% {
        -webkit-transform: translateY(40px);
    }
}

@keyframes slideUpIn {
    0% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    0% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px);
    }
}

@-webkit-keyframes MoveLeftRight {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

@keyframes MoveLeftRight {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

@-webkit-keyframes MoveUpDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@keyframes MoveUpDown {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@-webkit-keyframes Animated3 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

@keyframes Animated3 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

@-webkit-keyframes Animated4 {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

@keyframes Animated4 {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
}

@-webkit-keyframes UpToDown {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes UpToDown {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}

#preloader {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 9999999;
    left: 0;
    bottom: 0;
}

#preloader-status {
    width: 110px;
    position: absolute;
    right: 50%;
    top: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.loading-container div {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    float: right;
    margin: 0 3px;
    background-color: var(--secondary);
    -webkit-transform: scale(0);
    transform: scale(0);
}

.loading-container .ball1 {
    z-index: 1;
    -moz-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation: grow 4.4s infinite ease-in-out;
}

.loading-container .ball2 {
    -moz-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.loading-container .ball3 {
    -moz-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.loading-container .ball4 {
    -moz-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation: grow 4.4s infinite ease-in-out;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes grow {
    0% {
        -webkit-transform: scale(0);
    }
    25% {
        -webkit-transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0);
    }
    75% {
        -webkit-transform: scale(1);
        background: #ffffff;
    }
    100% {
        -webkit-transform: scale(0);
        background: #ffffff;
    }
}

.scrollup-wrapper {
    position: fixed;
    left: 40px;
    bottom: 20px;
    z-index: 9999;
}

.scrollup-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 26px;
    color: #ffffff;
    text-decoration: none;
    width: 50px;
    height: 50px;
    background-color: var(--secondary);
    border-radius: 50px;
    border: 2px solid;
    outline: 2px dashed var(--secondary);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.scrollup-btn i {
    margin-top: -3px;
    display: block;
}

.header-top {
    background-color: var(--secondary);
}

@media (max-width: 991px) {
    .header-top {
        display: none;
    }
}

.header-top .header-top-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 24px;
}

@media (max-width: 1199px) {
    .header-top .header-top-info {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .header-top .header-top-info {
        padding-bottom: 10px;
    }
}

.header-top .header-contact-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 24px;
    padding: 6px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.header-top .header-contact-info span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 24px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.header-top .header-contact-info span:first-child:before {
    display: none;
}

.header-top .header-contact-info span:before {
    background-color: rgb(255, 255, 255);
    opacity: 0.361;
    width: 1px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 12px;
    content: "";
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .header-top .header-contact-info span:before {
        display: none;
    }
}

.header-top .header-contact-info span.contact-info-item {
    gap: 0 10px !important;
}

.header-top .header-contact-info span a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .header-top .header-top-btn {
        display: none;
    }
}

.header-top .header-top-btn a {
    position: relative;
    padding: 12px 46px;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    margin-bottom: -100px;
    display: inline-block;
    font-weight: 700;
    z-index: 2;
}

.header-top .header-top-btn a:hover:before {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: var(--secondary);
}

.header-top .header-top-btn a:before {
    position: absolute;
    content: "";
    border-radius: 0 0 10px 10px;
    background-color: var(--primary);
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-top .social-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 25px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header-top .social-profile a {
    font-size: 16px;
    color: #ffffff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.header-top .social-profile a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    color: var(--secondary);
}

.header-menu-area {
    width: 100%;
    z-index: 999;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    background-color: #ffffff;
}

.header-menu-area.sticky-header {
    width: 100%;
    z-index: 999;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.header-menu-area.sticky_menu {
    right: 0;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 999;
    -webkit-box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.05);
    background: #fff;
}

.header-menu-area.sticky_menu .logo {
    height: 84px;
}

.header-menu-area.sticky_menu .logo:before {
    display: none;
}

.header-menu-area.sticky_menu .logo .standard-logo {
    display: none;
}

.header-menu-area.sticky_menu .logo .sticky-logo {
    display: block;
}

.header-menu-area.sticky_menu .logo .sticky-logo img {
    width: 120px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-menu-area.sticky_menu .main-menu ul li.active > a:after {
    bottom: 20px;
}

.header-menu-area.sticky_menu .main-menu ul li a {
    padding: 27px 17px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-menu-area.sticky_menu .main-menu ul li ul li a {
    padding: 10px 20px;
}

.header-menu-area .logo {
    position: relative;
    z-index: 999;
    height: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-menu-area .logo {
        height: 100px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .header-menu-area .logo {
        height: 80px;
    }
}

.header-menu-area .logo:before {
    position: absolute;
    content: "";
    width: 350px;
    height: 238px;
    right: -40px;
    bottom: -41px;
    background-image: linear-gradient(-65deg, var(--white) -98%, var(--primary) 100%);
    border-radius: 15px;
    z-index: -1;
    -webkit-transform: rotate(-19deg);
    transform: rotate(-19deg);
}

@media only screen and (max-width: 1199px) {
    .header-menu-area .logo:before {
        display: none;
    }
}

@media only screen and (max-width: 1199px) {
    .header-menu-area .logo .standard-logo {
        display: none;
    }
}

.header-menu-area .logo .standard-logo img {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-menu-area .logo .sticky-logo {
    display: none;
}

@media only screen and (max-width: 767px) {
    .header-menu-area .logo .sticky-logo {
        width: 170px;
    }
}

@media only screen and (max-width: 1199px) {
    .header-menu-area .logo .sticky-logo {
        display: block;
    }
}

.header-menu-area .logo .sticky-logo img {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-menu-area .logo .retina-logo {
    display: none;
}

.main-menu ul {
    margin: 0;
    padding: 0;
    position: relative;
}

.main-menu ul li {
    display: inline-block;
    padding: 0;
    position: relative;
}

.main-menu ul li.active {
    position: relative;
}

.main-menu ul li.active > a {
    color: var(--primary);
}

.main-menu ul li.active > a:after {
    position: absolute;
    content: "";
    height: 3px;
    width: 18px;
    background-color: var(--primary);
    bottom: 40px;
    right: 20px;
}

.main-menu ul li ul.submenu li.active > a {
    position: relative;
    z-index: 2;
    color: #ffffff;
}

.main-menu ul li ul.submenu li.active > a:after {
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    z-index: -1;
}

.main-menu ul li a {
    display: block;
    padding: 44px 17px;
    text-decoration: none;
    text-transform: capitalize;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    position: relative;
    color: #393d48;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.main-menu ul li a:hover {
    color: var(--primary);
}

.main-menu ul li:hover > ul {
    visibility: visible;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.main-menu ul li ul li:hover ul {
    visibility: visible;
    top: -2px;
}

.main-menu ul ul {
    background-color: #ffffff;
    right: 0;
    opacity: 0;
    position: absolute;
    text-align: right;
    top: 100%;
    visibility: hidden;
    z-index: 99999;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    border-top: 2px solid var(--primary);
    -webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
    box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
}

.main-menu ul ul li {
    float: none;
    width: 210px;
}

.main-menu ul ul li ul {
    visibility: hidden;
}

.main-menu ul ul li i {
    float: left;
}

.main-menu ul ul li a {
    line-height: 120%;
    padding: 10px 20px;
    color: #393d48;
    border-bottom: 1px solid #f5f5f5;
    margin-right: 0;
    font-size: 16px;
}

.main-menu ul ul li a:hover {
    background-color: var(--primary);
    color: #ffffff;
}

.main-menu ul ul li:last-child a {
    border-bottom: 0px;
}

.main-menu ul ul ul {
    right: 100%;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    border-top: 0px;
    border-top: 2px solid var(--primary);
}

.animation {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.slideUpIn {
    -webkit-animation-name: slideUpIn;
    animation-name: slideUpIn;
}

.header-btn {
    margin-right: 26px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
}

@media only screen and (max-width: 1399px) {
    .header-btn {
        display: none;
    }
}

.header-btn .icon-wrapper {
    border: 1px solid var(--secondary);
    border-radius: 50px;
    padding: 4px;
    -webkit-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 103, 49, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 103, 49, 0.1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-btn .icon-wrapper .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: var(--secondary);
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.header-btn .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.header-btn .content-wrapper span {
    display: inline-block;
    width: 100%;
    color: #5e6f92;
}

.header-btn .content-wrapper .text {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #393d48;
    line-height: 22px;
}

.header-btn .content-wrapper .text a {
    color: #393d48;
}

@media only screen and (max-width: 1199px) {
    .header-btn {
        display: none;
    }
}

.header-area.style-two.has-header-top .header-menu-area.sticky_menu .logo {
    height: 84px;
    margin-top: inherit;
}

.header-area.style-two.has-header-top .header-menu-area .logo {
    height: 162px;
    margin-top: -42px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-area.style-two.has-header-top .header-menu-area .logo {
        height: 120px;
        margin-top: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-area.style-two.has-header-top .header-menu-area .logo {
        height: 100px;
        margin-top: inherit;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .header-area.style-two.has-header-top .header-menu-area .logo {
        height: 80px;
        margin-top: inherit;
    }
}

.header-area.style-two .header-top .header-top-btn a {
    margin-bottom: inherit;
    padding: 6px 46px;
}

.header-area.style-two .header-top .header-top-btn a:hover:before {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: var(--secondary);
}

.header-area.style-two .header-top .header-top-btn a:before {
    border-radius: 0;
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.header-area.style-two .header-menu-area .logo:before {
    border-radius: 0;
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    bottom: 0;
    height: 190px;
}

.header-area.style-three .header-top .header-top-btn a {
    margin-bottom: inherit;
    padding: 6px 30px;
}

.header-area.style-three .header-top .header-top-btn a:before {
    border-radius: 8px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-area.style-three .header-top .header-top-btn {
        display: none;
    }
}

.header-area.style-three .header-menu-area .logo:before {
    display: none;
}

.mean-container a.meanmenu-reveal {
    display: none;
}

.mean-container .mean-nav {
    background: none;
}

.mean-container .mean-bar {
    padding: 0;
    min-height: auto;
    background: none;
}

.mean-container .mean-nav > ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none;
    display: block !important;
}

.mean-container a.meanmenu-reveal {
    display: none !important;
}

.mean-container .mean-nav ul li a {
    width: 100%;
    padding: 10px 0;
    color: #393d48;
    border-top: 1px solid #dfe2e9;
    font-size: 14px;
    text-transform: capitalize;
    line-height: 1.5;
    font-weight: 700;
}

.mean-container .mean-nav ul li a.mean-expand {
    margin-top: 5px;
    padding: 0 !important;
    border: 1px solid #dfe2e9 !important;
    height: 30px;
    width: 30px;
    line-height: 30px;
    color: #393d48;
    line-height: 30px;
    top: 0;
    font-weight: 400;
}

.mean-container .mean-nav ul li > a > i {
    display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
    display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
    border-top: 0;
}

.mean-container .mean-nav ul li.dropdown-opened a.mean-expand.mean-clicked {
    background-color: var(--secondary);
    border-color: var(--secondary) !important;
}

.mean-container .mean-nav ul li.dropdown-opened a.mean-expand.mean-clicked i {
    color: #ffffff;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
    color: #ffffff;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #ffffff;
}

.mobile-menu-bar a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.mobile-menu-bar a i {
    color: #393d48;
    font-size: 22px;
}

.menu-sidebar-area {
    position: fixed;
    left: -485px;
    top: 0;
    width: 465px;
    height: 100%;
    background-color: #ffffff;
    overflow-y: scroll;
    -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    z-index: 9999;
}

@media (max-width: 560px) {
    .menu-sidebar-area {
        width: 350px;
    }
}

.menu-sidebar-area .menu-sidebar-logo {
    display: inline-block;
    width: 180px;
}

.menu-sidebar-area .menu-sidebar-wrapper {
    position: relative;
    padding: 40px;
}

.menu-sidebar-area.active {
    left: 0;
}

.menu-sidebar-area .menu-sidebar-close {
    position: absolute;
    top: 35px;
}

.menu-sidebar-area .menu-sidebar-close .menu-sidebar-close-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    height: 45px;
    width: 45px;
    background-color: var(--secondary);
    color: #ffffff;
    border-radius: 50%;
}

.menu-sidebar-area .menu-sidebar-close .menu-sidebar-close-btn:hover {
    background-color: var(--secondary);
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .menu-sidebar-title {
    margin-bottom: 5px;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .header-contact-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 24px;
    padding: 6px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .header-contact-info span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 13px;
    color: #393d48;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    width: 100%;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .header-contact-info span.contact-info-item {
    gap: 0 10px !important;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .header-contact-info span a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    color: #393d48;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .header-contact-info span a:hover {
    color: var(--secondary);
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .social-profile {
    margin-top: 20px;
    border-top: 1px solid #dfe2e9;
    width: 100%;
    padding-top: 20px;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .social-profile a {
    color: #393d48;
    font-size: 22px;
}

.menu-sidebar-area .menu-sidebar-content .menu-sidebar-single-widget .social-profile a:hover {
    color: var(--secondary);
}

.body-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.body-overlay.active {
    opacity: 1;
    visibility: visible;
}

.body-overlay:hover {
    cursor: pointer;
}

.slider-area {
    overflow: hidden;
    position: relative;
}

.slider-area .social-share-wrapper {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 60px;
    z-index: 99;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .social-share-wrapper {
        display: none;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
    .slider-area .social-share-wrapper {
        right: 10px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .slider-area .social-share-wrapper {
        top: unset;
        right: 0;
        -webkit-transform: unset;
        transform: unset;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .social-share-wrapper {
        top: unset;
        right: 0;
        -webkit-transform: unset;
        transform: unset;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
    }
}

@media only screen and (max-width: 991px) {
    .slider-area .social-share-wrapper {
        top: unset;
        right: 0;
        -webkit-transform: unset;
        transform: unset;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
    }
}

@media (max-width: 1299px) {
    .slider-area .social-share-wrapper {
        position: absolute;
        width: 100%;
        right: 0;
        left: 0;
        bottom: 100px;
    }
}

.slider-area .social-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 34px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
}

@media (max-width: 1299px) {
    .slider-area .social-share {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        position: unset;
    }
}

.slider-area .social-share a {
    font-size: 24px;
    color: #ffffff;
    -webkit-transform: scale(1) rotate(90deg);
    transform: scale(1) rotate(90deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

@media (max-width: 1299px) {
    .slider-area .social-share a {
        -webkit-transform: unset;
        transform: unset;
    }
}

.slider-area .social-share a:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: var(--primary);
}

.slider-area .slider-wrapper .slick-active .slider-short-title, .slider-area .slider-wrapper .slick-active .slider-title, .slider-area .slider-wrapper .slick-active .slider-short-desc, .slider-area .slider-wrapper .slick-active .slider-btn-wrapper {
    -webkit-animation-name: MovefadeUp;
    animation-name: MovefadeUp;
    -webkit-animation-fill-mode: both;
}

.slider-area .slider-wrapper .slick-active .slider-short-title {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.slider-area .slider-wrapper .slick-active .slider-title {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}

.slider-area .slider-wrapper .slick-active .slider-short-desc {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
}

.slider-area .slider-wrapper .slick-active .slider-btn-wrapper {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
    -webkit-animation-duration: 0.9s;
    animation-duration: 0.9s;
}

.slider-area .slider-wrapper .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 46px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    flex-direction: column;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .slider-wrapper .slick-dots {
        display: none !important;
        position: relative;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: 30px;
        bottom: 70px;
        top: unset;
        right: unset;
        left: unset;
        -webkit-transform: none;
        transform: none;
    }
}

.slider-area .slider-wrapper .slick-dots li {
    display: inline-block;
    line-height: 0;
    outline: 1px solid transparent;
    border: 4px solid transparent;
    border-radius: 50px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area .slider-wrapper .slick-dots li button {
    font-size: 0;
    padding: 0;
    width: 11px;
    height: 11px;
    background-color: #c3e4de;
    border-radius: 50px;
    position: relative;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area .slider-wrapper .slick-dots li button:hover {
    background-color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area .slider-wrapper .slick-dots li.slick-active {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    outline: 1px solid var(--primary);
}

.slider-area .slider-wrapper .slick-dots li.slick-active button {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area button.slider-arrow-btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 99;
    background-color: transparent;
    border: 1px solid #dfe2e9;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
    display: none !important;
}

.slider-area button.slider-arrow-btn:before {
    position: absolute;
    width: 0;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--primary) 100%);
    content: "";
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: -1;
}

@media (max-width: 767px) {
    .slider-area button.slider-arrow-btn {
        width: 40px;
        height: 40px;
    }
}

.slider-area button.slider-arrow-btn.prev-btn {
    right: 30px;
}

.slider-area button.slider-arrow-btn.next-btn {
    left: 30px;
}

.slider-area button.slider-arrow-btn i {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    color: var(--primary);
    font-size: 21px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area button.slider-arrow-btn:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border-color: var(--primary);
}

.slider-area button.slider-arrow-btn:hover:before {
    width: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area button.slider-arrow-btn:hover i {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.slider-area .single-slider {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 2;
    overflow: hidden;
    height: 918px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .slider-area .single-slider {
        height: 620px;
        padding-bottom: 80px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider {
        height: 800px;
    }
}

.slider-area .single-slider .slider-overlay {
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    opacity: 0.831;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.slider-area .single-slider .slider-content-wrapper {
    position: relative;
    z-index: 99;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-content-wrapper {
        padding-bottom: 30px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content {
        text-align: center;
    }
}

.slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-title {
        font-size: 18px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-title {
        font-size: 16px;
    }
}

.slider-area .single-slider .slider-content-wrapper .slider-content .slider-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 90px;
    color: #ffffff;
    line-height: 100px;
    margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 60px;
        line-height: 64px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 48px;
        line-height: 56px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 42px;
        line-height: 52px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 20px;
    }
}

.slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-desc {
    font-size: 24px;
    color: #ffffff;
    line-height: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-desc {
        font-size: 18px;
        line-height: 32px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-desc {
        font-size: 18px;
        line-height: 32px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-short-desc {
        font-size: 18px;
        line-height: 32px;
    }
}

.slider-area .single-slider .slider-content-wrapper .slider-content .slider-btn-wrapper {
    margin-top: 50px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-content-wrapper .slider-content .slider-btn-wrapper {
        margin-top: 30px;
    }
}

.slider-area .single-slider .slider-image {
    position: relative;
    z-index: 2;
    width: 860px;
    -webkit-animation: MoveUpDown 2s linear infinite alternate;
    animation: MoveUpDown 2s linear infinite alternate;
    left: 47px;
    top: 24px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-image.moblile-slider-img {
        right: -30px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area .single-slider .slider-image {
        -webkit-animation: none;
        animation: none;
        left: unset;
        text-align: center;
    }
}

@media (max-width: 1799px) {
    .slider-area .single-slider .slider-image {
        width: auto;
        margin-top: inherit;
    }
}

.slider-area.style-2 {
    position: relative;
}

@media (max-width: 991px) {
    .slider-area.style-2 .social-share {
        display: none;
    }
}

.slider-area.style-2 .single-slider {
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area.style-2 .single-slider {
        height: 720px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area.style-2 .single-slider {
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-2 .single-slider {
        height: 500px;
    }
}

.slider-area.style-2 .single-slider:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 290px solid var(--secondary);
    border-right: 117px solid transparent;
    z-index: 1;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-2 .single-slider:after {
        display: none;
    }
}

.slider-area.style-2 .slider-wrapper .container {
    position: relative;
}

.slider-area.style-2 .slider-wrapper .slider-shape-top-triangle {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    top: 0;
    right: -100%;
    margin-right: -228px;
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area.style-2 .slider-wrapper .slider-shape-top-triangle {
        right: -80%;
    }
}

.slider-area.style-2 .slider-wrapper .slider-shape-bottom-left {
    position: absolute;
    width: 215px;
    height: 130px;
    background: var(--secondary);
    bottom: 0;
    right: -140px;
    -webkit-transform: skewX(22deg);
    transform: skewX(22deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area.style-2 .slider-wrapper .slider-shape-bottom-left {
        display: none;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-2 .slider-wrapper .slider-shape-bottom-left {
        display: none;
    }
}

.slider-area.style-2 .slider-wrapper .slider-image {
    position: relative;
    z-index: 2;
    float: left;
    margin-top: inherit;
    -webkit-animation: MoveLeftRight 2s linear infinite alternate;
    animation: MoveLeftRight 2s linear infinite alternate;
    left: -50px;
}

.slider-area.style-2 .slider-wrapper .slider-image img {
    float: left;
}

.slider-area.style-2 .slider-wrapper .slider-image:before {
    position: absolute;
    content: "";
    width: 520px;
    height: 2000px;
    background-color: var(--primary);
    border-radius: 0;
    top: -1000px;
    -webkit-transform: skewX(22deg);
    transform: skewX(22deg);
    z-index: -1;
    left: -160px;
}

.slider-area.style-3 {
    position: relative;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-3 .single-slider {
        height: 450px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .slider-area.style-3 .single-slider {
        padding-bottom: 0;
    }
}

.slider-area.style-3 .single-slider .slider-overlay {
    background: linear-gradient(-135deg, var(--primary) 0%, var(--secondary) 100%);
    opacity: 0.659;
}

.slider-area.style-3 .single-slider .slider-content-wrapper {
    gap: 100px;
    margin-top: -60px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper {
        margin-top: inherit;
    }
}

.slider-area.style-3 .single-slider .slider-content-wrapper .slider-content {
    width: 100%;
    text-align: center;
}

.slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-short-title {
    font-size: 36px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-short-title {
        font-size: 20px;
        line-height: 28px;
    }
}

.slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-title {
    font-size: 120px;
    line-height: 132px;
}

@media only screen and (min-width: 1199px) and (max-width: 1399px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 78px;
        line-height: 90px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 78px;
        line-height: 90px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 60px;
        line-height: 72px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 42px;
        line-height: 52px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 350px) {
    .slider-area.style-3 .single-slider .slider-content-wrapper .slider-content .slider-title {
        font-size: 38px;
        line-height: 50px;
    }
}

.slider-area.style-3 button.slider-arrow-btn {
    display: block !important;
}

.slider-area.style-3 .slider-wrapper .slick-dots {
    display: none !important;
}

.info-card-wrapper {
    position: relative;
}

.info-card {
    background: #ffffff;
    padding: 36px 55px 36px 40px;
    border-radius: 10px;
    position: relative;
}

@media only screen and (min-width: 991px) and (max-width: 1399px) {
    .info-card {
        padding: 30px 20px 30px 20px;
    }
}

.info-card:hover .image-wrapper .overlay {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    opacity: 0.72;
}

.info-card:hover .image-wrapper img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card:hover .icon img {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card .info-card-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
    height: 100%;
}

.info-card .divider:before {
    content: "";
    position: absolute;
    background-color: rgb(94, 111, 146);
    opacity: 0.2;
    left: 0;
    height: 80%;
    width: 1px;
    top: 45px;
}

.info-card .image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    top: 0;
    right: 0;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.info-card .image-wrapper img {
    width: 100%;
    height: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card .image-wrapper .overlay {
    width: 100%;
    height: 100%;
    background: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.6;
}

.info-card .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    width: 54px;
    height: 50px;
}

.info-card .icon img {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card .content-wrapper {
    position: relative;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.info-card .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 35px;
    padding-bottom: 40px;
    border-bottom: none;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.info-card .title-wrapper .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 52px;
    height: 52px;
    background: transparent;
    border-radius: 10px;
}

.info-card .title-wrapper .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
    color: #393d48;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.info-card .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
}

.info-card .content .desc {
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
    color: #5e6f92;
}

.info-card .content .read-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
}

.info-card .content .read-more a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 18px;
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0;
    color: #393d48;
}

.info-card .content .read-more a .icon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--primary);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    color: var(--secondary);
    z-index: 2;
}

.info-card .content .read-more a .icon:before {
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: 2px solid transparent;
    border-radius: 50px;
    background-image: linear-gradient(white, white), linear-gradient(-65deg, #e30008 0%, var(--primary) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card .content .read-more a .icon i {
    color: var(--secondary);
    font-size: 16px;
}

.info-card .content .read-more a:hover {
    margin-right: 5px;
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card .content .read-more a:hover .icon:before {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card .content .read-more a:hover .icon i {
    color: #ffffff;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .info-card {
        background-color: #ffffff;
        -webkit-box-shadow: 0px 5px 90px 0px rgba(2, 24, 93, 0.08);
        box-shadow: 0px 5px 90px 0px rgba(2, 24, 93, 0.08);
        margin-bottom: 28px;
        padding: 30px;
    }

    .info-card .divider:before {
        display: none;
    }

    .info-card .title-wrapper {
        gap: 20px;
        padding-bottom: 15px;
    }

    .info-card .content {
        gap: 26px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-card {
        background-color: #ffffff;
        -webkit-box-shadow: 0px 5px 90px 0px rgba(2, 24, 93, 0.08);
        box-shadow: 0px 5px 90px 0px rgba(2, 24, 93, 0.08);
        margin-bottom: 28px;
        padding: 30px;
    }

    .info-card .divider {
        display: none;
    }

    .info-card .content .read-more a .icon {
        width: 25px;
        height: 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .info-card .title-wrapper {
        gap: 20px;
        padding-bottom: 15px;
    }

    .info-card .title-wrapper .title {
        font-size: 18px;
    }

    .info-card .content {
        gap: 26px;
    }

    .info-card .content .desc {
        font-size: 16px;
        line-height: 26px;
    }

    .info-card .content .read-more a .icon {
        width: 25px;
        height: 25px;
    }
}

.info-card.style-two {
    padding: 46px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 12px;
    top: 0;
    z-index: 2;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    overflow: hidden;
    margin-bottom: 24px;
    border: 1px solid rgb(223, 226, 233);
    height: 400px;
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .info-card.style-two {
        padding: 30px;
    }
}

.info-card.style-two:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    right: 50%;
    background-color: #ffffff;
    z-index: -1;
    content: "";
    -webkit-transform: translate(50%, -50%) rotate(0deg);
    transform: translate(50%, -50%) rotate(0deg);
    -webkit-transition: 0.8s;
    transition: 0.8s;
}

.info-card.style-two:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: -2;
    content: "";
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border: 0;
}

.info-card.style-two:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    top: -10px;
}

.info-card.style-two:hover:before {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.8s;
    transition: 0.8s;
}

.info-card.style-two:hover:after {
    background: var(--secondary);
    background: linear-gradient(-135deg, rgba(0, 91, 172, 0.83) 0%, var(--primary) 100%);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card.style-two:hover .title-wrapper {
    border-bottom: 3px solid #ffffff;
}

.info-card.style-two:hover .title-wrapper .title {
    color: #ffffff;
}

.info-card.style-two:hover .title-wrapper .icon {
    background: linear-gradient(-65deg, var(--secondary) 0%, var(--primary) 100%);
}

.info-card.style-two:hover .content .desc {
    color: #ffffff;
}

.info-card.style-two:hover .read-more a {
    color: #ffffff;
}

.info-card.style-two .title-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    border-bottom: 3px solid var(--secondary);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .info-card.style-two .title-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.info-card.style-two .title-wrapper .icon {
    width: 97px;
    height: 97px;
    background: var(--secondary);
}

.info-card.style-two .title-wrapper .title {
    line-height: 36px;
}

.info-card.style-two .content {
    margin-top: 38px;
    gap: 37px;
}

.info-card.style-two .content .desc {
    line-height: 26px;
}

.info-card.style-two .content .read-more a .icon:before {
    display: none;
}

.info-card.style-two .content .read-more a .icon i {
    color: #ffffff;
}

@media (max-width: 370px) {
    .info-card.style-two .title-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .info-card.style-two {
        padding: 35px;
    }

    .info-card.style-two .title-wrapper {
        gap: 24px;
        padding-bottom: 30px;
    }

    .info-card.style-two .title-wrapper .icon {
        width: 62px;
        height: 62px;
    }

    .info-card.style-two .title-wrapper .icon img {
        width: 30px;
        height: 30px;
    }

    .info-card.style-two .title-wrapper .title {
        font-size: 22px;
        line-height: 30px;
    }

    .info-card.style-two .content {
        margin-top: 30px;
        gap: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-card.style-two {
        padding: 35px;
    }

    .info-card.style-two .title-wrapper {
        gap: 24px;
    }

    .info-card.style-two .title-wrapper .icon {
        width: 62px;
        height: 62px;
    }

    .info-card.style-two .title-wrapper .icon img {
        width: 30px;
        height: 30px;
    }

    .info-card.style-two .title-wrapper .title {
        font-size: 22px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .info-card.style-two {
        padding: 30px;
    }

    .info-card.style-two .title-wrapper {
        gap: 18px;
    }

    .info-card.style-two .title-wrapper .icon {
        width: 58px;
        height: 58px;
    }

    .info-card.style-two .title-wrapper .icon img {
        width: 30px;
        height: 30px;
    }

    .info-card.style-two .title-wrapper .title {
        font-size: 22px;
        line-height: 30px;
    }

    .info-card.style-two .content {
        margin-top: 30px;
        gap: 30px;
    }
}

.info-card.style-three {
    position: relative;
    padding: 0;
    margin-bottom: 100px;
}

.info-card.style-three:hover .image-wrapper img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.info-card.style-three:hover .image-wrapper:before {
    right: 0;
    left: 0;
    opacity: 0;
    visibility: visible;
    -webkit-transition: all 800ms linear;
    transition: all 800ms linear;
}

.info-card.style-three:hover .image-wrapper:after {
    top: 0;
    bottom: 0;
    opacity: 0;
    visibility: visible;
    -webkit-transition: all 800ms linear;
    transition: all 800ms linear;
}

.info-card.style-three:hover .content-wrapper .content-area {
    visibility: visible;
    opacity: 1;
    -webkit-transition: 0.6s;
    transition: 0.6s;
}

.info-card.style-three:hover .content-wrapper .content-area .content {
    margin-top: 20px;
}

@media (max-width: 375px) {
    .info-card.style-three:hover .content-wrapper .content-area .content {
        margin-top: 10px;
        gap: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-card.style-three:hover .content-wrapper .content-area .content {
        margin-top: 10px;
        gap: 10px;
    }
}

.info-card.style-three .image-wrapper {
    position: relative;
    opacity: 1;
    border-radius: 12px;
    overflow: hidden;
}

.info-card.style-three .image-wrapper img {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card.style-three .image-wrapper:before {
    position: absolute;
    content: "";
    opacity: 1;
    z-index: 3;
    top: -5%;
    left: 50%;
    bottom: -5%;
    right: 50%;
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.3);
}

.info-card.style-three .image-wrapper:after {
    position: absolute;
    content: "";
    opacity: 1;
    z-index: 3;
    top: 50%;
    left: -5%;
    bottom: 50%;
    right: -5%;
    visibility: hidden;
    background: rgba(255, 255, 255, 0.6);
}

.info-card.style-three .content-wrapper {
    margin-bottom: -48px;
    padding: 0 60px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
}

.info-card.style-three .content-wrapper .content-inner {
    padding: 20px 35px 20px 30px;
    background: #ffffff;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.info-card.style-three .content-wrapper .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 0;
}

.info-card.style-three .content-wrapper .title-wrapper .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
}

@media (max-width: 991px) {
    .info-card.style-three .content-wrapper .title-wrapper .icon {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 767px) {
    .info-card.style-three .content-wrapper .title-wrapper .icon {
        width: 30px;
        height: 30px;
    }
}

.info-card.style-three .content-wrapper .title-wrapper .title {
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
    margin: 0;
}

@media (min-width: 1280px) {
    .info-card.style-three .content-wrapper .title-wrapper .title {
        font-size: 24px;
    }
}

@media (max-width: 991px) {
    .info-card.style-three .content-wrapper .title-wrapper .title {
        font-size: 28px;
        line-height: 32px;
    }
}

@media (max-width: 767px) {
    .info-card.style-three .content-wrapper .title-wrapper .title {
        font-size: 18px;
        line-height: 28px;
    }
}

.info-card.style-three .content-wrapper .content-area {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card.style-three .content-wrapper .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
    margin-top: -200px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card.style-three .content-wrapper .content .desc {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    margin: 0;
}

@media (max-width: 375px) {
    .info-card.style-three .content-wrapper .content .desc {
        font-size: 14px;
        line-height: 21px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-card.style-three .content-wrapper .content .desc {
        font-size: 16px;
        line-height: 26px;
    }
}

.info-card.style-three .content-wrapper .content .read-more a .icon:before {
    display: none;
}

.info-card.style-three .content-wrapper .content .read-more a .icon i {
    color: #ffffff;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .info-card.style-three .content-wrapper {
        padding: 0 10px;
    }

    .info-card.style-three .content-wrapper .content-inner {
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-card.style-three .content-wrapper {
        padding: 0 10px;
    }

    .info-card.style-three .content-wrapper .content-inner {
        padding: 20px;
    }

    .info-card.style-three .content-wrapper .title-wrapper {
        gap: 20px;
    }

    .info-card.style-three .content-wrapper .title-wrapper .title {
        font-size: 26px;
        line-height: 36px;
    }

    .info-card.style-three .content-wrapper .title-wrapper .icon {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .info-card.style-three .content-wrapper {
        padding: 0 20px;
    }

    .info-card.style-three .content-wrapper .content-inner {
        padding: 20px;
    }

    .info-card.style-three .content-wrapper .title-wrapper {
        gap: 20px;
    }

    .info-card.style-three .content-wrapper .title-wrapper .title {
        font-size: 26px;
        line-height: 36px;
    }

    .info-card.style-three .content-wrapper .title-wrapper .icon {
        width: 50px;
        height: 50px;
    }
}

.info-card.style-four {
    position: relative;
    padding: 0;
    border-radius: 14px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.info-card.style-four .image-wrapper {
    position: relative;
    opacity: 1;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    border-radius: 10px;
}

.info-card.style-four .image-wrapper:before {
    position: absolute;
    width: 200%;
    height: 0%;
    right: 50%;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
    content: "";
    z-index: 1;
}

.info-card.style-four .image-wrapper .overlay {
    background: #393d49;
}

.info-card.style-four .content-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
}

.info-card.style-four .content-wrapper .content-inner {
    padding: 20px 35px 20px 30px;
    background: transparent;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.info-card.style-four .content-wrapper .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-bottom: 30px;
}

.info-card.style-four .content-wrapper .title-wrapper .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    color: #ffffff;
}

@media (max-width: 1199px) {
    .info-card.style-four .content-wrapper .title-wrapper .icon {
        width: 40px;
        height: 40px;
    }
}

.info-card.style-four .content-wrapper .title-wrapper .title {
    font-size: 30px;
    line-height: 42px;
    font-weight: 700;
    margin: 0;
    color: #ffffff;
}

@media (max-width: 1199px) {
    .info-card.style-four .content-wrapper .title-wrapper .title {
        font-size: 24px;
        line-height: 32px;
    }
}

.info-card.style-four .content-wrapper .content-area {
    overflow: hidden;
    visibility: hidden;
    opacity: 1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card.style-four .content-wrapper .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
    margin-top: -400px;
    -webkit-transition: 0.7s ease-in;
    transition: 0.7s ease-in;
}

.info-card.style-four .content-wrapper .content .desc {
    color: #ffffff;
}

.info-card.style-four .content-wrapper .content .read-more a {
    color: #ffffff;
}

.info-card.style-four .content-wrapper .content .read-more a .icon:before {
    display: none;
}

.info-card.style-four .content-wrapper .content .read-more a .icon i {
    color: #ffffff;
}

.info-card.style-four:hover .image-wrapper:before {
    height: 250%;
    -webkit-transition: all 800ms linear;
    transition: all 800ms linear;
    background-color: transparent;
}

.info-card.style-four:hover .title-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.info-card.style-four:hover .content-wrapper .content-area {
    visibility: visible;
    opacity: 1;
    -webkit-transition: 0.8s ease-in;
    transition: 0.8s ease-in;
}

.info-card.style-four:hover .content-wrapper .content-area .content {
    margin: 20px 0 30px 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card-slider-wrapper {
    position: relative;
}

.info-card-slider-wrapper:before {
    right: -100%;
    margin-right: -10px;
}

.info-card-slider-wrapper:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 200%;
    background-color: #ffffff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.info-card-slider-wrapper .slick-list {
    overflow: visible;
}

.info-card-slider-wrapper .slick-list .slick-slide {
    margin: 0 12px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card-slider-wrapper .slick-list .slick-slide .info-card {
    margin-bottom: 0;
}

.info-card-slider-wrapper .slick-list .slick-slide.slick-active {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card-slider-wrapper button.info-card-arrow-btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 99;
    background-color: #ffffff;
    border: 1px solid #dfe2e9;
    cursor: pointer;
    top: -125px;
    overflow: hidden;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .info-card-slider-wrapper button.info-card-arrow-btn {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 991px) {
    .info-card-slider-wrapper button.info-card-arrow-btn {
        top: unset;
        bottom: -80px;
    }
}

.info-card-slider-wrapper button.info-card-arrow-btn:before {
    position: absolute;
    width: 0;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--primary) 100%);
    content: "";
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: -1;
}

.info-card-slider-wrapper button.info-card-arrow-btn.prev-btn {
    left: 72px;
}

@media (max-width: 991px) {
    .info-card-slider-wrapper button.info-card-arrow-btn.prev-btn {
        left: unset;
    }
}

@media (max-width: 767px) {
    .info-card-slider-wrapper button.info-card-arrow-btn.prev-btn {
        left: unset;
        right: 10px;
    }
}

.info-card-slider-wrapper button.info-card-arrow-btn.next-btn {
    left: 0;
}

@media (max-width: 991px) {
    .info-card-slider-wrapper button.info-card-arrow-btn.next-btn {
        left: unset;
        right: 74px;
    }
}

@media (max-width: 767px) {
    .info-card-slider-wrapper button.info-card-arrow-btn.next-btn {
        left: 30px;
        right: 74px;
    }
}

.info-card-slider-wrapper button.info-card-arrow-btn i {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    color: var(--primary);
    font-size: 21px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .info-card-slider-wrapper button.info-card-arrow-btn i {
        font-size: 18px;
    }
}

.info-card-slider-wrapper button.info-card-arrow-btn:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border-color: var(--primary);
}

.info-card-slider-wrapper button.info-card-arrow-btn:hover:before {
    width: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.info-card-slider-wrapper button.info-card-arrow-btn:hover i {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.icon-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #ffffff;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 36px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .icon-card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 30px;
    }
}

.icon-card:hover .icon img {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.icon-card .icon {
    width: 78px;
    height: 78px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.icon-card .icon img {
    width: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.icon-card .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .icon-card .content {
        gap: 15px;
    }
}

.icon-card .content .title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #393d48;
    margin-bottom: 0;
}

.icon-card .content .desc {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #5e6f92;
    margin-bottom: 0;
}

.icon-card .content .desc a {
    color: #5e6f92;
}

.icon-card.style-two {
    background: transparent;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 34px;
    padding: 64px 70px;
    position: relative;
    margin-bottom: 0;
}

.icon-card.style-two .divider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-left: 1px solid #464b4e;
    border-bottom: 1px solid #464b4e;
}

.icon-card.style-two .icon {
    width: 62px;
    height: 62px;
}

.icon-card.style-two .icon img {
    width: 100%;
}

.icon-card.style-two .content {
    gap: 26px;
}

.icon-card.style-two .content .title {
    color: #ffffff;
}

@media (min-width: 1280px) {
    .icon-card.style-two .content .title {
        font-size: 22px;
    }
}

.icon-card.style-two .content .desc {
    color: #c7cdda;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .icon-card.style-two {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 36px;
        gap: 34px;
    }

    .icon-card.style-two .content {
        gap: 24px;
    }
}

.icon-card.style-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: transparent;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 50px;
}

.icon-card.style-three .icon {
    width: 72px;
    height: 72px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.icon-card.style-three .icon img {
    width: 100%;
}

.icon-card.style-three .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.icon-card.style-three .content .title {
    color: #ffffff;
}

.icon-card.style-three .content .desc {
    color: #c7cdda;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .icon-card.style-three {
        gap: 25px;
    }

    .icon-card.style-three .content {
        gap: 18px;
    }
}

.icon-card.style-four {
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.icon-card.style-four .icon {
    width: 72px;
    height: 72px;
    background: var(--secondary);
    border-radius: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.icon-card.style-four .icon i {
    color: #ffffff;
}

.icon-card.style-four .content {
    width: 100%;
    padding: 55px 30px 30px 30px;
    background: #eef5f8;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
}

.about-image-card .main-img-wrapper {
    text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-image-card .main-img-wrapper {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-card .main-img-wrapper {
        text-align: center;
    }
}

.about-image-card .main-img-wrapper .main-img-inner {
    position: relative;
    display: inline-block;
}

@media only screen and (max-width: 650px) {
    .about-image-card .main-img-wrapper .main-img-inner {
        padding: 50px;
        padding-top: 0;
        width: 100%;
    }
}

.about-image-card .main-img-wrapper .main-img-inner > img {
    border-radius: 12px;
    margin-right: 7px;
    margin-top: 4px;
}

@media (max-width: 650px) {
    .about-image-card .main-img-wrapper .main-img-inner > img {
        width: 100%;
    }
}

.about-image-card .img-card-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.about-image-card .img-card-wrapper img {
    border-radius: 12px;
}

.about-image-card .img-card-wrapper .year, .about-image-card .img-card-wrapper .title {
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.about-image-card .img-card-wrapper .year {
    font-size: 72px;
    line-height: 80px;
}

@media (max-width: 650px) {
    .about-image-card .img-card-wrapper .year {
        font-size: 48px;
        line-height: 52px;
    }
}

.about-image-card .img-card-wrapper .title {
    font-size: 18px;
    line-height: 26px;
}

@media (max-width: 650px) {
    .about-image-card .img-card-wrapper .title {
        font-size: 16px;
    }
}

.about-image-card .img-card-wrapper.image-one {
    top: 55px;
    left: -40px;
    border-radius: 6px;
    width: 96px;
    height: 96px;
    background-color: var(--secondary);
    -webkit-animation: MoveUpDown 2s linear infinite alternate;
    animation: MoveUpDown 2s linear infinite alternate;
}

@media (max-width: 650px) {
    .about-image-card .img-card-wrapper.image-one {
        left: 0;
    }
}

.about-image-card .img-card-wrapper.image-two {
    bottom: -60px;
    left: 50px;
    border-radius: 6px;
    width: 120px;
    height: 120px;
    background-color: var(--primary);
    -webkit-animation: MoveLeftRight 2s linear infinite alternate;
    animation: MoveLeftRight 2s linear infinite alternate;
}

@media (max-width: 650px) {
    .about-image-card .img-card-wrapper.image-two {
        bottom: 0;
        width: 100px;
        height: 100px;
    }
}

.about-image-card .img-card-wrapper.image-three {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -140px;
    border-radius: 10px;
    width: 180px;
    height: 180px;
    z-index: 2;
    background: var(--secondary);
    background: linear-gradient(-135deg, var(--secondary) 0%, var(--primary) 100%);
    -webkit-animation: MoveUpDown 2s linear infinite alternate;
    animation: MoveUpDown 2s linear infinite alternate;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .about-image-card .img-card-wrapper.image-three {
        right: -50px;
    }
}

@media (max-width: 991px) {
    .about-image-card .img-card-wrapper.image-three {
        right: -85px;
    }
}

@media (max-width: 650px) {
    .about-image-card .img-card-wrapper.image-three {
        right: 0;
        width: 130px;
        height: 130px;
    }
}

.about-image-card .img-card-wrapper.image-four {
    bottom: -100px;
    right: -220px;
    border-radius: 10px;
    width: 335px;
    height: 330px;
    overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .about-image-card .img-card-wrapper.image-four {
        right: -250px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .about-image-card .img-card-wrapper.image-four {
        bottom: -130px;
        right: -80px;
        border-radius: 10px;
        width: 230px;
    }
}

@media only screen and (max-width: 650px) {
    .about-image-card .img-card-wrapper.image-four {
        width: 150px;
        height: 150px;
        right: 0;
        bottom: -50px;
    }
}

@media only screen and (min-width: 651px) and (max-width: 767px) {
    .about-image-card .img-card-wrapper.image-four {
        width: 270px;
        height: 260px;
        right: -100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-card .img-card-wrapper.image-four {
        right: -120px;
    }
}

.about-image-card.style-two .main-img-wrapper {
    text-align: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-image-card.style-two .main-img-wrapper {
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-card.style-two .main-img-wrapper {
        text-align: center;
    }
}

.about-image-card.style-two .img-card-wrapper.image-three {
    width: 168px;
    height: 170px;
    border-radius: 12px;
    background-image: linear-gradient(-135deg, var(--secondary) 0%, var(--primary) 100%);
    top: unset;
    bottom: -84px;
    right: -59px;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-two .img-card-wrapper.image-three .year {
        font-size: 48px;
        line-height: 52px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-two .img-card-wrapper.image-three .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-image-card.style-two .img-card-wrapper.image-three {
        right: 50px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-two .img-card-wrapper.image-three {
        width: 145px;
        height: 140px;
        right: -30px;
    }
}

@media only screen and (max-width: 650px) {
    .about-image-card.style-two .img-card-wrapper.image-three {
        right: 0;
        bottom: -45px;
    }
}

.about-image-card.style-two .img-card-wrapper.image-four {
    width: 459px;
    height: 343px;
    border-radius: 0;
    z-index: -1;
    right: -160px;
    bottom: -170px;
}

@media only screen and (max-width: 650px) {
    .about-image-card.style-two .img-card-wrapper.image-four {
        right: -15px;
        bottom: -100px;
        width: 260px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-two .img-card-wrapper.image-four {
        right: -50px;
        bottom: -50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-card.style-two .img-card-wrapper.image-four {
        right: -130px;
    }
}

.about-image-card.style-two .img-card-wrapper.image-five {
    top: 150px;
    left: -224px;
    border-radius: 12px;
    overflow: hidden;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .about-image-card.style-two .img-card-wrapper.image-five {
        left: -44px !important;
        width: 300px;
        bottom: -360px;
    }
}

@media only screen and (max-width: 650px) {
    .about-image-card.style-two .img-card-wrapper.image-five {
        left: 0;
        width: 220px;
        bottom: -60px;
        top: unset;
    }
}

@media only screen and (min-width: 651px) and (max-width: 767px) {
    .about-image-card.style-two .img-card-wrapper.image-five {
        left: -70px;
        width: 305px;
        bottom: -100px;
        top: unset;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-image-card.style-two .img-card-wrapper.image-five {
        left: -130px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image-card.style-two .img-card-wrapper.image-five {
        left: -130px;
    }
}

.about-image-card.style-three .main-img-wrapper {
    text-align: center;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-three .main-img-wrapper .main-img-inner {
        width: 100%;
        padding: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-three .main-img-wrapper .main-img-inner img {
        width: 100%;
        margin: 0;
    }
}

.about-image-card.style-three .img-card-wrapper {
    top: 0;
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-three .img-card-wrapper {
        top: 50%;
        -webkit-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-three .img-card-wrapper .year {
        font-size: 38px;
        line-height: 32px;
    }
}

.about-image-card.style-three .img-card-wrapper .image-six {
    width: 190px;
    height: 190px;
    border-radius: 8px;
    z-index: -1;
    background-color: var(--secondary);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-three .img-card-wrapper .image-six {
        width: 160px;
        height: 160px;
    }
}

.about-image-card.style-three .img-card-wrapper .image-seven {
    width: 190px;
    height: 190px;
    border-radius: 8px;
    z-index: -1;
    background-color: var(--secondary);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-image-card.style-three .img-card-wrapper .image-seven {
        width: 160px;
        height: 160px;
    }
}

.about-info-card .about-info-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 28px;
}

.about-info-card .about-info-content .section-title .title {
    margin-bottom: 0;
}

.about-info-card .about-info-content .sub-title p, .about-info-card .about-info-content .text p, .about-info-card .about-info-content .quote-text p {
    margin-bottom: 0;
}

.about-info-card .about-info-content .sub-title {
    font-weight: 700;
}

.about-info-card .about-info-content .quote-text {
    padding-right: 40px;
    border-right: 6px solid var(--secondary);
    font-style: italic;
}

.about-info-card .about-info-content .quote-text .quote-text-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 5px;
}

.about-info-card .about-info-content .quote-text .quote-text-meta .divider {
    width: 25px;
    height: 1px;
    background-color: #bfc5cb;
}

.about-info-card .about-info-content .quote-text .quote-text-meta .name {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #393d48;
    font-style: normal;
}

.about-info-card .about-info-content .quote-text .quote-text-meta .designation {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.about-info-card .about-info-content .btn-wrapper {
    margin-top: 20px;
}

.about-info-card.style-two .about-info-content {
    gap: 16px;
}

.about-info-card.style-two .about-info-content .icon-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 0 20px;
    margin-bottom: 5px;
}

.about-info-card.style-two .about-info-content .icon-card-wrapper .icon-card {
    width: 50%;
    padding: 0;
    gap: 20px;
    margin-bottom: 0;
}

.about-info-card.style-two .about-info-content .icon-card-wrapper .icon-card .icon {
    width: 42px;
    height: 42px;
    margin-top: 13px;
}

.about-info-card.style-two .about-info-content .icon-card-wrapper .icon-card .content {
    gap: 4px;
}

.about-info-card.style-two .about-info-content .icon-card-wrapper .icon-card .content .title {
    font-size: 18px;
}

.about-info-card.style-two .about-info-content .btn-wrapper {
    margin-top: 25px;
}

.about-info-card.style-two .about-info-content .skill-and-video-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-info-card.style-two .about-info-content .skill-and-video-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.about-info-card.style-two .about-info-content .skill-and-video-wrapper .video-popup-card {
    width: 43%;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-info-card.style-two .about-info-content .skill-and-video-wrapper .video-popup-card {
        width: 100%;
    }
}

.about-info-card.style-two .about-info-content .skill-and-video-wrapper .video-popup-card:before {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    content: "";
    background: var(--secondary);
    background: linear-gradient(-135deg, rgba(0, 91, 172, 0.6) 0%, rgba(30, 174, 152, 0.6) 100%);
}

.about-info-card.style-two .about-info-content .skill-and-video-wrapper .video-popup-card .video-popup-btn a {
    width: 60px;
    height: 60px;
    background-color: var(--primary);
    background-image: none;
}

.about-info-card.style-three .section-title {
    margin-bottom: 40px;
}

.about-info-card.style-three .about-info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-info-card.style-three .about-info-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.about-info-card.style-three .about-info-wrapper .about-info-img {
    border-radius: 12px;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-info-card.style-three .about-info-wrapper .about-info-img {
        height: 350px;
    }
}

.about-info-card.style-three .about-info-wrapper .about-info-img img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.about-info-card.style-three .about-info-content {
    padding-right: 50px;
    gap: 30px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-info-card.style-three .about-info-content {
        padding-right: 0;
        padding-top: 50px;
    }
}

.about-info-card.style-three .about-info-content .skill-progressbar-wrapper {
    width: 100%;
}

.about-info-card.style-three .about-info-content .icon-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}

.about-info-card.style-three .about-info-content .icon-card-wrapper .icon-card {
    width: 50%;
    padding: 0;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0;
}

.about-info-card.style-three .about-info-content .icon-card-wrapper .icon-card .icon {
    width: 40px;
    height: 40px;
}

.about-info-card.style-three .about-info-content .icon-card-wrapper .icon-card .content {
    gap: 4px;
}

.about-info-card.style-three .about-info-content .icon-card-wrapper .icon-card .content .title {
    font-size: 18px;
    line-height: 24px;
}

.about-info-card.style-three .about-info-content .btn-wrapper {
    margin-top: 7px;
}

.promotional-banner-widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
}

@media (max-width: 767px) {
    .promotional-banner-widget {
        margin-top: 50px;
    }
}

.promotional-banner-widget .promotional-bannar-img {
    border-radius: 12px;
    overflow: hidden;
}

.promotional-banner-widget .promotional-bannar-img img {
    width: 100%;
}

.promotional-banner-widget .title-wrapper .title {
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
    line-height: 48px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .promotional-banner-widget .title-wrapper .title {
        font-size: 32px;
        line-height: 42px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .promotional-banner-widget .title-wrapper .title {
        font-size: 28px;
        line-height: 32px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .promotional-banner-widget .title-wrapper .title {
        font-size: 26px;
        line-height: 30px;
    }
}

.promotional-banner-widget .promotional-bananer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.promotional-banner-widget .promotional-bananer-content .icon {
    width: 65px;
    height: 65px;
    background-color: var(--secondary);
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #ffffff;
}

.promotional-banner-widget .promotional-bananer-content .content {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.promotional-banner-widget .promotional-bananer-content .content span {
    display: inline-block;
    width: 100%;
}

.promotional-banner-widget .promotional-bananer-content .content .text {
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    font-weight: 700;
    color: #393d48;
}

.promotional-banner-widget .promotional-bananer-content .content .text a {
    color: #393d48;
}

.promotional-banner-widget.style-two .promotional-bananer-content {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 20px;
}

.promotional-banner-widget.style-two .promotional-bananer-content .icon {
    background: var(--secondary);
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
}

.promotional-banner-widget.style-two .promotional-bananer-content .content span {
    display: inline-block;
    width: 100%;
    color: var(--secondary);
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.promotional-banner-widget.style-two .promotional-bananer-content .content .text {
    font-size: 24px;
    color: #ffffff;
}

.promotional-banner-widget.style-two .promotional-bananer-content .content .text a {
    color: #ffffff;
}

.camera-scan {
    position: fixed;
    width: 200px;
    height: 200px;
    bottom: 1%;
    right: 1%;
    z-index: 100000;
}

.camera-popup-scan {
    position: relative;
    padding: 10px;
}

.camera-popup-scan .camera-popup-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.camera-popup-scan .camera-popup-btn {
    position: absolute;
    bottom: -20%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.camera-popup-scan .camera-popup-btn a {
    width: 155px;
    height: 155px;
    /*-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);*/
    /*box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);*/
    /*background: var(--secondary);*/
    /*background: linear-gradient(-135deg, var(--secondary) 0%, var(--primary) 100%);*/
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.camera-popup-scan .camera-popup-btn img {
    width: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .camera-popup-scan .camera-popup-btn a {
        width: 100px;
        height: 100px;
    }
    .camera-popup-scan .camera-popup-btn {
        bottom: -5%;
        right: 40%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .camera-popup-scan .camera-popup-btn a {
        width: 80px;
        height: 80px;
    }
    .camera-popup-scan .camera-popup-btn {
        bottom: -15%;
        right: 30%;
    }
    .camera-popup-scan .camera-popup-btn img {
        width: 50px;
    }
}

.camera-popup-scan .camera-popup-btn a i {
    color: #ffffff;
    font-size: 55px;
}

.camera-popup-scan .camera-popup-btn a:hover {
    -webkit-animation: none;
    animation: none;
}

.camera-popup-scan.style-two {
    padding: 0;
    margin-bottom: -78px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (max-width: 991px) {
    .video-popup-card.style-two {
        margin-bottom: 80px;
    }
}

.camera-popup-scan.style-two .camera-popup-btn a {
    width: 100px;
    height: 100px;
    background-color: rgba(255, 103, 49, 0.84);
    background-image: none;
}

.camera-popup-scan.style-three {
    overflow: visible;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.camera-popup-scan.style-three .video-popup-image {
    border-radius: 12px;
    overflow: hidden;
}

.camera-popup-scan.style-three .camera-popup-btn {
    right: 0;
}

@media (max-width: 1199px) {
    .camera-popup-scan.style-three .camera-popup-btn {
        right: 50%;
    }
}

.camera-popup-scan.style-three .camera-popup-btn a {
    width: 135px;
    height: 135px;
    background: var(--secondary);
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
}

@media (max-width: 767px) {
    .camera-popup-scan.style-three .camera-popup-btn a {
        width: 80px;
        height: 80px;
    }

    .camera-popup-scan .camera-popup-btn a i {
        color: #ffffff;
        font-size: 35px;
    }
}

.counter-card {
    z-index: 1;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-card {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-card {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 50px;
    }

    .camera-popup-scan .camera-popup-btn a i {
        color: #ffffff;
        font-size: 35px;
    }
}

.counter-card .counter-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.counter-card .counter-item .counter-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    gap: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.counter-card .counter-item .number {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-card .counter-item .number {
        font-size: 36px;
        line-height: 40px;
    }
}

.counter-card .counter-item .title {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
}

.counter-card .counter-item .text {
    margin: 0;
    color: #c7cdda;
}

.counter-card.style-two {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-card.style-two {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-card.style-two {
        grid-template-columns: repeat(1, 1fr);
    }
}

.counter-card.style-two .counter-item {
    text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-card.style-two .counter-item {
        text-align: center;
    }
}

.counter-card.style-two .counter-item .counter-title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-card.style-two .counter-item .counter-title {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
    }
}

.counter-card.style-two .counter-item .counter-content {
    width: 100%;
}

@media (max-width: 280px) {
    .counter-card.style-two .counter-item .counter-content .text br {
        display: none;
    }
}

.counter-card.style-two .counter-item .number {
    font-size: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-card.style-two .counter-item .number {
        font-size: 48px;
        line-height: 50px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-card.style-two .counter-item .number {
        font-size: 48px;
        line-height: 50px;
    }
}

.counter-card.style-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 18px;
}

.counter-card.style-three .counter-item {
    text-align: right;
    padding: 60px 40px;
    background: var(--secondary);
    border-radius: 8px;
}

.counter-card.style-three .counter-item .counter-title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 30px;
}

.counter-card.style-three .counter-item .counter-title .title {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
}

.counter-card.style-three .counter-item .number {
    margin: 0;
    color: #ffffff;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-card.style-three .counter-item .number {
        font-size: 48px;
        line-height: 52px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-card.style-three .counter-item {
        padding: 40px 30px;
    }
}

.process-steps-wrapper {
    padding: 70px 50px 10px 0px;
    background: #31373f;
}

.process-step {
    position: relative;
    padding: 41px 56px;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 79px;
}

@media only screen and (min-width: 1199px) and (max-width: 1399px) {
    .process-step {
        padding: 30px;
    }
}

@media (max-width: 991px) {
    .process-step {
        margin-bottom: 28px;
    }
}

.process-step:hover .count:before {
    -webkit-transform: scale(1) rotateY(-360deg);
    transform: scale(1) rotateY(-360deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.process-step .count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    background: var(--secondary);
    width: 72px;
    height: 72px;
    border-radius: 50px;
    color: #ffffff;
}

.process-step .count span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
}

.process-step .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
}

.process-step .content .title {
    margin: 0;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #393d48;
}

.process-step .content .desc {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #5e6f92;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .process-step {
        padding: 25px 30px;
    }

    .process-step .icon {
        margin-bottom: 20px;
    }

    .process-step .count {
        right: 0;
        top: 6px;
        position: relative;
        -webkit-transform: inherit;
        transform: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .process-step {
        padding: 25px 30px;
    }

    .process-step .icon {
        margin-bottom: 20px;
    }

    .process-step .count {
        right: 0;
        top: 6px;
        position: relative;
        -webkit-transform: inherit;
        transform: inherit;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .process-step {
        padding: 25px 30px;
    }

    .process-step .icon {
        margin-bottom: 20px;
    }

    .process-step .count {
        right: 0;
        top: 6px;
        position: relative;
        -webkit-transform: inherit;
        transform: inherit;
    }
}

.process-step.style-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    background: transparent;
    gap: 35px;
    padding: 0;
}

@media (max-width: 1199px) {
    .process-step.style-two {
        margin-bottom: 60px !important;
    }
}

@media only screen and (min-width: 1024px) {
    .process-step.style-two {
        padding-left: 15px;
    }
}

.process-step.style-two:hover .icon:before {
    background-color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.process-step.style-two:hover .icon img {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.process-step.style-two:hover .icon .count {
    background-color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.process-step.style-two .icon {
    width: 85px;
    height: 85px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

@media (max-width: 1199px) {
    .process-step.style-two .icon {
        margin-bottom: 0 !important;
    }
}

.process-step.style-two .icon:before {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
    border-radius: 50%;
}

.process-step.style-two .icon .count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    background: var(--secondary);
    width: 30px;
    height: 30px;
    border-radius: 50px;
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.process-step.style-two .icon .count:before {
    width: 100%;
    height: 100%;
    background-color: var(--secondary);
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    -webkit-transform: scale(0) rotateY(0deg);
    transform: scale(0) rotateY(0deg);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border-radius: 50%;
}

.process-step.style-two .icon .count span {
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
}

.process-step.style-two .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
}

.process-step.style-two .content .title {
    margin: 0;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
}

.process-step.style-two .content .desc {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #c7cdda;
}

.testimonial-card {
    padding: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
    border-radius: 12px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-card {
        padding: 35px;
    }
}

.testimonial-card .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
}

.testimonial-card .content .text {
    margin: 0;
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-card .content .text {
        font-size: 16px;
    }
}

.testimonial-card .meta-user {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonial-card .meta-user .user-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}

.testimonial-card .meta-user .user-info .image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 72px;
    height: 72px;
    border-radius: 50px;
    overflow: hidden;
}

.testimonial-card .meta-user .user-info .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.testimonial-card .meta-user .user-info .info h2 {
    margin: 0;
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    color: #393d48;
}

.testimonial-card .meta-user .user-info .info p {
    margin: 0;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #778196;
}

.testimonial-card.style-two {
    padding: 66px 44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-card.style-two {
        padding: 30px;
    }
}

.testimonial-card.style-two .icon {
    -ms-flex-item-align: self-start;
    align-self: self-start;
}

.testimonial-card.style-two .content-wrapper .content .text {
    line-height: 30px;
}

.testimonial-card.style-two .content-wrapper .meta-user .icon {
    display: none;
}

.testimonial-card.style-three {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0;
}

.testimonial-card.style-three .icon {
    -ms-flex-item-align: start;
    align-self: flex-start;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.testimonial-card.style-three .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 45px;
}

.testimonial-card.style-three .content-wrapper .content {
    margin-top: 18px;
}

.testimonial-card.style-three .content-wrapper .content .text {
    font-size: 26px;
    font-style: italic;
    line-height: 40px;
}

.testimonial-card.style-three .content-wrapper .meta-user .icon {
    display: none;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-card.style-three {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
        padding: 30px;
    }

    .testimonial-card.style-three .content-wrapper {
        gap: 20px;
    }

    .testimonial-card.style-three .content-wrapper .content {
        margin-top: 0;
    }

    .testimonial-card.style-three .content-wrapper .content .text {
        font-size: 18px;
        line-height: 30px;
    }

    .testimonial-card.style-three .content-wrapper .meta-user .user-info .info h2 {
        font-size: 16px;
        line-height: 28px;
    }

    .testimonial-card.style-three .content-wrapper .meta-user .user-info .info p {
        font-size: 14px;
    }
}

.testimonial-slider-wrapper {
    position: relative;
}

.testimonial-slider-wrapper .slick-list {
    padding: 40px 30px;
    padding-bottom: 60px;
    margin: 0 -12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-slider-wrapper .slick-list {
        padding: 40px 10px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-slider-wrapper .slick-list {
        padding: 30px 10px;
    }
}

.testimonial-slider-wrapper .slick-list .slick-slide {
    margin: 0 12px;
    border-radius: 20px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.testimonial-slider-wrapper .slick-list .slick-slide.slick-active {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-box-shadow: 0px 10px 44px 0px rgba(4, 43, 77, 0.08);
    box-shadow: 0px 10px 44px 0px rgba(4, 43, 77, 0.08);
    background-color: #ffffff;
}

@media (max-width: 991px) {
    .testimonial-slider-wrapper .slick-list .slick-slide.slick-active {
        -webkit-box-shadow: -1px 8px 44px rgba(20, 20, 43, 0.03);
        box-shadow: -1px 8px 44px rgba(20, 20, 43, 0.03);
    }
}

.testimonial-slider-wrapper .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: -65px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
}

@media only screen and (min-width: 991px) and (max-width: 1280px) {
    .testimonial-slider-wrapper .slick-dots {
        left: -15px;
    }
}

@media (max-width: 991px) {
    .testimonial-slider-wrapper .slick-dots {
        position: unset;
        left: unset;
        top: unset;
        -webkit-transform: inherit;
        transform: inherit;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.testimonial-slider-wrapper .slick-dots li {
    display: inline-block;
    line-height: 0;
    outline: 2px solid transparent;
    border: 5px solid transparent;
    border-radius: 50px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.testimonial-slider-wrapper .slick-dots li button {
    font-size: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    background-color: rgb(94, 111, 146);
    opacity: 0.478;
    border-radius: 50px;
    position: relative;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.testimonial-slider-wrapper .slick-dots li.slick-active {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    outline: 2px solid rgb(252, 133, 91);
}

.testimonial-slider-wrapper .slick-dots li.slick-active button {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.testimonial-slider-wrapper button.testimonial-arrow-btn {
    display: none !important;
}

.testimonial-slider-wrapper button.testimonial-arrow-btn.prev-btn {
    right: 30px;
}

.testimonial-slider-wrapper button.testimonial-arrow-btn.next-btn {
    left: 30px;
}

.testimonial-slider-wrapper:before, .testimonial-slider-wrapper:after {
    background: #f3f7fc;
}

button.testimonial-arrow-btn {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 99;
    background-color: transparent;
    border: 1px solid #dfe2e9;
    cursor: pointer;
    bottom: 0;
    overflow: hidden;
}

button.testimonial-arrow-btn:before {
    position: absolute;
    width: 0;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--primary) 100%);
    content: "";
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: -1;
}

button.testimonial-arrow-btn i {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    color: var(--primary);
    font-size: 21px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

button.testimonial-arrow-btn:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border-color: var(--primary);
}

button.testimonial-arrow-btn:hover:before {
    width: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

button.testimonial-arrow-btn:hover i {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.testimonial-slider-arrow {
    position: relative;
    z-index: 99;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
}

@media (max-width: 991px) {
    .testimonial-slider-arrow {
        display: none;
    }
}

.testimonial-slider-wrapper.style-three .slick-list {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .testimonial-slider-wrapper.style-three .slick-list {
        padding-bottom: 30px;
    }
}

.testimonial-slider-wrapper.style-three .slick-list .slick-slide {
    border-radius: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.testimonial-slider-wrapper.style-three .slick-list .slick-slide.slick-active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (max-width: 767px) {
    .testimonial-slider-wrapper.style-three .slick-list .slick-slide.slick-active {
        -webkit-box-shadow: -1px 8px 44px rgba(20, 20, 43, 0.03);
        box-shadow: -1px 8px 44px rgba(20, 20, 43, 0.03);
        border-radius: 12px;
        -webkit-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
    }
}

.testimonial-slider-wrapper.style-three .slick-dots {
    margin-top: 20px;
    position: unset;
    padding-right: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.testimonial-slider-wrapper.has-backgorund-slider .slick-dots {
    margin-bottom: 0;
    position: unset;
    padding-right: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.accordion-wrapper .accordion-list-item .accordion-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 12px 0;
    position: relative;
}

.accordion-wrapper .accordion-list-item .accordion-head:before {
    content: "\f068";
    font-family: "Font Awesome 6 Pro";
    color: #ffffff;
    font-weight: 700;
}

.accordion-wrapper .accordion-list-item .accordion-head.collapsed:before {
    position: relative;
    content: "+";
    font-family: "Font Awesome 6 Pro";
    color: #ffffff;
    font-weight: 700;
}

.accordion-wrapper .accordion-list-item .accordion-head .accordion-title {
    font-size: 21px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 0;
}

.accordion-wrapper .accordion-list-item .accordion-item-body {
    color: #c7cdda;
    padding-right: 50px;
    padding-bottom: 3px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.accordion-wrapper.style-two .accordion-list-item .accordion-head {
    border-top: 1px solid #395670;
    padding: 26px 0;
}

.accordion-wrapper.style-two .accordion-list-item .accordion-head .accordion-title {
    font-size: 24px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .accordion-wrapper.style-two .accordion-list-item .accordion-head .accordion-title {
        font-size: 20px;
    }
}

.accordion-wrapper.style-two .accordion-list-item .accordion-head:before {
    background-color: var(--primary);
    color: #ffffff;
    font-size: 12px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.accordion-wrapper.style-two .accordion-list-item .accordion-head.collapsed:before {
    background-color: #ffffff;
    border-radius: 50px;
    color: var(--primary);
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.accordion-wrapper.style-two .accordion-list-item:last-child {
    border-bottom: 1px solid #395670;
}

.accordion-wrapper.style-two .accordion-list-item .show.accordion-item-body {
    padding-bottom: 20px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.accordion-wrapper.style-two.accordion-style-three .accordion-list-item .accordion-head {
    border-top: 1px solid #dfe2e9;
    padding: 28px 0;
}

.accordion-wrapper.style-two.accordion-style-three .accordion-list-item .accordion-head .accordion-title {
    color: #393d48;
}

.accordion-wrapper.style-two.accordion-style-three .accordion-list-item .accordion-head:before {
    background-color: var(--primary) !important;
    color: #ffffff;
    font-size: 12px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.accordion-wrapper.style-two.accordion-style-three .accordion-list-item .accordion-head.collapsed:before {
    background-color: var(--secondary) !important;
    border-radius: 50px;
    color: #ffffff;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.accordion-wrapper.style-two.accordion-style-three .accordion-list-item .accordion-item-body {
    color: #5e6f92;
}

.accordion-wrapper.style-two.accordion-style-three .accordion-list-item:last-child {
    border-bottom: 1px solid #dfe2e9;
}

.accordion-wrapper.style-four .accordion-list-item {
    margin-bottom: 20px;
}

.accordion-wrapper.style-four .accordion-list-item .accordion-head .accordion-title {
    color: #393d48;
}

.accordion-wrapper.style-four .accordion-list-item .accordion-head:before {
    background-color: var(--primary) !important;
    color: #ffffff;
    font-size: 12px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.accordion-wrapper.style-four .accordion-list-item .accordion-head.collapsed:before {
    background-color: var(--secondary) !important;
    border-radius: 50px;
    color: #ffffff;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.accordion-wrapper.style-four .accordion-list-item .accordion-item-body {
    color: #5e6f92;
}

.accordion-wrapper.style-four .accordion-list-item .show.accordion-item-body {
    padding-top: 16px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.client-logo-wrapper {
    padding: 10px 0;
}

.client-logo-wrapper .client-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.client-logo-wrapper .client-logo img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.65;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.client-logo-wrapper .client-logo:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.client-logo-wrapper .client-logo:hover img {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
}

.client-logo-wrapper.style-two {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 60px 30px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .client-logo-wrapper.style-two {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px 30px;
    }
}

.client-logo-wrapper.style-two .client-logo {
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.client-logo-wrapper.style-two .client-logo img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.65;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

@media (max-width: 767px) {
    .client-logo-wrapper.style-two .client-logo img {
        width: 65%;
    }
}

@media (max-width: 575px) {
    .client-logo-wrapper.style-two .client-logo img {
        width: 60%;
    }
}

.client-logo-wrapper.style-two .client-logo:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.client-logo-wrapper.style-two .client-logo:hover img {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
}

.page-header {
    border-right: 4px solid #dfe2e9;
    padding-right: 45px;
    margin-bottom: 50px;
}

@media (max-width: 991px) {
    .page-header {
        padding-right: 30px;
    }
}

.page-header .page-title {
    font-size: 48px;
    line-height: 60px;
}

@media (max-width: 991px) {
    .page-header .page-title {
        font-size: 38px;
        line-height: 48px;
    }
}

.blog-area ol, .blog-area ul {
    padding-right: 20px;
}

.blog-area ol li, .blog-area ul li {
    padding-top: 7px;
}

.blog-details-wrapper .comment-respond {
    margin-top: 78px;
    margin-bottom: 40px;
}

.blog-details-wrapper .post-comments {
    margin-top: 70px;
}

.blog-details-wrapper .wp-block-group.has-background .wp-block-button .wp-block-button__link {
    color: #ffffff !important;
}

.blog-details-wrapper .single-post-item {
    border: 0;
    overflow: visible;
}

.blog-details-wrapper .single-post-item iframe {
    width: 100%;
    height: 522px;
}

.blog-details-wrapper .single-post-item .post-meta {
    margin-bottom: 20px;
}

.blog-details-wrapper .single-post-item .post-gallery:hover img {
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}

.blog-details-wrapper .single-post-item .post-thumbnail, .blog-details-wrapper .single-post-item .post-video, .blog-details-wrapper .single-post-item .post-gallery, .blog-details-wrapper .single-post-item .postbox-audio, .blog-details-wrapper .single-post-item .wp-block-post-featured-image {
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 45px;
    position: relative;
}

@media (max-width: 767px) {
    .blog-details-wrapper .single-post-item .post-thumbnail, .blog-details-wrapper .single-post-item .post-video, .blog-details-wrapper .single-post-item .post-gallery, .blog-details-wrapper .single-post-item .postbox-audio, .blog-details-wrapper .single-post-item .wp-block-post-featured-image {
        margin-bottom: 30px;
    }
}

.blog-details-wrapper .single-post-item .post-thumbnail:hover img, .blog-details-wrapper .single-post-item .post-video:hover img, .blog-details-wrapper .single-post-item .post-gallery:hover img, .blog-details-wrapper .single-post-item .postbox-audio:hover img, .blog-details-wrapper .single-post-item .wp-block-post-featured-image:hover img {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.blog-details-wrapper .single-post-item .post-thumbnail img, .blog-details-wrapper .single-post-item .post-video img, .blog-details-wrapper .single-post-item .post-gallery img, .blog-details-wrapper .single-post-item .postbox-audio img, .blog-details-wrapper .single-post-item .wp-block-post-featured-image img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.blog-details-wrapper .single-post-item .post-content-wrapper {
    padding: 0 0 10px 0;
}

.blog-details-wrapper .single-post-item .post-content {
    margin-bottom: 17px;
}

.blog-details-wrapper .single-post-item .post-content .wp-block-query .wp-block-post-template {
    padding-right: 0;
}

.blog-details-wrapper .single-post-item .post-content .wp-block-query .wp-block-post-template .wp-block-post {
    margin-bottom: 30px;
}

.blog-details-wrapper .single-post-item .post-content .wp-block-navigation__responsive-container-content .wp-block-page-list {
    padding-right: 0;
}

.blog-details-wrapper .single-post-item .post-content .wp-block-group {
    margin: 15px 0;
}

.blog-details-wrapper .single-post-item .post-content .wp-block-group p {
    margin-bottom: 0;
}

.blog-details-wrapper .single-post-item .post-content p {
    margin-bottom: 20px;
}

.blog-details-wrapper .single-post-item .post-content p img {
    margin: 20px 0;
    border-radius: 8px;
}

.blog-details-wrapper .single-post-item .blog-post-tag .social-share {
    padding-bottom: 20px;
}

.blog-details-wrapper .wp-block-calendar {
    margin-bottom: 30px;
}

.single-post-item {
    background-color: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 48px;
    border: 1px solid #dfe2e9;
}

.single-post-item:hover .post-thumbnail img, .single-post-item:hover .post-video img {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item iframe {
    width: 100%;
    height: 522px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .single-post-item iframe {
        height: 350px;
    }
}

.single-post-item .post-content-wrapper {
    padding: 45px 47px 41px 47px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .single-post-item .post-content-wrapper {
        padding: 35px;
        padding-top: 30px;
    }
}

.single-post-item .post-content-wrapper .read-more {
    margin-top: 30px;
    margin-bottom: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 30px;
}

.single-post-item.format-quote .post-content {
    margin: 0;
}

.single-post-item.format-quote blockquote {
    margin: 0;
}

.single-post-item.format-quote blockquote p {
    margin-top: 0;
}

.single-post-item.format-quote blockquote cite a {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #5e6f92;
    margin-top: 20px;
    font-style: normal;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item.format-quote blockquote cite a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: var(--secondary);
}

.single-post-item.sticky {
    border: 2px solid var(--secondary);
}

.single-post-item.sticky .post-content {
    margin-bottom: 0;
}

.single-post-item.sticky .post-content p {
    margin-bottom: 0;
}

.single-post-item .post-title {
    font-size: 36px;
    line-height: 54px;
    font-weight: 700;
    margin-bottom: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .single-post-item .post-title {
        font-size: 28px;
        line-height: 42px;
        margin-top: 5px;
    }
}

.single-post-item .post-title:hover a {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item .post-content p {
    margin-top: 17px;
}

.single-post-item .post-content h2, .single-post-item .post-content h3, .single-post-item .post-content h4, .single-post-item .post-content h5, .single-post-item .post-content h6 {
    margin-bottom: 10px;
}

.single-post-item .post-content p {
    margin-bottom: unset;
}

.single-post-item .single-post-meta {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.single-post-item .social-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 17px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.single-post-item .social-share a.facebook {
    color: #1877f2;
}

.single-post-item .social-share a.twitter {
    color: #1da1f2;
}

.single-post-item .social-share a.linkedin {
    color: #1a70c6;
}

.single-post-item .social-share .social-share-title {
    font-size: 16px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #393d48;
    margin-left: 5px;
    text-transform: uppercase;
}

.single-post-item .blog-post-tag {
    width: 100%;
    display: inline-block;
}

.single-post-item .blog-post-tag span {
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    font-family: "Poppins", sans-serif;
    color: #393d48;
    margin-bottom: 0;
    margin-left: 30px;
    text-transform: uppercase;
    margin-top: 8px;
    float: right;
}

.single-post-item .blog-post-tag .post-tag-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.single-post-item .blog-post-tag .post-tag-list a {
    color: #5e6f92;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 12px 22px;
    border-radius: 50px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border: 1px solid #bfc5cb;
}

.single-post-item .blog-post-tag .post-tag-list a:hover {
    color: #ffffff;
    background: var(--secondary);
    border-color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item .post-thumbnail, .single-post-item .post-video {
    position: relative;
    overflow: hidden;
}

.single-post-item .post-thumbnail img, .single-post-item .post-video img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item .post-thumbnail .popup-video-wrapper, .single-post-item .post-video .popup-video-wrapper {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.single-post-item .post-thumbnail .video-btn a, .single-post-item .post-video .video-btn a {
    width: 70px;
    height: 70px;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    background-color: #ffffff;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.single-post-item .post-thumbnail .video-btn a i, .single-post-item .post-video .video-btn a i {
    color: var(--secondary);
    font-size: 18px;
}

.single-post-item .post-thumbnail .video-btn a:hover, .single-post-item .post-video .video-btn a:hover {
    -webkit-animation: none;
    animation: none;
}

.single-post-item button.post-gallery-btn {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 99;
    background-color: #ffffff;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: hidden;
}

.single-post-item button.post-gallery-btn.prev-btn {
    right: 30px;
}

.single-post-item button.post-gallery-btn.next-btn {
    left: 30px;
}

.single-post-item button.post-gallery-btn i {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    color: #393d48;
    font-size: 17px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item button.post-gallery-btn:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: var(--secondary);
}

.single-post-item button.post-gallery-btn:hover i {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item .post-audio iframe {
    width: 100%;
}

.single-post-item .post-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 32px;
    margin-bottom: 2px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .single-post-item .post-meta {
        gap: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.single-post-item .post-meta span {
    font-size: 18px;
    font-weight: 400;
    color: #5e6f92;
    text-transform: capitalize;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.single-post-item .post-meta span i {
    color: var(--secondary);
    font-size: 18px;
}

.single-post-item .post-meta span a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    color: #5e6f92;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item .post-meta span a:hover {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.single-post-item .post-meta span a:hover i {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.author-info {
    background-color: #eef5f8;
    padding: 90px 45px 45px 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    border-radius: 14px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    margin-top: 130px;
    margin-bottom: 70px;
}

.author-info .author-thumb {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    border-radius: 50px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
}

.author-info .author-thumb img {
    border-radius: 50%;
}

.author-info .author-text h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 28px;
}

.author-info .author-text .designation {
    font-size: 16px;
    font-style: italic;
    color: #9ba8c3;
    text-transform: capitalize;
}

.author-info .author-text p {
    margin-top: 30px;
    margin-bottom: 0;
}

.author-info .author-social-profiles {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.author-info .author-social-profiles a {
    font-size: 18px;
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    width: 44px;
    height: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--secondary);
    border-radius: 10px;
}

.author-info .author-social-profiles a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: #ffffff;
    color: var(--secondary);
}

.author-info .author-post a {
    font-size: 16px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #393d48;
    border-bottom: 2px solid var(--primary);
    margin-top: 30px;
    display: inline-block;
}

.author-info .author-post a:hover {
    color: var(--primary);
}

.blog-post-nav {
    margin: 40px 0;
    background: #fff;
    padding: 23px 30px;
    padding-bottom: 22px;
    border-radius: 8px;
    border: 1px solid rgb(223, 226, 233);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .blog-post-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 30px;
        margin: 30px 0;
    }
}

@media (max-width: 767px) {
    .blog-post-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 30px;
        margin: 30px 0;
    }
}

.blog-post-nav .divider {
    background-color: rgb(223, 226, 233);
    width: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .blog-post-nav .divider {
        width: 100%;
        height: 1px;
    }
}

@media (max-width: 767px) {
    .blog-post-nav .divider {
        width: 100%;
        height: 1px;
    }
}

.blog-post-nav .post-navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 50%;
}

@media (max-width: 374px) {
    .blog-post-nav .post-navigation {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .blog-post-nav .post-navigation {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .blog-post-nav .post-navigation {
        width: 100%;
    }
}

.blog-post-nav .post-navigation:last-child {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.blog-post-nav .post-navigation:last-child .text {
    text-align: left;
}

@media (max-width: 374px) {
    .blog-post-nav .post-navigation:last-child .text {
        text-align: right;
    }
}

.blog-post-nav .post-navigation .post-img {
    width: 96px;
    height: 96px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media (max-width: 374px) {
    .blog-post-nav .post-navigation .post-img {
        width: 100% !important;
        height: 150px !important;
    }
}

.blog-post-nav .post-navigation .post-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    right: 0;
    top: 0;
}

.blog-post-nav .post-navigation .text {
    width: 100%;
}

.blog-post-nav .post-navigation .text span {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    color: #ff5b5b;
}

.blog-post-nav .post-navigation .text h4 {
    font-size: 21px;
    margin: 0;
    line-height: 30px;
    text-transform: capitalize;
}

.post-card.style-one {
    min-height: 218px;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .post-card.style-one {
        gap: 10px;
        padding: 30px;
    }
}

.post-card.style-one:hover .image img {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    visibility: visible;
}

.post-card.style-one:hover .image:before {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-one:hover .date-bottom {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-one:hover .content .title {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-one:hover .content .post-meta span:first-child {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-one:hover .content .post-meta span:first-child a {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-one .content .title {
    height: 56px;
    overflow: hidden;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .post-card.style-one .content .title {
        height: 84px;
    }
}

.post-card.style-one .image {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
}

.post-card.style-one .image img {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
    visibility: hidden;
}

.post-card.style-one .image:before {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-color: #ffffff;
    content: "";
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: 2;
    visibility: visible;
    opacity: 1;
}

.post-card.style-one .image:after {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: 1;
}

.post-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 37px;
    border: 1px solid rgb(223, 226, 233);
    border-radius: 13px;
    gap: 40px;
    margin-bottom: 34px;
    position: relative;
    overflow: hidden;
}

.post-card .image {
    overflow: hidden;
    border-radius: 6px;
}

.post-card .image img {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.post-card .overlay {
    width: 100%;
    height: 100%;
    background: #393d48;
    opacity: 24%;
}

.post-card .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    position: relative;
    z-index: 3;
}

.post-card .content .post-meta {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 17px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.post-card .content .post-meta span {
    color: #5e6f92;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    margin: 0;
}

.post-card .content .post-meta span:before {
    background-color: #b8bfcc;
    width: 1px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 12px;
    content: "";
    margin-left: 17px;
}

.post-card .content .post-meta span:first-child {
    color: var(--primary);
}

.post-card .content .post-meta span:first-child a {
    color: var(--primary);
}

.post-card .content .post-meta span:first-child a:hover {
    color: var(--primary) !important;
}

.post-card .content .post-meta span:first-child:before {
    display: none;
}

.post-card .content .title {
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin: 0;
    color: #393d48;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card .date {
    margin-bottom: 0;
}

.post-card .date-bottom {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0;
    color: #778196;
    position: relative;
    z-index: 3;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .post-card {
        padding: 20px 25px;
        gap: 55px;
    }

    .post-card .content .post-meta {
        font-size: 14px;
    }

    .post-card .content .title {
        font-size: 16px;
        line-height: 26px;
    }
}

.post-card.style-two {
    padding: 0;
    border: none;
    border-radius: 0;
    gap: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 36px;
}

.post-card.style-two:hover .image img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.post-card.style-two:hover .image .icon {
    z-index: 2;
    opacity: 1;
    -webkit-transform: translate(50%, -50%) scale(1);
    transform: translate(50%, -50%) scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-two:hover .image:before {
    opacity: 0.6;
    z-index: 1;
}

.post-card.style-two .image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    width: 180px;
    height: 132px;
}

.post-card.style-two .image img {
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    right: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    width: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-two .image:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #393d48;
    z-index: -2;
    opacity: 0;
}

.post-card.style-two .image .icon {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: -1;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-two .image .icon i {
    color: #ffffff;
}

.post-card.style-two .content {
    gap: 12px;
}

.post-card.style-two .content .post-meta span:last-child {
    display: block;
}

.post-card.style-two .content .post-meta span:first-child:before {
    display: none;
}

.post-card.style-two .content .post-meta .date {
    color: #778196;
    font-weight: 400;
}

.post-card.style-two .content .title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .post-card.style-two .content .title {
        height: 66px;
        overflow: hidden;
    }
}

.post-card.style-two .content .title:hover {
    color: var(--secondary);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .post-card.style-two {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;
    }

    .post-card.style-two .image {
        width: 100%;
        height: 340px;
    }
}

.post-card.style-three {
    height: 470px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    overflow: hidden;
    border: 0;
}

@media (max-width: 280px) {
    .post-card.style-three {
        padding: 20px;
    }
}

.post-card.style-three:before {
    background-color: #393d48;
    opacity: 0.3;
}

.post-card.style-three:hover .image img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-three .image {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 2;
}

.post-card.style-three .image img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-three .image .image-overlay {
    background: -webkit-gradient(linear, right top, right bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.post-card.style-three .content .post-meta {
    color: #ffffff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 17px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
}

@media (max-width: 280px) {
    .post-card.style-three .content .post-meta {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.post-card.style-three .content .post-meta span {
    color: #ffffff;
    margin: 0;
    font-weight: 400;
}

.post-card.style-three .content .post-meta span a {
    color: #ffffff;
}

.post-card.style-three .content .post-meta span a:hover {
    color: var(--primary);
}

.post-card.style-three .content .post-meta span:before {
    background-color: #b8bfcc;
    width: 1px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 12px;
    content: "";
    margin-left: 17px;
}

.post-card.style-three .content .post-meta span:last-child {
    display: block;
}

.post-card.style-three .content .post-meta span:first-child:before {
    display: none;
}

.post-card.style-three .content .title {
    color: #ffffff;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
}

.post-card.style-three .content .title:hover {
    color: var(--primary);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .post-card.style-three {
        height: 340px;
    }

    .post-card.style-three .content .post-meta {
        font-size: 14px;
    }

    .post-card.style-three .content .title {
        font-size: 24px;
        line-height: 38px;
    }
}

.post-card.style-four {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: none;
    padding: 0;
    gap: 25px;
    margin-bottom: 45px;
}

@media (max-width: 991px) {
    .post-card.style-four {
        gap: 22px;
    }
}

.post-card.style-four:hover .image img {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
}

.post-card.style-four .image {
    position: relative;
}

.post-card.style-four .image img {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-card.style-four .content .title a {
    font-size: 24px;
    line-height: 33px;
}

.post-card.style-four .content .title:hover {
    color: var(--secondary);
}

.post-card.style-four .content .post-meta span {
    color: var(--primary);
    line-height: 30px;
    font-weight: 700;
}

.post-card.style-four .content .post-meta span:last-child {
    display: block;
}

.post-card.style-four .content .post-meta span:first-child:before {
    display: none;
}

.post-card.style-four .content .post-meta .date {
    color: #778196;
    font-weight: 400;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .post-card.style-four {
        gap: 20px;
    }
}

.skill-progressbar-wrapper {
    width: 57%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 14px;
    padding-left: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .skill-progressbar-wrapper {
        width: 100%;
        padding-left: 0;
    }
}

.skill-progressbar .progress-inner-item {
    position: relative;
    margin: 0;
    line-height: inherit;
}

.skill-progressbar .skill-title {
    z-index: 100;
    color: #393d48;
    font-size: 18px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}

.skill-progressbar .progressbar-number {
    width: 100%;
    z-index: 10;
}

.skill-progressbar .progress-number-count {
    padding: 0 8px;
    position: absolute;
    transform: translateX(50%);
    -ms-transform: translateX(50%);
    -webkit-transform: translateX(50%);
}

.skill-progressbar .progressbar-number, .skill-progressbar .progress-number-count {
    font-size: 16px;
    line-height: 41px;
    height: 6px;
    letter-spacing: 0px;
    font-weight: 400;
    text-transform: none;
    font-family: "Roboto", sans-serif;
    color: #5e6f92;
}

.skill-progressbar .progress-content-outter {
    height: 6px;
    background-color: #e7eaec;
    border-radius: 50px;
}

.skill-progressbar .progress-content {
    height: 6px;
    background-color: var(--secondary);
    width: 0%;
    border-radius: 50px;
}

.price-card {
    padding: 30px 18px 48px 18px;
    background: white;
    z-index: 2;
    position: relative;
    border-radius: 14px;
}

.price-card:hover .price:before {
    height: 250%;
    -webkit-transition: all 700ms linear;
    transition: all 700ms linear;
    background-color: transparent;
}

.price-card .card-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.price-card .card-title .sub-title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    text-transform: capitalize;
    color: var(--primary);
    margin: 0;
}

.price-card .card-title .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #393d48;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
}

.price-card .price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: linear-gradient(135deg, var(--primary) 0%, var(--secondary) 100%);
    border-radius: 7px;
    padding-top: 36px;
    padding-bottom: 28px;
    gap: 8px;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.price-card .price:before {
    position: absolute;
    width: 200%;
    height: 0%;
    right: 50%;
    top: 50%;
    background: linear-gradient(-135deg, var(--primary) 0%, var(--secondary) 100%);
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
    content: "";
    z-index: -1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.price-card .price .price-heading {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 42px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2px;
    margin-bottom: 0;
}

.price-card .price .price-heading .currency {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    text-align: start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.price-card .price .price-heading .under-dot {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    color: #ffffff;
    -ms-flex-item-align: end;
    align-self: end;
}

.price-card .price .price-desc {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 0;
}

.price-card .list-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.price-card .list {
    margin-top: 0;
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 6px;
}

.price-card .list .price-list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
}

.price-card .list .price-list-item .fa-check {
    color: var(--primary);
}

.price-card .list .price-list-item .fa-x {
    color: #bbc7cf;
}

.price-card .list .price-list-item .text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #5e6f92;
    margin: 0;
}

.price-card .theme-btn {
    padding: 5px 26px 5px 6px;
    gap: 30px;
    font-size: 16px;
}

.cta-area {
    position: relative;
    margin-bottom: -110px;
    z-index: 99;
}

.call-to-action-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--secondary);
    padding: 68px 50px;
    border-radius: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 60px;
}

@media (max-width: 375px) {
    .call-to-action-card {
        padding: 40px 25px !important;
    }
}

@media (max-width: 991px) {
    .call-to-action-card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 30px;
    }
}

@media (max-width: 767px) {
    .call-to-action-card {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.call-to-action-card .image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media (max-width: 1399px) {
    .call-to-action-card .image {
        width: 200px;
    }
}

.call-to-action-card .content {
    position: relative;
    padding-right: 60px;
}

@media (max-width: 991px) {
    .call-to-action-card .content {
        padding-right: 0;
    }
}

.call-to-action-card .content .title {
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 0;
}

@media (max-width: 1399px) {
    .call-to-action-card .content .title {
        font-size: 18px;
        line-height: 32px;
    }
}

.call-to-action-card .content:before {
    position: absolute;
    content: "";
    height: 100%;
    right: 0;
    top: 0;
    width: 1px;
    background-color: #6fcbbd;
}

@media (max-width: 991px) {
    .call-to-action-card .content:before {
        display: none;
    }
}

.call-to-action-card .btn-wrapper {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.triangle-shape-card-one {
    width: 200px;
    height: 375px;
    background-color: var(--primary);
    -webkit-clip-path: polygon(68% 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(80% 0, 100% 0, 100% 100%, 0% 100%);
    position: absolute;
    bottom: -150px;
    left: 0;
    z-index: 2;
    -webkit-animation: MoveUpDown 2s linear infinite alternate;
    animation: MoveUpDown 2s linear infinite alternate;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .triangle-shape-card-one {
        height: 260px;
        bottom: -80px;
    }
}

@media (max-width: 991px) {
    .triangle-shape-card-one {
        width: 150px;
        height: 150px;
        display: none;
    }
}

.triangle-shape-card-two {
    width: 310px;
    height: 290px;
    background-color: var(--secondary);
    -webkit-clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(40% 0, 100% 0, 100% 100%, 0% 100%);
    position: absolute;
    bottom: -210px;
    left: 0;
    overflow: hidden;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .triangle-shape-card-two {
        height: 240px;
        bottom: -100px;
    }
}

@media (max-width: 991px) {
    .triangle-shape-card-two {
        width: 150px;
        height: 150px;
        bottom: -70px;
        display: none;
    }
}

.triangle-shape-card-three {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 99;
    border-top: 550px solid #393d48;
    border-left: 220px solid transparent;
}

@media (max-width: 630px) {
    .triangle-shape-card-three {
        display: none;
    }
}

.triangle-shape-card-four {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    border-bottom: 330px solid var(--primary);
    border-right: 130px solid transparent;
}

@media (max-width: 650px) {
    .triangle-shape-card-four {
        border-bottom: 190px solid var(--primary);
        border-right: 80px solid transparent;
    }
}

.section-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 2;
    position: relative;
}

@media (max-width: 767px) {
    .section-title.mb-70, .section-title.mb-75 {
        margin-bottom: 50px;
    }
}

.section-title .short-title {
    font-size: 24px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: var(--primary);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .section-title .short-title {
        font-size: 20px;
    }
}

.section-title .title {
    font-size: 48px;
    line-height: 60px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #393d48;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .section-title .title {
        font-size: 47px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title .title {
        font-size: 38px;
        line-height: 48px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .section-title .title {
        font-size: 32px;
        line-height: 42px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 439px) {
    .section-title .title br {
        display: none;
    }
}

.section-title .title span {
    color: var(--secondary);
}

.section-title .desc {
    font-size: 18px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #5e6f92;
    margin-bottom: 0;
    margin-top: 10px;
}

.section-title .text {
    margin-bottom: 0;
}

.section-title .divider {
    width: 72px;
    height: 6px;
    background-color: var(--secondary);
    margin-top: 16px;
}

.page-breadcrumb-area {
    padding: 127px 0;
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: var(--secondary);
}

@media (max-width: 991px) {
    .page-breadcrumb-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .page-breadcrumb-area {
        padding: 80px 0;
    }
}

.page-overlay {
    background-image: linear-gradient(-135deg, var(--primary) 0%, var(--secondary) 100%);
    opacity: 0.6;
    height: 100%;
    right: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
}

.page-heading h3, .page-heading .page-title {
    font-family: "Poppins", sans-serif;
    font-size: 72px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
    text-transform: capitalize;
    line-height: 84px;
    font-weight: 700;
}

@media (max-width: 375px) {
    .page-heading h3, .page-heading .page-title {
        font-size: 32px;
        line-height: 42px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .page-heading h3, .page-heading .page-title {
        font-size: 42px;
        line-height: 52px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .page-heading h3, .page-heading .page-title {
        font-size: 60px;
        line-height: 72px;
    }
}

.breadcrumb-wrapper {
    padding: 0 100px;
}

@media (max-width: 1199px) {
    .breadcrumb-wrapper {
        padding: 0;
    }
}

.breadcrumb-list {
    text-align: center;
}

.breadcrumb-list ul {
    margin-bottom: 0;
    padding-right: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 0 12px;
}

@media (max-width: 375px) {
    .breadcrumb-list ul {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.breadcrumb-list ul li {
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    gap: 0 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
}

.breadcrumb-list ul li.active {
    color: var(--primary);
}

.breadcrumb-list ul li.active a {
    color: var(--primary);
}

.breadcrumb-list ul li a {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.breadcrumb-list ul li a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: var(--primary);
}

.breadcrumb-list ul li:before {
    content: "\f054";
    font-size: 12px;
    color: #fff;
    font-family: "Font Awesome 6 Pro";
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.breadcrumb-list ul li:first-child:before {
    display: none;
}

@media (max-width: 375px) {
    .breadcrumb-list ul li:first-child:before {
        display: block !important;
    }
}

.wp-block-query-pagination, .navigation.posts-navigation, .comment-navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.wp-block-query-pagination .wp-block-query-pagination-numbers, .navigation.posts-navigation .wp-block-query-pagination-numbers, .comment-navigation .wp-block-query-pagination-numbers {
    width: inherit;
}

.wp-block-query-pagination .nav-links, .navigation.posts-navigation .nav-links, .comment-navigation .nav-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
}

.wp-block-query-pagination .wp-block-query-pagination-previous, .wp-block-query-pagination .wp-block-query-pagination-next, .wp-block-query-pagination .nav-next a, .wp-block-query-pagination .nav-previous a, .navigation.posts-navigation .wp-block-query-pagination-previous, .navigation.posts-navigation .wp-block-query-pagination-next, .navigation.posts-navigation .nav-next a, .navigation.posts-navigation .nav-previous a, .comment-navigation .wp-block-query-pagination-previous, .comment-navigation .wp-block-query-pagination-next, .comment-navigation .nav-next a, .comment-navigation .nav-previous a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #5e6f92;
    background-color: #ffffff;
    border-radius: 6px;
    height: 53px;
    padding: 0 20px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #dfe2e9;
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .wp-block-query-pagination .wp-block-query-pagination-previous, .wp-block-query-pagination .wp-block-query-pagination-next, .wp-block-query-pagination .nav-next a, .wp-block-query-pagination .nav-previous a, .navigation.posts-navigation .wp-block-query-pagination-previous, .navigation.posts-navigation .wp-block-query-pagination-next, .navigation.posts-navigation .nav-next a, .navigation.posts-navigation .nav-previous a, .comment-navigation .wp-block-query-pagination-previous, .comment-navigation .wp-block-query-pagination-next, .comment-navigation .nav-next a, .comment-navigation .nav-previous a {
        height: 40px;
    }
}

.wp-block-query-pagination .wp-block-query-pagination-previous:hover, .wp-block-query-pagination .wp-block-query-pagination-next:hover, .wp-block-query-pagination .nav-next a:hover, .wp-block-query-pagination .nav-previous a:hover, .navigation.posts-navigation .wp-block-query-pagination-previous:hover, .navigation.posts-navigation .wp-block-query-pagination-next:hover, .navigation.posts-navigation .nav-next a:hover, .navigation.posts-navigation .nav-previous a:hover, .comment-navigation .wp-block-query-pagination-previous:hover, .comment-navigation .wp-block-query-pagination-next:hover, .comment-navigation .nav-next a:hover, .comment-navigation .nav-previous a:hover {
    background: var(--primary) !important;
    color: #ffffff;
    border-color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.basic-pagination, .page-links, .wp-block-query-pagination-numbers {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.basic-pagination ul, .page-links ul, .wp-block-query-pagination-numbers ul {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-right: 0;
}

.basic-pagination ul li, .page-links ul li, .wp-block-query-pagination-numbers ul li {
    padding-top: 0;
}

.basic-pagination ul .prev span, .basic-pagination ul .next span, .page-links ul .prev span, .page-links ul .next span, .wp-block-query-pagination-numbers ul .prev span, .wp-block-query-pagination-numbers ul .next span {
    display: none;
}

.basic-pagination .post-page-numbers, .page-links .post-page-numbers, .wp-block-query-pagination-numbers .post-page-numbers {
    background-color: rgba(71, 112, 247, 0.1) !important;
}

.basic-pagination .page-numbers, .basic-pagination .post-page-numbers, .page-links .page-numbers, .page-links .post-page-numbers, .wp-block-query-pagination-numbers .page-numbers, .wp-block-query-pagination-numbers .post-page-numbers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #5e6f92;
    background-color: #ffffff;
    border-radius: 6px;
    height: 53px;
    width: 53px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #dfe2e9;
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .basic-pagination .page-numbers, .basic-pagination .post-page-numbers, .page-links .page-numbers, .page-links .post-page-numbers, .wp-block-query-pagination-numbers .page-numbers, .wp-block-query-pagination-numbers .post-page-numbers {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
}

.basic-pagination .page-numbers:hover, .basic-pagination .post-page-numbers:hover, .page-links .page-numbers:hover, .page-links .post-page-numbers:hover, .wp-block-query-pagination-numbers .page-numbers:hover, .wp-block-query-pagination-numbers .post-page-numbers:hover {
    background: var(--primary) !important;
    color: #ffffff;
    border-color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.basic-pagination .page-numbers.current, .basic-pagination .post-page-numbers.current, .page-links .page-numbers.current, .page-links .post-page-numbers.current, .wp-block-query-pagination-numbers .page-numbers.current, .wp-block-query-pagination-numbers .post-page-numbers.current {
    background: var(--primary) !important;
    color: #ffffff;
    border-color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.nav-tab {
    list-style: none;
    padding-right: 0;
    border-bottom: 1px solid #dfe2e9;
}

.nav-tab li {
    position: relative;
    display: inline-block;
    margin-left: 50px;
    color: #393d48;
    font-family: "Poppins", sans-serif;
    display: inline-block;
    font-size: 21px;
    line-height: 20px;
    padding: 18px 0;
    font-weight: 700;
    position: relative;
    cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
    .nav-tab li {
        margin-left: 26px;
    }
}

@media (max-width: 575px) {
    .nav-tab li {
        padding: 16px 0;
    }
}

.nav-tab li:hover {
    color: var(--primary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.nav-tab li:before {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: -1px;
    right: 0;
    background-color: var(--primary);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

@media (max-width: 575px) {
    .nav-tab li {
        width: 100%;
        margin: 0;
    }
}

.nav-tab li:last-child {
    margin-left: 0;
}

.nav-tab li.active {
    color: var(--primary);
}

.nav-tab li.active:before {
    width: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.tab-content img {
    border-radius: 8px;
}

@media (max-width: 575px) {
    .tab-content img {
        width: 100%;
        margin-bottom: 25px;
    }
}

.time-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f3f7fc;
}

.time-card-wrapper .time-card-item {
    width: 100%;
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    position: relative;
}

.time-card-wrapper .time-card-item:nth-of-type(even) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.time-card-wrapper .time-card-item::after {
    content: "";
    width: 3px;
    height: 100%;
    left: 0;
    right: 50%;
    top: 0;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    position: absolute;
    background: #dde4f6;
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
    .time-card-wrapper .time-card-item::after {
        display: none;
    }
}

.time-card-wrapper .time-card-item .time-card-desc {
    width: 424px;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

@media (max-width: 450px) {
    .time-card-wrapper .time-card-item .time-card-desc {
        width: 100%;
    }
}

.time-card-wrapper .time-card-item .time-card-desc .title {
    padding: 6px 0;
    background: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.time-card-wrapper .time-card-item .time-card-desc .title h2 {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    line-height: 60px;
    font-weight: 700;
    color: #ffffff;
}

.time-card-wrapper .time-card-item .time-card-desc .desc {
    padding: 40px;
}

.time-card-wrapper .time-card-item .time-card-desc .desc p {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #5e6f92;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .time-card-wrapper .time-card-item .time-card-desc .desc {
        padding: 20px;
    }
}

.time-card-wrapper .time-card-item .circle-wrapper {
    position: relative;
    width: 248px;
    padding-top: 35px;
}

@media (max-width: 991px) {
    .time-card-wrapper .time-card-item .circle-wrapper {
        padding: 0;
    }
}

.time-card-wrapper .time-card-item .circle-wrapper .time-card-circle {
    position: relative;
}

@media (max-width: 991px) {
    .time-card-wrapper .time-card-item .circle-wrapper .time-card-circle {
        height: 85px;
    }
}

.time-card-wrapper .time-card-item .circle-wrapper .time-card-circle:before {
    content: "";
    min-width: 18px;
    min-height: 18px;
    border-radius: 50px;
    background: var(--primary);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: absolute;
    z-index: 3;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    right: 50%;
    top: 50%;
}

.time-card-wrapper .time-card-item .circle-wrapper .time-card-circle:after {
    content: "";
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    background: #dde4f6;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991px) {
    .time-card-wrapper .time-card-item .circle-wrapper .time-card-circle:after {
        right: 50%;
        top: 50%;
        -webkit-transform: translate(50%, -50%);
        transform: translate(50%, -50%);
        width: 3px;
        height: 100%;
    }
}

.time-card-wrapper .time-card-item .time-card-time {
    margin-top: 10px;
}

.time-card-wrapper .time-card-item .time-card-time .time-card-date {
    color: #f3f8fc;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    background-color: var(--secondary);
    display: inline-block;
    border-radius: 50px;
    padding: 9px 35px;
    margin-bottom: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .time-card-wrapper .time-card-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-bottom: 30px;
    }

    .time-card-wrapper .time-card-item:nth-of-type(even) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .time-card-wrapper .time-card-item:after {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .time-card-wrapper .time-card-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding: 30px 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-bottom: 30px;
    }

    .time-card-wrapper .time-card-item:nth-of-type(even) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .time-card-wrapper .time-card-item:after {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .time-card-wrapper .time-card-item {
        padding: 30px 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
    .time-card-wrapper .time-card-item {
        padding: 30px 8px;
    }
}

.time-card-wrapper .time-card-arrow {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 42px;
    height: 42px;
    border-radius: 50px;
    background: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.time-card-wrapper .time-card-arrow i {
    font-size: 16px;
    color: #ffffff;
}

.project-page-wrapper {
    padding-top: 144px;
    padding-bottom: 114px;
}

@media (max-width: 991px) {
    .project-page-wrapper {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .project-page-wrapper {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}

.project-page-wrapper .basic-pagination {
    margin-bottom: 0;
    margin-top: 30px;
}

.project-detail-area {
    padding: 144px 0;
}

@media (max-width: 991px) {
    .project-detail-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .project-detail-area {
        padding-top: 70px;
        padding-bottom: 120px;
    }
}

.project-detail-area .project-details-padding-left {
    padding-right: 60px;
}

@media (max-width: 1199px) {
    .project-detail-area .project-details-padding-left {
        padding-right: 12px;
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .project-detail-area .project-details-padding-left {
        margin-top: 20px;
    }
}

.project-detail-area img {
    border-radius: 12px;
}

@media (max-width: 767px) {
    .project-detail-area .mt-65 {
        margin-top: 45px;
    }
}

.project-gallery-image {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

.project-gallery-image:hover:before {
    height: 250%;
    -webkit-transition: all 900ms linear;
    transition: all 900ms linear;
    background-color: transparent;
}

.project-gallery-image:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.project-gallery-image:before {
    position: absolute;
    width: 200%;
    height: 0%;
    right: 50%;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
    content: "";
    z-index: 1;
}

.project-gallery-image img {
    width: 100%;
    height: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.project-details-title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #393d48;
    margin: 0;
}

.simillar-project-title h2 {
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: #393d48;
    margin: 0;
}

@media (max-width: 767px) {
    .simillar-project-title h2 {
        font-size: 28px;
        line-height: 32px;
    }
}

@media (max-width: 991px) {
    .simillar-project-title {
        margin-bottom: 30px;
    }
}

.project-info-widget {
    background: #ebf4f8;
    padding: 55px 58px;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
}

.project-info-widget .info-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px;
}

.project-info-widget .info-item h3 {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: #393d48;
}

.project-info-widget .info-item p {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #5e6f92;
}

.list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 45px;
}

@media (max-width: 991px) {
    .list-item {
        margin-bottom: 20px;
    }
}

.list-item .icon {
    width: 54px;
    height: 54px;
    border-radius: 50px;
    background: var(--primary);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media (max-width: 991px) {
    .list-item .icon {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .list-item .icon {
        width: 35px;
        height: 35px;
        font-size: 14px;
    }
}

.list-item .icon i {
    color: #ffffff;
}

.list-item .text {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: #5e6f92;
    margin: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .list-item {
        margin-bottom: 20px;
    }
}

.list-item.style-two {
    gap: 18px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    margin-bottom: 20px;
}

.list-item.style-two .icon {
    background: transparent;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    width: auto;
    height: auto;
}

.list-item.style-two .icon i {
    color: var(--primary);
    line-height: 30px;
}

.list-item.style-two .text {
    margin: 0;
    font-weight: 400;
}

.list-item ul {
    padding-right: 0;
}

.list-item ul li {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 18px;
    padding-top: 10px;
}

.list-item ul li:last-child {
    padding-bottom: 0;
}

.list-item ul li i {
    color: var(--secondary);
    font-size: 20px;
    position: relative;
    top: 5px;
}

.sidebar {
    padding-right: 13px;
}

@media (max-width: 991px) {
    .sidebar {
        padding-right: 0;
        padding-top: 40px;
    }
}

.page-sidebar {
    margin-bottom: 50px;
    padding-right: 18px;
}

.widget {
    background-color: #eef5f8;
    padding: 36px;
    border-radius: 8px;
    margin-bottom: 48px;
}

.widget:last-child {
    margin-bottom: 40px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .widget {
        padding: 30px;
    }
}

.widget ol, .widget ul {
    margin-bottom: 0;
}

.widget .widget-instagram-feed {
    width: 315px;
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    .widget .widget-instagram-feed {
        width: 220px;
    }
}

@media (max-width: 374px) {
    .widget .widget-instagram-feed {
        width: 200px;
    }
}

.widget .widget-instagram-feed .single-instagram-feed {
    width: 96px;
    height: 96px;
}

.widget .wp-block-heading, .widget .widget-title, .widget .wp-block-search__label {
    font-size: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #393d48;
    position: relative;
}

.widget .wp-block-heading:before, .widget .widget-title:before, .widget .wp-block-search__label:before {
    content: "\f0da";
    font-family: "Font Awesome 6 Pro";
    margin-left: 6px;
    color: var(--secondary);
    font-size: 18px;
}

.widget .wp-block-social-links .wp-social-link {
    padding: 0;
    margin: 0;
    line-height: 0;
}

.widget .wp-block-social-links .wp-social-link a {
    width: 44px;
    height: 44px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.widget .has-avatars.has-dates.has-excerpts.wp-block-latest-comments li.wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta, .widget .has-dates.has-excerpts.wp-block-latest-comments li.wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    font-weight: 500;
}

.widget .has-avatars.has-dates.has-excerpts.wp-block-latest-comments li.wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-author, .widget .has-avatars.has-dates.has-excerpts.wp-block-latest-comments li.wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-link, .widget .has-dates.has-excerpts.wp-block-latest-comments li.wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-author, .widget .has-dates.has-excerpts.wp-block-latest-comments li.wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-link {
    font-weight: 500;
}

.widget .wp-block-latest-comments li.wp-block-latest-comments__comment, .widget .wp-block-latest-posts li.wp-block-latest-comments__comment {
    margin-bottom: 0;
}

.widget .wp-block-latest-comments li .wp-block-latest-comments__comment-meta, .widget .wp-block-latest-comments li .wp-block-latest-posts__post-title, .widget .wp-block-latest-posts li .wp-block-latest-comments__comment-meta, .widget .wp-block-latest-posts li .wp-block-latest-posts__post-title {
    line-height: 28px;
    font-weight: 400;
}

.widget .wp-block-latest-comments li .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-date, .widget .wp-block-latest-comments li .wp-block-latest-posts__post-title .wp-block-latest-comments__comment-date, .widget .wp-block-latest-posts li .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-date, .widget .wp-block-latest-posts li .wp-block-latest-posts__post-title .wp-block-latest-comments__comment-date {
    font-size: 14px !important;
    margin-bottom: 10px;
    position: relative;
}

.widget .wp-block-latest-comments li .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-date:before, .widget .wp-block-latest-comments li .wp-block-latest-posts__post-title .wp-block-latest-comments__comment-date:before, .widget .wp-block-latest-posts li .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-date:before, .widget .wp-block-latest-posts li .wp-block-latest-posts__post-title .wp-block-latest-comments__comment-date:before {
    content: "\f274";
    font-family: "Font Awesome 6 Pro";
    padding-left: 8px;
}

.widget .wp-block-latest-comments li a, .widget .wp-block-latest-posts li a {
    color: #5e6f92;
}

.widget .wp-block-latest-comments li a.wp-block-latest-comments__comment-author, .widget .wp-block-latest-comments li a.wp-block-latest-comments__comment-link, .widget .wp-block-latest-posts li a.wp-block-latest-comments__comment-author, .widget .wp-block-latest-posts li a.wp-block-latest-comments__comment-link {
    display: inline !important;
    text-transform: capitalize;
    line-height: 28px;
    font-weight: 400;
}

.widget .wp-block-latest-comments li a:hover, .widget .wp-block-latest-posts li a:hover {
    color: var(--secondary);
}

.widget .wp-block-latest-comments li .wp-block-latest-comments__comment-excerpt p, .widget .wp-block-latest-posts li .wp-block-latest-comments__comment-excerpt p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
}

.widget .social-profile {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.widget .social-profile a {
    width: 60px;
    height: 60px;
    background-color: var(--secondary);
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border-radius: 50px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 1199px) {
    .widget .social-profile a {
        width: 40px;
        height: 40px;
    }
}

.widget .social-profile a.facebook {
    background-color: #1877f2;
}

.widget .social-profile a.twitter {
    background-color: #1da1f2;
}

.widget .social-profile a.linkedin {
    background-color: #0a66c2;
}

.widget .social-profile a.instagram {
    background-color: #405de6;
}

.widget .social-profile a:hover {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: var(--primary);
}

.widget select {
    max-width: 100%;
    height: 50px;
    width: 100%;
    border: 1px solid transparent;
    padding: 0 10px;
    border-radius: 5px;
    color: #5e6f92;
    margin-bottom: 0;
}

.widget .has-login-form .login-username label, .widget .has-login-form .login-password label {
    margin-bottom: 10px;
}

.widget .has-login-form .login-username input, .widget .has-login-form .login-password input {
    max-width: 100%;
    height: 50px;
    width: 100%;
    border: 1px solid #31373f;
    padding: 0 10px;
    border-radius: 5px;
    color: #5e6f92;
}

.widget .has-login-form .login-remember label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
}

.widget .has-login-form .login-submit {
    margin-bottom: 0;
}

.widget .has-login-form .login-submit input.button-primary {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: var(--secondary);
    color: #fff;
    padding: 12px 32px;
    text-align: center;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
}

.widget .search-form {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.widget .search-form button {
    width: 62px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.widget .wp-block-search__no-button.wp-block-search input {
    border-radius: 10px;
}

.widget .wp-block-search {
    margin-bottom: -2px;
}

.widget .wp-block-search, .widget .search-form {
    display: inline-block;
    width: 100%;
    overflow: hidden;
}

.widget .wp-block-search .wp-block-search__inside-wrapper, .widget .search-form .wp-block-search__inside-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 !important;
}

.widget .wp-block-search label, .widget .search-form label {
    font-size: 24px;
    display: block;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #393d48;
    font-family: "Poppins", sans-serif;
}

.widget .wp-block-search input, .widget .search-form input {
    background-color: #ffffff;
    border: 1px solid #ffffff !important;
    padding: 0 25px;
    height: 60px;
    width: 100%;
    color: #5e6f92;
    font-size: 15px;
    border-radius: 0 6px 6px 0;
}

.widget .wp-block-search button, .widget .search-form button {
    color: #ffffff;
    padding: 0 20px;
    position: relative;
    left: 0;
    background-color: var(--secondary);
    top: 0;
    height: auto;
    border-radius: 6px 0 0 6px;
    line-height: 0;
    border: 0;
    margin-right: 0 !important;
}

.widget .tagcloud, .widget .wp-block-tag-cloud {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 6px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.widget .tagcloud a, .widget .wp-block-tag-cloud a {
    color: #5e6f92;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 12px 22px;
    border-radius: 50px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    border: 1px solid #bfc5cb;
}

.widget .tagcloud a:hover, .widget .wp-block-tag-cloud a:hover {
    color: #ffffff;
    background: var(--secondary);
    border-color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget ul, .widget ol {
    margin-bottom: 0;
    padding-right: 0;
}

.widget ul li, .widget ol li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #dfe2e9;
}

.widget ul li:first-child, .widget ol li:first-child {
    padding-top: 0;
}

.widget ul li:last-child, .widget ol li:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}

.widget ul li.recentcomments .comment-author-link a:before, .widget ol li.recentcomments .comment-author-link a:before {
    display: block;
}

.widget ul li.recentcomments a, .widget ol li.recentcomments a {
    padding-right: 0;
    display: inline;
}

.widget ul li.recentcomments a:before, .widget ol li.recentcomments a:before {
    display: none;
}

.widget ul li a, .widget ol li a {
    color: #5e6f92;
    position: relative;
    display: inline-block;
    text-transform: capitalize;
    font-size: 18px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget ul li a:hover, .widget ol li a:hover {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget ul li a.rsswidget, .widget ol li a.rsswidget {
    padding-right: 0;
    font-weight: 500;
    display: block;
}

.widget ul li a.rsswidget:before, .widget ol li a.rsswidget:before {
    display: none;
}

.widget ul li ul, .widget ol li ul {
    padding-right: 25px;
    padding-top: 10px;
}

.widget ul.wp-tag-cloud li, .widget ol.wp-tag-cloud li {
    display: inline-block;
    padding: 0;
    border: 0;
}

.widget ul.wp-tag-cloud li a:before, .widget ol.wp-tag-cloud li a:before {
    display: none;
}

.widget.widget_categories ul li, .widget.widget_archive ul li {
    text-align: left;
    display: inline-block;
    width: 100%;
}

.widget.widget_categories ul li.cat-item, .widget.widget_archive ul li.cat-item {
    text-align: left;
    display: inline-block;
    width: 100%;
}

.widget.widget_categories ul li a, .widget.widget_archive ul li a {
    float: right;
}

.widget-about-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.widget-about-info .about-desc {
    text-align: right;
}

.widget-about-info .about-desc .about-title {
    font-size: 20px;
}

.widget-about-info .about-image {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.widget-about-info .social-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    padding-top: 30px;
    border-top: 1px solid #dfe2e9;
    width: 100%;
    margin-top: 10px;
}

.widget-about-info .social-profile a {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    width: 44px;
    height: 44px;
    background-color: #f3f4f8;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #393d48;
    transition: all 0.5s ease-out;
}

.widget-about-info .social-profile a:hover {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: var(--secondary);
}

.has-dates.has-author.wp-block-latest-posts .wp-block-latest-posts__post-title {
    display: inline-block;
    width: 100%;
    font-weight: 500;
}

.widget_rss .wp-block-heading:before, .widget_rss .widget-title:before {
    display: none;
}

.widget_rss .rss-widget-icon {
    margin-left: 6px;
}

.widget_rss ul li a.rsswidget {
    color: #393d48;
}

.widget_rss ul li a.rsswidget:hover {
    color: var(--secondary);
}

.wp-block-latest-posts .wp-block-rss__item-title, .wp-block-rss .wp-block-rss__item-title, .widget_rss .wp-block-rss__item-title {
    display: inline-block;
    width: 100%;
    font-weight: 500;
}

.wp-block-latest-posts .wp-block-latest-posts__post-author, .wp-block-latest-posts .wp-block-rss__item-publish-date, .wp-block-rss .wp-block-latest-posts__post-author, .wp-block-rss .wp-block-rss__item-publish-date, .widget_rss .wp-block-latest-posts__post-author, .widget_rss .wp-block-rss__item-publish-date {
    margin-left: 10px;
}

.wp-block-latest-posts .wp-block-latest-posts__post-author, .wp-block-latest-posts .wp-block-latest-posts__post-date, .wp-block-latest-posts .wp-block-rss__item-publish-date, .wp-block-latest-posts .wp-block-rss__item-author, .wp-block-latest-posts .rss-date, .wp-block-rss .wp-block-latest-posts__post-author, .wp-block-rss .wp-block-latest-posts__post-date, .wp-block-rss .wp-block-rss__item-publish-date, .wp-block-rss .wp-block-rss__item-author, .wp-block-rss .rss-date, .widget_rss .wp-block-latest-posts__post-author, .widget_rss .wp-block-latest-posts__post-date, .widget_rss .wp-block-rss__item-publish-date, .widget_rss .wp-block-rss__item-author, .widget_rss .rss-date {
    display: inline-block;
    font-size: 14px !important;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
}

.wp-block-latest-posts .wp-block-latest-posts__post-date:before, .wp-block-latest-posts .wp-block-rss__item-publish-date:before, .wp-block-latest-posts .rss-date:before, .wp-block-rss .wp-block-latest-posts__post-date:before, .wp-block-rss .wp-block-rss__item-publish-date:before, .wp-block-rss .rss-date:before, .widget_rss .wp-block-latest-posts__post-date:before, .widget_rss .wp-block-rss__item-publish-date:before, .widget_rss .rss-date:before {
    content: "\f274";
    font-family: "Font Awesome 6 Pro";
    padding-left: 8px;
}

.wp-block-latest-posts .wp-block-latest-posts__post-author:before, .wp-block-latest-posts .wp-block-rss__item-author:before, .wp-block-rss .wp-block-latest-posts__post-author:before, .wp-block-rss .wp-block-rss__item-author:before, .widget_rss .wp-block-latest-posts__post-author:before, .widget_rss .wp-block-rss__item-author:before {
    content: "\f007";
    font-family: "Font Awesome 6 Pro";
    padding-left: 8px;
}

.wp-block-latest-posts .wp-block-latest-posts__post-excerpt, .wp-block-latest-posts .wp-block-rss__item-excerpt, .wp-block-latest-posts .rssSummary, .wp-block-rss .wp-block-latest-posts__post-excerpt, .wp-block-rss .wp-block-rss__item-excerpt, .wp-block-rss .rssSummary, .widget_rss .wp-block-latest-posts__post-excerpt, .widget_rss .wp-block-rss__item-excerpt, .widget_rss .rssSummary {
    margin-top: 10px;
    margin-bottom: 0;
}

.widget_latest_post ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    border: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.widget_latest_post ul li:hover .latest-post-thumb img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget_latest_post ul li:last-child {
    margin-bottom: 0;
}

.widget_latest_post ul li a {
    padding-right: 0;
}

.widget_latest_post ul li a:before {
    display: none;
}

.widget_latest_post ul li .latest-post-thumb {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    overflow: hidden;
    width: 84px;
    height: 84px;
    border-radius: 6px;
}

.widget_latest_post ul li .latest-post-thumb img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget_latest_post ul li .latest-post-desc .latest-post-title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    color: #393d48;
    font-family: "Poppins", sans-serif;
}

.widget_latest_post ul li .latest-post-desc .latest-post-title a {
    font-size: 18px;
    line-height: 24px;
    color: #393d48;
    position: relative;
    text-transform: capitalize;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    font-family: "Poppins", sans-serif;
}

.widget_latest_post ul li .latest-post-desc .latest-post-title a:hover {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget_latest_post ul li .latest-post-desc .latest-post-meta {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 400;
    gap: 8px;
    color: #5e6f92;
}

.widget-instagram-feed {
    display: grid;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    width: 240px;
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    .widget-instagram-feed {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 374px) {
    .widget-instagram-feed {
        grid-template-columns: repeat(2, 1fr);
    }
}

.widget-instagram-feed .single-instagram-feed {
    border-radius: 5px;
    width: 72px;
    height: 72px;
    overflow: hidden;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.widget-instagram-feed .single-instagram-feed:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget-instagram-feed .single-instagram-feed img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.widget_promotion {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    background-color: #020a27;
}

.widget_promotion .wp-block-heading {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 10px;
}

.widget_promotion .secondary-btn {
    display: inline-block;
    background: var(--primary);
    color: #393d48;
    padding: 12px 104px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
}

.widget_promotion .secondary-btn i {
    color: #fff;
    font-size: 16px;
}

.widget_promotion .secondary-btn:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: #ffffff;
    border-color: #ffffff;
}

.widget_promotion p {
    color: rgba(255, 255, 255, 0.53);
    margin-bottom: 32px;
}

.single-post-item .single-post-meta {
    gap: 32px;
}

@media (max-width: 1199px) {
    .service-sidebar {
        margin-top: 70px;
    }
}

@media (max-width: 767px) {
    .service-sidebar {
        margin-top: 56px !important;
    }
}

.service-sidebar .widget:last-child {
    margin-bottom: 0;
}

.widget_service_categories {
    background-color: transparent;
    padding: 0;
}

.widget_service_categories ul li {
    border: 0;
    padding-bottom: 5px !important;
}

.widget_service_categories ul li a {
    background: #ebf4f8;
    border-radius: 8px;
    padding: 21px 24px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 16px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #393d48;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
}

.widget_service_categories ul li a i {
    color: var(--secondary);
    font-size: 24px;
    font-weight: 700;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .widget_service_categories ul li a {
        padding: 10px;
    }
}

.widget_service_categories ul li a:hover {
    background: var(--primary);
    color: #ffffff;
}

.widget_service_categories ul li a:hover i {
    color: #ffffff;
}

.widget_service_categories ul li.active a {
    background: var(--primary);
    color: #ffffff;
}

.widget_service_categories ul li.active a i {
    color: #ffffff;
}

.widget_service_promotion {
    padding: 44px 40px;
    text-align: center;
    background-color: #020a27;
    position: relative;
    overflow: hidden;
}

.widget_service_promotion .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--secondary);
    background: linear-gradient(-135deg, rgba(0, 91, 172, 0.83) 0%, var(--primary) 100%);
}

.widget_service_promotion .widget-subtitle {
    position: relative;
    z-index: 0;
}

.widget_service_promotion .widget-subtitle p {
    color: var(--primary);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 60px;
    font-weight: 700;
    margin: 0;
}

.widget_service_promotion .promotion-card {
    position: relative;
    z-index: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 50px;
}

.widget_service_promotion .promotion-card h4 {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin: 0;
}

.widget_service_promotion .promotion-card .secondary-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 18px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.widget_service_promotion .promotion-card .secondary-btn .icon {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: var(--secondary);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.widget_service_promotion .promotion-card .secondary-btn .icon i {
    color: #ffffff;
}

.widget_service_promotion .promotion-card .secondary-btn .btn-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
}

.widget_service_promotion .promotion-card .secondary-btn .btn-text p {
    margin: 0;
    text-align: start;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}

.widget_service_promotion .promotion-card .secondary-btn .btn-text h4 {
    margin: 0;
    text-align: start;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .widget_service_promotion {
        padding: 44px 25px;
    }
}

.widget_service_brochura {
    padding: 38px;
    position: relative;
    overflow: hidden;
    border: 1px solid #dfe2e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
    background-color: #ffffff;
}

.widget_service_brochura .widget-title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px;
}

.widget_service_brochura .widget-title-wrapper .widget-subtitle {
    color: var(--primary);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
}

.widget_service_brochura .widget-title-wrapper .widget-title {
    color: #393d48;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
}

.widget_service_brochura .widget-title-wrapper .widget-title:before {
    display: none;
}

.widget_service_brochura .button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
}

.widget_service_brochura .button-wrapper .btn-text {
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.widget_service_brochura .button-wrapper .pdf {
    padding: 18px;
    border-radius: 6px;
    background-image: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
}

.widget_service_brochura .button-wrapper .doc {
    padding: 18px;
    border-radius: 6px;
    background: var(--secondary);
    background: linear-gradient(-135deg, rgba(0, 91, 172, 0.83) 0%, var(--primary) 100%);
}

.comment-respond {
    margin-top: 70px;
}

.blog-coment-title h2, .blog-coment-title .wp-block-comments-title, .wp-block-comments h2, .wp-block-comments .wp-block-comments-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 30px;
}

.wp-block-comments .wp-block-comment-template {
    padding-right: 0;
}

.wp-block-comments .wp-block-comment-template .wp-block-comment-reply-link {
    margin-top: 15px;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns {
    padding: 50px;
    border: 1px solid #dfe2e9;
    border-radius: 8px;
    margin-bottom: 24px;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-column:first-child {
    -ms-flex-preferred-size: 80px !important;
    flex-basis: 80px !important;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-avatar {
    width: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-avatar img {
    width: 100%;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-comment-author-name a {
    font-size: 21px;
    margin-bottom: 5px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #393d48;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-comment-edit-link a {
    color: var(--secondary);
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-comment-date a, .wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-comment-edit-link a {
    color: #9ba8c3;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-comment-reply-link a {
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: #393d48;
    border: 1px solid var(--primary);
    padding: 4px 26px;
    display: inline-block;
}

.wp-block-comments .wp-block-comment-template li .wp-block-columns .wp-block-comment-reply-link a:hover {
    background-color: var(--primary);
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.latest-comments .comment-respond {
    margin-top: 80px;
}

.latest-comments li .children .comments-box {
    margin-right: 50px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .latest-comments li .children .comments-box {
        margin-right: 0;
    }
}

.latest-comments li .children .children .comments-box {
    margin-right: 100px;
    border-top: 1px solid #D2D2D2;
}

.latest-comments li .comments-box {
    padding: 50px;
    border: 1px solid #dfe2e9;
    border-radius: 8px;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .latest-comments li .comments-box {
        padding: 30px;
    }
}

.latest-comments li .comments-box .comments-avatar {
    float: right;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .latest-comments li .comments-box .comments-avatar {
        float: inherit;
        margin-bottom: 20px;
    }
}

.latest-comments li .comments-box .comments-avatar img {
    margin-left: 30px;
    width: 100px;
    border-radius: 50px;
}

.latest-comments li .comments-box .comments-text {
    overflow: hidden;
}

.latest-comments li .comments-box .comments-text p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #5e6f92;
}

.latest-comments li .comments-box .comments-text .avatar-name {
    margin-bottom: 10px;
    position: relative;
}

.latest-comments li .comments-box .comments-text .avatar-name h5 {
    font-size: 21px;
    margin-bottom: 5px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.latest-comments li .comments-box .comments-text .avatar-name span {
    color: #9ba8c3;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .latest-comments li .comments-box .comments-text .avatar-name span {
        display: inline-block;
        width: 100%;
    }
}

.latest-comments li .comments-box .comments-text .comment-reply-link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: #393d48;
    border: 1px solid var(--primary);
    padding: 4px 26px;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .latest-comments li .comments-box .comments-text .comment-reply-link {
        position: unset;
        margin-top: 10px;
    }
}

.latest-comments li .comments-box .comments-text .comment-reply-link:hover {
    background-color: var(--primary);
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.post-comments .latest-comments > ul {
    list-style: none;
    padding-right: 0;
    margin-bottom: 0;
}

.comments-text > ul, .comments-text > ol {
    margin-bottom: 20px;
}

ul.children {
    list-style: none;
}

.post-comments-form .contacts-message {
    margin-top: 10px;
}

.post-comments-title .sub-title {
    font-size: 18px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: var(--primary);
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}

.post-comments-title h2 {
    font-size: 36px;
    line-height: 28px;
    font-weight: 700;
    color: #393d48;
    margin-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .post-comments-title h2 {
        font-size: 28px;
        margin-bottom: 20px;
    }
}

.post-comments-title h2 small {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: var(--secondary);
}

.comment-respond {
    background-color: #eef5f8;
    padding: 50px;
    border-radius: 18px;
    position: relative;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .comment-respond {
        padding: 30px;
    }
}

.comment-respond p {
    margin: 0;
}

.comment-respond .contacts-email, .comment-respond .contacts-name, .comment-respond .single-input-field {
    margin-bottom: 12px;
}

.comment-respond label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #778196;
    margin-bottom: 12px;
}

.comment-respond .checkbox-field-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 12px;
    padding: 15px 0;
}

.comment-respond .checkbox-field-wrapper p {
    margin: 0;
}

.comment-respond .checkbox-field-wrapper .single-checkbox-field {
    margin-bottom: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.comment-respond .comment-form-cookies-consent {
    display: none;
}

.comment-respond input[type=number] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.comment-respond input, .comment-respond select {
    padding: 0 20px;
    height: 60px;
    width: 100%;
    color: #778196;
    opacity: 1;
    border: 1px solid transparent !important;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(4, 43, 77, 0.04);
    box-shadow: 0px 10px 30px 0px rgba(4, 43, 77, 0.04);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    font-family: "Roboto", sans-serif !important;
}

.comment-respond *::-webkit-input-placeholder {
    color: #778196;
}

.comment-respond *::-moz-placeholder {
    color: #778196;
    opacity: 1;
}

.comment-respond *:-ms-input-placeholder {
    color: #778196;
}

.comment-respond *::-ms-input-placeholder {
    color: #778196;
}

.comment-respond *::placeholder {
    color: #778196;
}

.comment-respond textarea {
    resize: vertical;
    padding: 20px;
    width: 100%;
    color: #778196;
    border: 1px solid transparent !important;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(4, 43, 77, 0.04);
    box-shadow: 0px 10px 30px 0px rgba(4, 43, 77, 0.04);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    font-family: "Roboto", sans-serif !important;
    height: 192px;
    margin-bottom: 12px;
}

.comment-respond input:focus, .comment-respond textarea:focus, .comment-respond .form-select:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: var(--secondary) !important;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.comment-respond.style-two {
    margin: 0;
    background-color: transparent;
    padding: 82px 6px 82px 88px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .comment-respond.style-two {
        padding: 50px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .comment-respond.style-two {
        padding: 30px;
    }
}

.comment-respond.style-two .post-comments-title h2 {
    color: #ffffff;
}

.comment-respond.style-two label {
    color: #c7cdda;
}

.comment-respond.style-two .nice-select:after {
    border-color: transparent transparent #c7cdda transparent;
}

.comment-respond.style-two input[type=radio]::before, .comment-respond.style-two input[type=checkbox]::before {
    background-color: transparent;
}

.comment-respond.style-two .single-input-field p {
    margin-bottom: 0;
}

.comment-respond.style-two .single-input-field input, .comment-respond.style-two .single-input-field select, .comment-respond.style-two .single-input-field textarea {
    background-color: transparent;
    color: #c7cdda;
    border-color: #739bbf !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.comment-respond.style-two .single-input-field .nice-select {
    color: #c7cdda;
    border-color: #739bbf !important;
}

.comment-respond.style-two .single-input-field input:focus, .comment-respond.style-two .single-input-field textarea:focus, .comment-respond.style-two .single-input-field .form-select:focus, .comment-respond.style-two .single-input-field .nice-select:focus {
    border-color: var(--secondary) !important;
}

.comment-respond.style-two .single-input-field *::-webkit-input-placeholder {
    color: #c7cdda;
}

.comment-respond.style-two .single-input-field *::-moz-placeholder {
    color: #c7cdda;
    opacity: 1;
}

.comment-respond.style-two .single-input-field *:-ms-input-placeholder {
    color: #c7cdda;
}

.comment-respond.style-two .single-input-field *::-ms-input-placeholder {
    color: #c7cdda;
}

.comment-respond.style-two .single-input-field *::placeholder {
    color: #c7cdda;
}

.comment-respond.style-two .checkbox-field-wrapper .single-checkbox-field .form-check-input, .comment-respond.style-two .checkbox-field-wrapper .wpcf7-list-item .form-check-input {
    background-color: transparent;
    border-color: #739bbf !important;
}

.comment-respond.style-two .checkbox-field-wrapper .single-checkbox-field input[type=checkbox], .comment-respond.style-two .checkbox-field-wrapper .single-checkbox-field input[type=radio], .comment-respond.style-two .checkbox-field-wrapper .wpcf7-list-item input[type=checkbox], .comment-respond.style-two .checkbox-field-wrapper .wpcf7-list-item input[type=radio] {
    border-color: #739bbf !important;
}

.comment-respond.style-two .checkbox-field-wrapper .single-checkbox-field input[type=checkbox]:checked, .comment-respond.style-two .checkbox-field-wrapper .single-checkbox-field input[type=radio]:checked, .comment-respond.style-two .checkbox-field-wrapper .wpcf7-list-item input[type=checkbox]:checked, .comment-respond.style-two .checkbox-field-wrapper .wpcf7-list-item input[type=radio]:checked {
    border-color: var(--secondary) !important;
}

.comment-respond.style-three {
    margin: 0;
}

.comment-respond.style-three input, .comment-respond.style-three select, .comment-respond.style-three textarea {
    border: 1px solid rgb(223, 226, 233) !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.comment-respond.style-three input:focus, .comment-respond.style-three textarea:focus, .comment-respond.style-three .form-select:focus, .comment-respond.style-three .nice-select:focus {
    border-color: var(--secondary) !important;
}

.team-member-card {
    position: relative;
}

.team-member-card:hover .image:before {
    height: 250%;
    -webkit-transition: all 900ms linear;
    transition: all 900ms linear;
    background-color: transparent;
}

.team-member-card:hover .image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.team-member-card .image {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

.team-member-card .image:before {
    position: absolute;
    width: 200%;
    height: 0%;
    right: 50%;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
    content: "";
    z-index: 1;
}

.team-member-card .image img {
    width: 100%;
    height: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.team-member-card .content-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 36px;
    width: 100%;
    bottom: -36px;
    z-index: 2;
}

.team-member-card .content-wrapper .content {
    width: 100%;
    padding: 30px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--secondary);
    z-index: 1;
    gap: 5px;
}

.team-member-card .content-wrapper .content .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    margin: 0;
}

.team-member-card .content-wrapper .content .desc {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #c7cdda;
    font-family: "Poppins", sans-serif;
    margin: 0;
}

.team-member-card .content-wrapper .social {
    background: var(--primary);
    padding: 18px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 22px;
    width: 94%;
    margin-top: -60px;
    z-index: -1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.team-member-card .content-wrapper .social a:hover {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.team-member-card .content-wrapper .social i {
    color: #ffffff;
}

.team-member-card:hover .content-wrapper .content {
    z-index: 1;
}

.team-member-card:hover .content-wrapper .social {
    margin-top: 0;
    z-index: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .team-member-card {
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-member-card {
        margin-bottom: 50px;
    }
}

.team-member-slider-wrapper {
    position: relative;
}

.team-member-slider-wrapper .slick-list {
    padding-bottom: 88px;
    margin: 0 -55px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .team-member-slider-wrapper .slick-list {
        padding-bottom: 25px;
        margin: unset;
    }
}

.team-member-slider-wrapper .slick-list .slick-slide {
    margin: 0 55px;
}

@media (max-width: 1199px) {
    .team-member-slider-wrapper .slick-list .slick-slide {
        margin: 0 14px;
    }
}

.team-member-slider-wrapper .slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
}

.team-member-slider-wrapper .slick-dots li {
    display: inline-block;
    line-height: 0;
    outline: 2px solid transparent;
    border: 5px solid transparent;
    border-radius: 50px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.team-member-slider-wrapper .slick-dots li button {
    font-size: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    background-color: rgb(94, 111, 146);
    opacity: 0.478;
    border-radius: 50px;
    position: relative;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.team-member-slider-wrapper .slick-dots li.slick-active {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    outline: 2px solid rgb(252, 133, 91);
}

.team-member-slider-wrapper .slick-dots li.slick-active button {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two {
    background-color: #ffffff;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 30px;
}

.portfolio-style-two .image .content-wrapper {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two .image .content-wrapper .content .content-inner {
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two:hover .image .content-wrapper .content .btn {
    left: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two:hover .image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two .image {
    overflow: hidden;
    position: relative;
}

.portfolio-style-two .image img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    width: 100%;
}

.portfolio-style-two .content-wrapper {
    padding: 12px;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two .content-wrapper .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
    gap: 10px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

.portfolio-style-two .content-wrapper .content .content-inner {
    width: 100%;
    background-color: var(--secondary);
    opacity: 0.9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    padding: 18px 10px;
    margin-bottom: -100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two .content-wrapper .content .sub-title {
    color: var(--primary);
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
}

.portfolio-style-two .content-wrapper .content .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 0;
    color: #ffffff;
}

.portfolio-style-two .content-wrapper .content .btn {
    position: absolute;
    top: 0;
    left: -100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two .content-wrapper .content .btn a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 60px;
    height: 60px;
    background-color: var(--primary);
    border-radius: 50px;
    color: #ffffff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-style-two .content-wrapper .content .btn a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background-color: var(--secondary);
    color: #ffffff;
}

.portfolio-slider-wrapper {
    position: relative;
}

.portfolio-slider-wrapper .slick-list {
    margin: 0 -12px;
}

.portfolio-slider-wrapper .slick-list .slick-slide {
    margin: 0 12px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-slider-wrapper .slick-list .slick-slide.slick-active {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-slider-wrapper button.portfolio-arrow-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 99;
    background-color: #ffffff;
    border: 1px solid var(--primary);
    cursor: pointer;
    top: -90px;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

@media (max-width: 991px) {
    .portfolio-slider-wrapper button.portfolio-arrow-btn {
        top: unset;
        bottom: -50px;
    }
}

.portfolio-slider-wrapper button.portfolio-arrow-btn.prev-btn {
    left: 40px;
}

@media (max-width: 991px) {
    .portfolio-slider-wrapper button.portfolio-arrow-btn.prev-btn {
        left: unset;
    }
}

@media (max-width: 767px) {
    .portfolio-slider-wrapper button.portfolio-arrow-btn.prev-btn {
        left: unset;
        right: 10px;
    }
}

.portfolio-slider-wrapper button.portfolio-arrow-btn.next-btn {
    left: 0;
}

@media (max-width: 991px) {
    .portfolio-slider-wrapper button.portfolio-arrow-btn.next-btn {
        left: unset;
        right: 50px;
    }
}

@media (max-width: 767px) {
    .portfolio-slider-wrapper button.portfolio-arrow-btn.next-btn {
        left: 30px;
        right: 50px;
    }
}

.portfolio-slider-wrapper button.portfolio-arrow-btn i {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    color: #393d48;
    font-size: 16px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.portfolio-slider-wrapper button.portfolio-arrow-btn:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    background: var(--secondary);
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
    border-color: var(--primary);
}

.portfolio-slider-wrapper button.portfolio-arrow-btn:hover i {
    color: #ffffff;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.about-us-area.style-1 {
    padding-top: 140px;
    padding-bottom: 165px;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .about-us-area.style-1 {
        padding-bottom: 144px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .about-us-area.style-1 {
        padding-bottom: 144px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-area.style-1 {
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-us-area.style-1 {
        padding-top: 42px;
        padding-bottom: 70px;
    }
}

.about-us-area.style-1 .about-info-card .about-info-content {
    padding-right: 110px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-us-area.style-1 .about-info-card .about-info-content {
        padding-right: 70px;
    }
}

@media (max-width: 1199px) {
    .about-us-area.style-1 .about-info-card .about-info-content {
        padding-right: 0;
        padding-top: 160px;
    }
}

@media (max-width: 767px) {
    .about-us-area.style-1 .about-info-card .about-info-content {
        padding-top: 150px;
    }
}

@media (max-width: 650px) {
    .about-us-area.style-1 .about-info-card .about-info-content {
        padding-top: 100px;
    }
}

.counter-up-area.style-1 {
    padding-top: 138px;
    padding-bottom: 138px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--secondary);
    z-index: -1;
}

@media (max-width: 991px) {
    .counter-up-area.style-1 {
        padding-top: 280px;
        padding-bottom: 100px;
    }
}

@media (max-width: 767px) {
    .counter-up-area.style-1 {
        padding-top: 150px;
        padding-bottom: 70px;
    }
}

.squre-shape-wrapper {
    position: absolute;
    width: 100%;
    height: 340px;
    overflow: hidden;
    margin-top: -170px;
}

.counter-squre-shape {
    width: 245px;
    height: 245px;
    border-radius: 18px;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--primary) 100%);
    -webkit-transform: translateY(-50%) rotate(124deg);
    transform: translateY(-50%) rotate(124deg);
    left: -135px;
    top: 50%;
    position: absolute;
}

@media (max-width: 991px) {
    .counter-squre-shape {
        width: 100px;
        height: 100px;
        left: -50px;
        bottom: -50px;
    }
}

.feature-area.style-1 {
    margin-top: -74px;
}

@media (max-width: 1199px) {
    .feature-area.style-1 {
        margin-top: 0;
        padding-top: 100px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .feature-area.style-1 {
        padding-top: 70px !important;
    }
}

.feature-area.style-1 .feature-area-wrapper {
    background-color: #ffffff;
    position: relative;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 90px 0px rgba(2, 24, 93, 0.08);
    box-shadow: 0px 5px 90px 0px rgba(2, 24, 93, 0.08);
    padding: 0 27px;
}

@media (max-width: 991px) {
    .feature-area.style-1 .feature-area-wrapper {
        padding: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .feature-area.style-1 .feature-area-wrapper {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature-area.style-1 .feature-area-wrapper {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.feature-area.style-1 .feature-area-wrapper .row div:last-child .info-card .divider {
    display: none;
}

.service-area.style-1 {
    padding-top: 138px;
    padding-bottom: 120px;
}

.service-area.style-1.empty-area-title {
    padding-top: 144px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-area.style-1 {
        padding-top: 100px !important;
        padding-bottom: 76px;
    }
}

@media (max-width: 767px) {
    .service-area.style-1 {
        padding-top: 70px !important;
        padding-bottom: 46px;
    }
}

.service-area.style-1 .section-title {
    margin-bottom: 60px;
}

.process-step-area {
    background-color: #00285cb5;
    padding-top: 138px;
}

@media (max-width: 991px) {
    .process-step-area {
        padding-top: 100px;
        padding-bottom: 76px;
    }
}

@media (max-width: 767px) {
    .process-step-area {
        padding-top: 70px;
        padding-bottom: 42px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .process-step-area {
        padding-bottom: 67px;
    }
}

.process-step-area .section-title {
    margin-bottom: 95px;
}

@media (max-width: 767px) {
    .process-step-area .section-title {
        margin-bottom: 50px;
    }
}

.process-image {
    margin-bottom: -70px;
    position: relative;
    text-align: center;
    z-index: 99;
    -webkit-animation: MoveLeftRight 2s linear infinite alternate;
    animation: MoveLeftRight 2s linear infinite alternate;
}

.appointment-area.style-1 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 144px 0;
    position: relative;
    z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .appointment-area.style-1 {
        padding-bottom: 127px;
    }
}

@media (max-width: 991px) {
    .appointment-area.style-1 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .appointment-area.style-1 {
        padding: 70px 0;
    }
}

.appointment-area.style-1 .accordion-wrapper {
    padding-right: 120px;
}

@media (max-width: 1399px) {
    .appointment-area.style-1 .accordion-wrapper {
        padding-right: 0;
        margin-top: 80px;
    }
}

.appointment-squre-shape {
    width: 200px;
    height: 200px;
    border-radius: 18px;
    background: var(--secondary);
    background: linear-gradient(-45deg, var(--secondary) 0%, var(--primary) 100%);
    -webkit-transform: translateY(-50%) rotate(-27deg);
    transform: translateY(-50%) rotate(-27deg);
    right: -36px;
    top: 50%;
    position: absolute;
    z-index: 9;
}

@media (max-width: 991px) {
    .appointment-squre-shape {
        width: 100px;
        height: 100px;
        right: -50px;
    }
}

.blue-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 56, 105, 0.8);
    right: 0;
    top: 0;
    z-index: -1;
}

.brand-slider-area.style-1 {
    padding-top: 93px;
    padding-bottom: 93px;
}

@media (max-width: 767px) {
    .brand-slider-area.style-1 {
        padding: 70px 0;
    }
}

.latest-posts-area.style-1 {
    padding-top: 138px;
    padding-bottom: 110px;
}

@media (max-width: 991px) {
    .latest-posts-area.style-1 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .latest-posts-area.style-1 {
        padding-top: 70px;
        padding-bottom: 36px;
    }
}

.latest-posts-area.style-1 .section-title {
    margin-bottom: 70px;
}

@media (max-width: 767px) {
    .latest-posts-area.style-1 .section-title {
        margin-bottom: 50px;
    }
}

.testimonial-slider-area.style-1 {
    padding-top: 138px;
    padding-bottom: 124px;
}

@media (max-width: 991px) {
    .testimonial-slider-area.style-1 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .testimonial-slider-area.style-1 {
        padding: 70px 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
    .testimonial-slider-area.style-1 .section-title .title br {
        display: none;
    }
}

.testimonial-slider-area.style-1 .testimonial-slider-wrapper {
    padding-right: 94px;
    margin-top: -40px;
    margin-bottom: -40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-slider-area.style-1 .testimonial-slider-wrapper {
        padding-right: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-slider-area.style-1 .testimonial-slider-wrapper {
        padding-right: 0;
    }
}

.counter-up-area.style-2 {
    position: relative;
    padding-top: 142px;
    padding-bottom: 138px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-up-area.style-2 {
        background-color: var(--secondary);
        margin: 100px 0;
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-up-area.style-2 {
        padding: 70px 0;
    }
}

@media (max-width: 550px) {
    .counter-up-area.style-2 {
        padding-bottom: 0 !important;
    }
}

.counter-up-area.style-2:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    top: 0;
    left: -75%;
    -webkit-transform: skewX(22deg);
    transform: skewX(22deg);
}

@media (max-width: 550px) {
    .counter-up-area.style-2:before {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-up-area.style-2:before {
        display: none;
    }
}

.counter-up-area.style-2 .counter-area-two-inner {
    padding-top: 144px;
    padding-bottom: 140px;
    position: relative;
    background-color: var(--secondary);
    border-radius: 18px 0 0 18px;
}

@media (max-width: 550px) {
    .counter-up-area.style-2 .counter-area-two-inner {
        border-radius: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-up-area.style-2 .counter-area-two-inner {
        background-color: transparent;
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .counter-up-area.style-2 .counter-area-two-inner {
        padding: 70px 0;
    }
}

.counter-up-area.style-2 .counter-area-two-inner:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--secondary);
    top: 0;
    right: -100%;
}

.process-step-area.style-2 {
    padding-top: 363px;
    padding-bottom: 60px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .process-step-area.style-2 {
        padding-top: 340px;
        padding-bottom: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .process-step-area.style-2 {
        padding-bottom: 40px;
    }
}

.process-step-area.style-2 .section-title {
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .process-step-area.style-2 .section-content {
        margin-top: 20px;
    }
}

.triangle-shape-top {
    position: absolute;
    right: 0;
    top: -80px;
    border-top: 350px solid var(--secondary);
    border-left: 140px solid transparent;
    z-index: 1;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .triangle-shape-top {
        display: none;
    }
}

.triangle-shape-bottom {
    position: absolute;
    left: 0;
    bottom: -115px;
    border-bottom: 240px solid var(--primary);
    border-right: 100px solid transparent;
    border-left: 0 solid transparent;
    width: 170px;
    -webkit-animation: MoveUpDown 2s linear infinite alternate;
    animation: MoveUpDown 2s linear infinite alternate;
}

@media (max-width: 550px) {
    .triangle-shape-bottom {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .triangle-shape-bottom {
        bottom: -100px;
        border-bottom: 135px solid var(--primary);
        width: 170px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .triangle-shape-bottom {
        bottom: -100px;
        border-bottom: 135px solid var(--primary);
        width: 170px;
    }
}

.triangle-border-shape {
    position: absolute;
    width: 230px;
    height: 400px;
    top: 85px;
    right: -150px;
    border-top: 1px solid var(--primary);
    border-left: 1px solid var(--primary);
    -webkit-transform: skewX(22deg);
    transform: skewX(22deg);
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .triangle-border-shape {
        display: none;
    }
}

.appointment-area.style-2 {
    margin-bottom: -261px;
    position: relative;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appointment-area.style-2 {
        margin-top: 80px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .appointment-area.style-2 {
        margin-top: 70px;
    }
}

.appointment-area.style-2 .form-image-mr {
    padding-left: 56px !important;
}

.appointment-area.style-2 .form-image-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
}

.appointment-area.style-2 .appointment-area-two-inner {
    margin: 0;
}

.faq-area.style-1 {
    padding-top: 138px;
    padding-bottom: 144px;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .faq-area.style-1 {
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .faq-area.style-1 {
        padding: 70px 0;
    }

    .faq-area.style-1 .promotional-banner-widget {
        margin-top: 0;
    }
}

.faq-area.style-1 .accordion-wrapper {
    padding-right: 120px;
}

@media (max-width: 991px) {
    .faq-area.style-1 .accordion-wrapper {
        padding-right: 0;
        padding-bottom: 80px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .faq-area.style-1 .section-title .title br {
        display: none;
    }
}

@media only screen and (min-width: 991px) and (max-width: 1024px) {
    .faq-area.style-1 .promotional-banner-widget {
        margin-top: -400px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .faq-area.style-1 .promotional-banner-widget {
        margin-top: -320px;
    }
}

.triangle-image-shape-wrapper {
    position: absolute;
    width: 830px;
    top: -155px;
    right: 0;
    z-index: -1;
}

@media only screen and (min-width: 991px) and (max-width: 1024px) {
    .triangle-image-shape-wrapper {
        right: -340px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .triangle-image-shape-wrapper {
        right: -240px;
    }
}

@media (max-width: 991px) {
    .triangle-image-shape-wrapper {
        display: none;
    }
}

.triangle-image-shape {
    -webkit-clip-path: polygon(0 0, 64% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 64% 0, 100% 100%, 0% 100%);
}

.triangle-image-shape-border {
    position: absolute;
    top: -30px;
    right: 0;
    -webkit-animation: MoveUpDown 2s linear infinite alternate;
    animation: MoveUpDown 2s linear infinite alternate;
}

.brand-slider-area.style-2 {
    padding-top: 110px;
    padding-bottom: 110px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .brand-slider-area.style-2 {
        padding: 70px 0;
    }
}

.testimonial-slider-area.style-2 {
    padding-top: 138px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-slider-area.style-2 {
        padding: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-slider-area.style-2 {
        padding: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-slider-area.style-2 {
        padding-top: 0;
    }
}

.testimonial-slider-area.style-2 .section-title {
    margin-bottom: 10px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-slider-area.style-2 .section-title {
        margin-bottom: 0;
    }
}

.testimonial-triangle-image-wrapper {
    width: 916px;
    position: absolute;
    top: 0;
    right: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-triangle-image-wrapper {
        width: 620px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-triangle-image-wrapper {
        margin-bottom: 70px !important;
    }
}

@media (max-width: 1199px) {
    .testimonial-triangle-image-wrapper {
        width: 100%;
        position: relative;
        margin-bottom: 100px;
    }
}

.testimonial-triangle-image {
    -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-triangle-image {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-triangle-image {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.testimonial-triangle-shape {
    position: absolute;
    left: 154px;
    bottom: 70px;
    width: 190px;
    height: 190px;
    background-color: var(--secondary);
    -webkit-transform: skewX(22deg);
    transform: skewX(22deg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-triangle-shape {
        display: none;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .testimonial-triangle-shape {
        display: none;
    }
}

.about-us-area.style-2 {
    padding-top: 140px;
    padding-bottom: 144px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-us-area.style-2 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .about-us-area.style-2 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.about-us-area.style-2 .about-info-card .about-info-content {
    padding-right: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .about-us-area.style-2 .about-info-card .about-info-content .text {
        padding-left: 120px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .about-us-area.style-2 .about-info-card .about-info-content {
        padding-right: 50px;
    }
}

@media (max-width: 1199px) {
    .about-us-area.style-2 .about-info-card .about-info-content {
        padding-right: 0;
        padding-top: 300px;
    }
}

@media only screen and (min-width: 651px) and (max-width: 767px) {
    .about-us-area.style-2 .about-info-card .about-info-content {
        padding-top: 150px;
    }
}

@media (max-width: 651px) {
    .about-us-area.style-2 .about-info-card .about-info-content {
        padding-top: 100px;
    }
}

@media (max-width: 991px) {
    .form-image-wrapper {
        height: 350px;
        overflow: hidden;
    }
}

.form-image-wrapper img {
    width: 100%;
}

.latest-posts-area.style-2 {
    padding-top: 104px;
    padding-bottom: 108px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .latest-posts-area.style-2 {
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .latest-posts-area.style-2 {
        padding-top: 70px;
        padding-bottom: 50px;
    }
}

.latest-posts-area.style-2 .section-title {
    margin-bottom: 70px;
}

@media (max-width: 991px) {
    .latest-posts-area.style-2 .section-title {
        margin-bottom: 50px;
    }
}

.latest-posts-area.style-2 .home-two-post-card-padding {
    padding-right: 68px;
}

@media (max-width: 1199px) {
    .latest-posts-area.style-2 .home-two-post-card-padding {
        padding-right: 12px;
    }
}

.team-slider-area.style-1 {
    padding-top: 138px;
    padding-bottom: 144px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-slider-area.style-1 {
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .team-slider-area.style-1 {
        padding: 70px 0;
    }
}

.service-slider-area {
    padding-bottom: 144px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .service-slider-area {
        padding-top: 70px;
        padding-bottom: 80px;
    }
}

@media (max-width: 767px) {
    .service-slider-area .section-title .desc br {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-slider-area .section-title .desc br {
        display: none;
    }
}

.why-choose-us-area.style-1 {
    padding-top: 138px;
    padding-bottom: 103px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .why-choose-us-area.style-1 {
        padding-top: 100px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .why-choose-us-area.style-1 {
        padding-top: 70px;
        padding-bottom: 34px;
    }
}

.why-choose-us-area.style-1 .section-title {
    padding-left: 100px;
}

@media (max-width: 1399px) {
    .why-choose-us-area.style-1 .section-title {
        padding-left: 12px;
    }
}

@media (max-width: 991px) {
    .why-choose-us-area.style-1 .section-title {
        margin-bottom: 70px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us-area.style-1 .section-title .title br {
        display: none;
    }
}

.why-choose-us-area.style-1 .icon-card-left-padding {
    padding-right: 134px;
}

@media (max-width: 1399px) {
    .why-choose-us-area.style-1 .icon-card-left-padding {
        padding-right: 12px;
    }
}

.feature-area.style-2 {
    margin-top: -65px;
    position: relative;
    z-index: 2;
}

.feature-area.style-2 .feature-area-inner {
    background-color: #2c3237;
    border-radius: 8px;
    overflow: hidden;
}

.why-choose-us-area.style-2 {
    padding-top: 138px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 991px) {
    .why-choose-us-area.style-2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .why-choose-us-area.style-2 {
        padding-top: 0 !important;
        padding-bottom: 20px !important;
    }
}

.why-choose-us-area.style-2 .section-title {
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .why-choose-us-area.style-2 .section-title {
        padding-top: 70px;
        padding-bottom: 20px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .why-choose-us-area.style-2 .section-title .title br {
        display: none;
    }
}

.why-choose-us-area.style-2 .why-choose-card-wrapper {
    padding-right: 110px;
}

@media (min-width: 1024px) {
    .why-choose-us-area.style-2 .why-choose-card-wrapper {
        padding-right: 50px;
    }
}

@media (max-width: 991px) {
    .why-choose-us-area.style-2 .why-choose-card-wrapper {
        padding-right: 0;
    }
}

.about-us-area.style-3 {
    padding-top: 138px;
    padding-bottom: 144px;
}

@media (max-width: 991px) {
    .about-us-area.style-3 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .about-us-area.style-3 {
        padding: 70px 0;
    }
}

.about-us-area.style-3 .section-title.another-title {
    display: none;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .about-us-area.style-3 .section-title.another-title {
        display: block !important;
    }
}

@media (max-width: 991px) {
    .about-us-area.style-3 .section-title {
        display: none;
    }
}

@media (max-width: 1199px) {
    .about-us-area.style-3 .about-info-card.style-three .about-info-content {
        padding-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .about-us-area.style-3 .about-info-card.style-three .about-info-wrapper .about-info-img {
        display: none;
    }
}

@media (max-width: 767px) {
    .about-us-area.style-3 .about-info-card.style-three .about-info-wrapper .about-info-img {
        display: block !important;
    }
}

.why-choose-three-area {
    background-color: #393d48;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.portfolio-area.style-1 {
    padding-top: 138px;
    padding-bottom: 114px;
}

@media (max-width: 991px) {
    .portfolio-area.style-1 {
        padding-top: 100px;
        padding-bottom: 80px;
    }
}

@media (max-width: 767px) {
    .portfolio-area.style-1 {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}

.latest-posts-area.style-3 {
    padding-top: 138px;
    padding-bottom: 85px;
}

@media (max-width: 991px) {
    .latest-posts-area.style-3 {
        padding-top: 100px;
        padding-bottom: 80px;
    }
}

@media (max-width: 991px) {
    .latest-posts-area.style-3 {
        padding-top: 70px;
        padding-bottom: 25px;
    }
}

.pricing-area.style-1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 138px;
    padding-bottom: 144px;
}

@media (max-width: 991px) {
    .pricing-area.style-1 {
        padding-top: 100px;
        padding-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .pricing-area.style-1 {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}

.price-card-padding {
    padding: 0 50px;
}

@media (max-width: 1399px) {
    .price-card-padding {
        padding: 0 12px;
    }
}

.service-area.style-2 {
    padding-top: 138px;
    padding-bottom: 90px;
}

@media (max-width: 991px) {
    .service-area.style-2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .service-area.style-2 .section-title {
        margin-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .service-area.style-2 {
        padding-top: 70px;
        padding-bottom: 20px;
    }

    .service-area.style-2 .row.mb-70 {
        margin-bottom: 50px;
    }
}

.faq-area.style-2 {
    padding-top: 216px;
    padding-bottom: 144px;
}

@media (max-width: 1199px) {
    .faq-area.style-2 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .faq-area.style-2 {
        padding: 70px 0;
    }
}

@media (max-width: 1199px) {
    .faq-area.style-2 .accordion-wrapper {
        padding-left: 0 !important;
        padding-bottom: 80px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .faq-area.style-2 .section-title .title br {
        display: none;
    }
}

.testimonial-area.style-3 {
    padding-top: 138px;
    padding-bottom: 134px;
}

@media (max-width: 991px) {
    .testimonial-area.style-3 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .testimonial-area.style-3 {
        padding: 70px 0;
    }

    .testimonial-area.style-3 .section-title {
        margin-bottom: 30px;
    }
}

@media (max-width: 1199px) {
    .testimonial-area.style-3 .accordion-wrapper {
        padding-left: 0 !important;
        padding-bottom: 80px;
    }
}

.brand-slider-area.style-3 {
    padding-bottom: 62px;
}

.about-page-about-us-area {
    padding-top: 140px;
    padding-bottom: 154px;
}

@media (max-width: 991px) {
    .about-page-about-us-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .about-page-about-us-area {
        padding: 70px 0;
    }
}

.about-page-about-us-area .about-info-card .about-info-content {
    padding-right: 120px;
}

@media (max-width: 1199px) {
    .about-page-about-us-area .about-info-card .about-info-content {
        padding-right: 0;
        padding-top: 330px;
    }
}

@media (max-width: 767px) {
    .about-page-about-us-area .about-info-card .about-info-content {
        padding-top: 150px;
    }
}

@media (max-width: 650px) {
    .about-page-about-us-area .about-info-card .about-info-content {
        padding-top: 110px;
    }
}

.counter-up-area.style-3 {
    padding-top: 134px;
    padding-bottom: 138px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (max-width: 991px) {
    .counter-up-area.style-3 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .counter-up-area.style-3 {
        padding: 70px 0;
    }
}

.faq-area.style-3 {
    padding-top: 138px;
    padding-bottom: 142px;
}

@media (max-width: 991px) {
    .faq-area.style-3 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .faq-area.style-3 {
        padding: 70px 0;
    }
}

.faq-area.style-3 .section-title {
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .faq-area.style-3 .section-title {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
    .faq-area.style-3 .section-title .title br {
        display: none;
    }
}

.faq-area.style-3 .camera-popup-scan.style-three {
    margin-right: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
    .faq-area.style-3 .camera-popup-scan.style-three .video-popup-image {
        height: 707px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .faq-area.style-3 .camera-popup-scan.style-three {
        margin-right: 90px;
    }
}

@media (max-width: 1199px) {
    .faq-area.style-3 .camera-popup-scan.style-three {
        margin-right: 30px;
        margin-top: 80px;
    }
}

@media (max-width: 767px) {
    .faq-area.style-3 .camera-popup-scan.style-three {
        margin-right: 0 !important;
        margin-top: 40px !important;
    }
}

.timeline-area.style-1 {
    background-color: #f3f8fc;
    padding-top: 138px;
    padding-bottom: 144px;
}

@media (max-width: 991px) {
    .timeline-area.style-1 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .timeline-area.style-1 {
        padding: 70px 0;
    }
}

.about-page-testimonial-area {
    background-color: #f3f7fc;
    padding-top: 140px;
    padding-bottom: 124px;
}

@media (max-width: 991px) {
    .about-page-testimonial-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .about-page-testimonial-area {
        padding: 70px 0;
    }
}

.about-page-testimonial-area .testimonial-slider-wrapper {
    margin-top: -40px;
    margin-bottom: -40px;
}

.brand-area.style-1 {
    padding-top: 138px;
    padding-bottom: 132px;
}

@media (max-width: 991px) {
    .brand-area.style-1 {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .brand-area.style-1 {
        padding: 70px 0;
    }
}

.contact-info-area {
    padding-top: 108px;
    padding-bottom: 133px;
}

@media (max-width: 991px) {
    .contact-info-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .contact-info-area {
        padding-top: 35px;
        padding-bottom: 70px;
    }
}

.contact-form-area .comment-respond {
    margin-top: 45px;
}

.contact-map-area {
    padding-top: 144px;
    padding-bottom: 134px;
}

@media (max-width: 991px) {
    .contact-map-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .contact-map-area {
        padding: 70px 0;
    }
}

.contact-map-area .map-wodget iframe {
    height: 380px;
}

.contact-title {
    text-align: center;
}

.contact-title h2 {
    font-size: 48px;
    color: #393d48;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-title h2 {
        font-size: 48px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-title h2 {
        font-size: 38px;
        line-height: 44px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .contact-title h2 {
        font-size: 32px;
        line-height: 30px;
    }
}

.pricing-page-price-card-wrapper {
    background-color: #eef5f8;
    padding-top: 140px;
    padding-bottom: 140px;
}

@media (max-width: 991px) {
    .pricing-page-price-card-wrapper {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .pricing-page-price-card-wrapper {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}

.pricing-page-why-choose-us {
    padding-top: 122px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (max-width: 1199px) {
    .pricing-page-why-choose-us {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media (max-width: 767px) {
    .pricing-page-why-choose-us {
        padding-top: 70px;
        padding-bottom: 20px;
    }
}

.pricing-page-why-choose-us .why-choose-card-wrapper {
    padding-right: 110px;
}

@media (max-width: 991px) {
    .pricing-page-why-choose-us .why-choose-card-wrapper {
        padding-right: 0;
    }
}

.faq-area.style-4 {
    padding-top: 218px;
    padding-bottom: 130px;
}

@media (max-width: 1199px) {
    .faq-area.style-4 {
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .faq-area.style-4 {
        padding: 70px 0;
    }
}

.faq-area.style-4 .accordion-wrapper.style-four .accordion-list-item {
    margin-bottom: 0;
}

.pricing-accordion-wrapper {
    padding-right: 120px;
}

@media only screen and (min-width: 991px) and (max-width: 1399px) {
    .pricing-accordion-wrapper {
        padding-right: 50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pricing-accordion-wrapper {
        padding-right: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .pricing-accordion-wrapper {
        padding-right: 0;
        margin-top: 30px;
    }
}

.faq-tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 12px;
    padding-right: 0;
    list-style: none;
}

.faq-tab li {
    display: inline-block;
}

.faq-tab li.active a {
    color: #ffffff;
    background: var(--primary);
}

.faq-tab li a {
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    color: #393d48;
    border-radius: 6px;
    padding: 26px 35px;
    background: #eef5f8;
    display: inline-block;
    width: 100%;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.faq-tab li a:hover {
    color: #ffffff;
    background: var(--primary);
}

.service-page-service-area {
    padding-top: 144px;
    padding-bottom: 120px;
}

@media (max-width: 991px) {
    .service-page-service-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .service-page-service-area {
        padding-top: 70px;
        padding-bottom: 46px;
    }
}

.process-step-area.style-3 {
    padding-top: 138px;
    padding-bottom: 59px;
}

@media (max-width: 991px) {
    .process-step-area.style-3 {
        padding-top: 100px;
        padding-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .process-step-area.style-3 {
        padding-top: 70px;
        padding-bottom: 10px;
    }
}

.process-step-area.style-3 .section-title {
    margin-bottom: 0;
}

.why-choose-us-area.style-3 {
    padding-top: 144px;
    padding-bottom: 124px;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .why-choose-us-area.style-3 {
        padding-bottom: 98px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .why-choose-us-area.style-3 {
        padding-top: 144px;
        padding-bottom: 94px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .why-choose-us-area.style-3 {
        padding: 100px 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .why-choose-us-area.style-3 {
        padding: 70px 0;
    }
}

.why-choose-us-area.style-3 .service-list-wrapper {
    padding-left: 100px;
    padding-top: 65px;
}

@media only screen and (min-width: 1199px) and (max-width: 1399px) {
    .why-choose-us-area.style-3 .service-list-wrapper {
        padding-left: 30px;
        padding-top: 0;
    }
}

@media (max-width: 1199px) {
    .why-choose-us-area.style-3 .service-list-wrapper {
        padding-top: 0;
        padding-left: 20px;
    }
}

@media (max-width: 767px) {
    .why-choose-us-area.style-3 .service-list-wrapper {
        padding-left: 0;
        padding-top: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .why-choose-us-area.style-3 {
        padding: 65px 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1499px) {
    .why-choose-us-area.style-3 .section-title .title br {
        display: none;
    }
}

.why-choose-us-area.style-3 .service-image {
    position: relative;
}

@media (max-width: 430px) {
    .why-choose-us-area.style-3 .service-image {
        height: 350px !important;
    }
}

@media (max-width: 1199px) {
    .why-choose-us-area.style-3 .service-image {
        height: 500px;
        overflow: hidden;
        border-radius: 12px;
    }
}

@media (max-width: 1199px) {
    .why-choose-us-area.style-3 .service-image img {
        width: 100%;
        position: absolute;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        right: 0;
        top: 0;
    }
}

.service-details-wrapper {
    padding-top: 144px;
    padding-bottom: 130px;
}

@media (max-width: 991px) {
    .service-details-wrapper {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .service-details-wrapper {
        padding: 70px 0;
    }
}

.service-details-wrapper .details-main {
    padding-right: 25px;
}

@media (max-width: 1199px) {
    .service-details-wrapper .details-main {
        padding-right: 0;
    }
}

.service-details-wrapper .details-main img {
    border-radius: 12px;
}

.service-details-wrapper .details-main .accordion-list-item {
    margin-bottom: 0;
}

.service-details-wrapper .details-main .list-item {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .service-details-wrapper .details-main .row.mt-55 {
        margin-top: 10px;
    }
}

.service-details-wrapper .details-main .service-feature-img {
    margin-bottom: 44px;
}

@media (max-width: 767px) {
    .service-details-wrapper .details-main .service-feature-img {
        margin-bottom: 30px;
    }
}

.service-details-wrapper .faq-title {
    font-size: 30px;
}

.service-details-wrapper .benefits-image {
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .service-details-wrapper .benefits-image img {
        width: 100%;
    }
}

.team-page-area {
    padding-top: 59px;
    padding-bottom: 181px;
}

@media (max-width: 991px) {
    .team-page-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .team-page-area {
        padding-top: 40px;
        padding-bottom: 55px;
    }
}

.team-space {
    padding: 0 37px;
    margin-top: 30px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .team-space {
        padding: 0 10px;
        margin-top: 85px;
    }
}

@media screen and (min-width: 1200px) {
    .team-space {
        margin-top: 85px;
    }
}

.blog-grid-area {
    padding-top: 140px;
    padding-bottom: 144px;
}

@media (max-width: 991px) {
    .blog-grid-area {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .blog-grid-area {
        padding: 70px 0;
    }
}

.blog-load-btn {
    padding: 20px 35px;
    background: var(--primary);
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    border-radius: 6px;
}

@media (max-width: 767px) {
    .blog-load-btn {
        margin-top: 0;
    }
}

.blog-load-btn:hover {
    background-color: var(--secondary);
    color: #ffffff;
}

.footer {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

@media (max-width: 1199px) {
    .footer .mt-50.mb-50 {
        margin: 0;
    }
}

.footer .footer-sec {
    padding-top: 265px;
}

@media (max-width: 767px) {
    .footer .footer-sec {
        padding-top: 210px;
    }
}

.footer .footer-bg {
    position: absolute;
    bottom: 30px;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    z-index: -1;
}

.footer-widget-title {
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    line-height: 26px;
    color: #ffffff;
    position: relative;
    margin-bottom: 26px;
    font-weight: 700;
}

.footer-widget {
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .footer-widget {
        margin-bottom: 50px;
    }
}

.footer-widget ul {
    padding-right: 0;
}

.footer-widget.widget_boomdevs-latest-posts-footer {
    margin-top: -5px;
}

@media (max-width: 767px) {
    .footer-widget.zoom-instagram-widget {
        margin-bottom: 0;
        margin-top: -10px;
    }
}

@media (max-width: 767px) {
    .footer-widget.widget_boomdevs_contact_info_widget {
        margin-bottom: 18px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
    .footer-widget .widget-instagram-feed {
        width: 170px;
    }
}

.footer-widget .footer-widget-info {
    padding-left: 30px;
}

@media (max-width: 1199px) {
    .footer-widget .footer-widget-info {
        padding-left: 0;
    }
}

.footer-widget .footer-widget-info p {
    color: #c7cdda;
    margin-bottom: 30px;
}

.footer-widget .footer-widget-info .footer-logo {
    margin-bottom: 30px;
    display: inline-block;
}

.footer-widget .footer-widget-info .info-form-and-social-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 50px;
}

.footer-widget .footer-widget-info .info-form-and-social-icon .subscribe-form-widget {
    border-radius: 6px;
}

.footer-widget .footer-widget-info .info-form-and-social-icon .subscribe-form-widget .single-field input {
    height: 60px;
}

.footer-widget .footer-widget-info .info-form-and-social-icon .social-profile {
    margin-top: 0;
}

.footer-widget .footer-widget-info .social-profile {
    gap: 20px;
    margin-top: 30px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer-widget .footer-widget-info .info-form-and-social-icon {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start;
        gap: 30px;
    }
}

.footer-widget.widget_nav_menu ul {
    margin-top: -5px;
    padding-right: 0;
    margin-bottom: 0;
}

.footer-widget.widget_nav_menu li {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
}

.footer-widget.widget_nav_menu li:last-child {
    padding-top: 0;
    padding-bottom: 0;
}

.footer-widget.widget_nav_menu li a {
    color: #c7cdda;
    text-transform: capitalize;
    font-size: 18px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    position: relative;
}

.footer-widget.widget_nav_menu li a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: var(--secondary);
}

.footer-widget .footer-widget-contact p {
    color: #c7cdda;
}

.footer-widget .footer-widget-contact .footer-contact ul {
    list-style: none;
}

.footer-widget .footer-widget-contact .footer-contact ul li {
    margin-bottom: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 24px;
    gap: 20px;
}

.footer-widget .footer-widget-contact .footer-contact ul li:first-child {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.footer-widget .footer-widget-contact .footer-contact ul li:first-child .contact-icon {
    margin-top: 5px;
}

.footer-widget .footer-widget-contact .footer-contact ul li:last-child {
    margin-bottom: 0;
}

.footer-widget .footer-widget-contact .footer-contact ul li .contact-icon {
    color: #ffffff;
    border-radius: 50px;
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.footer-widget .footer-widget-contact .footer-contact ul li .contact-text {
    color: #c7cdda;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
}

.footer-widget .footer-widget-contact .footer-contact ul li .contact-text a:hover span {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.footer-widget .footer-widget-contact .footer-contact ul li .contact-text span, .footer-widget .footer-widget-contact .footer-contact ul li .contact-text p, .footer-widget .footer-widget-contact .footer-contact ul li .contact-text a {
    color: #c7cdda;
    font-size: 18px;
    line-height: 24px;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.footer-widget .widget_latest_post ul li .latest-post-thumb {
    width: 60px;
    height: 60px;
}

.footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 700;
}

.footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title a {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    position: relative;
    text-transform: capitalize;
    font-weight: 700;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title a:hover {
    color: var(--secondary);
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-meta {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 400;
    gap: 8px;
    color: #c7cdda;
}

.footer-bottom-menu ul {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-right: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .footer-bottom-menu ul {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.footer-bottom-menu ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #c7cdda;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.footer-bottom-menu ul li a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: var(--secondary);
}

.footer-bottom-area {
    padding: 57px 0;
}

@media (max-width: 991px) {
    .footer-bottom-area {
        padding: 30px 0;
    }
}

@media (max-width: 767px) {
    .footer-bottom-area .copyright-text {
        margin-bottom: 20px;
    }
}

.footer-bottom-area .copyright-text p {
    color: #c7cdda;
    margin-bottom: 0;
}

.footer-bottom-area .copyright-text p a {
    color: #ffffff;
    font-weight: 700;
}

.footer-bottom-area .copyright-text p a:hover {
    color: var(--secondary);
}

.footer-bottom-area .credit-text p {
    color: #c7cdda;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 10px;
}

.footer-bottom-area .credit-text a {
    color: var(--secondary);
}

.subscribe-form-area {
    position: relative;
    z-index: 99;
    margin-bottom: -65px;
}

.subscribe-area {
    background-color: var(--primary);
    border-radius: 18px;
    padding: 64px 70px 64px 90px;
    z-index: 2;
    position: relative;
    overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .subscribe-area {
        padding: 40px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .subscribe-area {
        padding: 40px;
    }
}

.subscribe-area .triangle-shape {
    width: 280px;
    height: 280px;
    background-color: var(--secondary);
    border-radius: 28px;
    position: absolute;
    left: -32px;
    bottom: -80px;
    -webkit-transform: rotate(-39deg);
    transform: rotate(-39deg);
    z-index: -1;
}

.subscribe-area .shape-bg {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.subscribe-area .shape-bg img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
}

.subscribe-area .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.subscribe-area .content-wrapper .short-title {
    font-size: 21px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 0;
}

.subscribe-area .content-wrapper .subscribe-title {
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: 48px;
    line-height: 60px;
}

@media (max-width: 375px) {
    .subscribe-area .content-wrapper .subscribe-title {
        font-size: 34px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .subscribe-area .content-wrapper .subscribe-title {
        font-size: 40px;
        line-height: 42px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .subscribe-area .content-wrapper .subscribe-title {
        font-size: 36px;
        line-height: 42px;
    }
}

.subscribe-area .content-wrapper .text {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 0;
    margin-top: 12px;
}

.subscribe-area .subscribe-inner-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .subscribe-area .subscribe-inner-area {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.subscribe-area .subscribe-inner-area .subscribe-form-wrapper {
    margin-top: -12px;
}

.subscribe-area .subscribe-inner-area .content-wrapper, .subscribe-area .subscribe-inner-area .subscribe-form-wrapper {
    width: 50%;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .subscribe-area .subscribe-inner-area .content-wrapper, .subscribe-area .subscribe-inner-area .subscribe-form-wrapper {
        width: 100%;
    }
}

.subscribe-form-widget {
    position: relative;
}

.subscribe-form-widget .mc4wp-form-fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    border-radius: 12px;
    overflow: hidden;
}

.subscribe-form-widget .single-field {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.subscribe-form-widget .single-field input {
    color: #778196;
    height: 72px;
    padding: 0 30px;
    font-size: 18px;
    border-radius: 50px;
    width: 100%;
}

.subscribe-form-widget .single-field *::-webkit-input-placeholder {
    color: #778196;
}

.subscribe-form-widget .single-field *::-moz-placeholder {
    color: #778196;
    opacity: 1;
}

.subscribe-form-widget .single-field *:-ms-input-placeholder {
    color: #778196;
}

.subscribe-form-widget .single-field *::-ms-input-placeholder {
    color: #778196;
}

.subscribe-form-widget .single-field *::placeholder {
    color: #778196;
}

.subscribe-form-widget .submit-btn {
    background-color: #ffffff;
    color: var(--primary);
    background: -webkit-linear-gradient(#e30008, var(--primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    padding: 0 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 9px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
}

.subscribe-form-widget .mc4wp-response {
    position: relative;
    top: 15px;
}

.subscribe-form-widget .mc4wp-response .mc4wp-alert.mc4wp-error p, .subscribe-form-widget .mc4wp-response .mc4wp-alert.mc4wp-success p, .subscribe-form-widget .mc4wp-response .mc4wp-alert.mc4wp-notice p {
    color: #fff;
    position: relative;
}

.social-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}

.social-profile a {
    color: #ffffff;
    font-size: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.social-profile a:hover {
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    color: var(--secondary);
}

.map-wodget iframe {
    width: 100%;
    height: 320px;
    border-radius: 12px;
}

.footer.style-two .footer-sec {
    padding-top: 225px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-two .footer-sec {
        padding-top: 180px;
    }
}

.footer.style-two .subscribe-form-wrapper p {
    color: #c7cdda;
    margin-bottom: 30px;
}

.footer.style-two .subscribe-form-wrapper .subscribe-form-widget {
    border-radius: 6px;
}

.footer.style-two .subscribe-form-wrapper .subscribe-form-widget .single-field input {
    height: 60px;
}

.footer-triangle-image-wrapper {
    position: absolute;
    width: 730px;
    top: 0;
    height: 100%;
    right: 0;
    z-index: -1;
}

@media only screen and (min-width: 781px) and (max-width: 991px) {
    .footer-triangle-image-wrapper {
        width: 120%;
    }
}

@media (max-width: 780px) {
    .footer-triangle-image-wrapper {
        width: 100%;
    }
}

.footer-triangle-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 65% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 65% 100%, 0 100%);
}

@media only screen and (min-width: 781px) and (max-width: 991px) {
    .footer-triangle-image {
        -webkit-clip-path: polygon(0 0, 100% 0, 57% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 57% 100%, 0 100%);
    }
}

@media (max-width: 780px) {
    .footer-triangle-image {
        -webkit-clip-path: none;
        clip-path: none;
    }
}

.footer-triangle-image:before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--secondary);
    opacity: 0.9;
    z-index: 1;
}

.footer-triangle-image img {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.footer.style-three .footer-sec {
    padding-top: 130px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-three .footer-sec {
        padding-top: 70px;
    }
}

.footer.style-three .footer-sec .footer-widget.widget_nav_menu ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 26px;
    -moz-column-gap: 26px;
    column-gap: 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .footer.style-three .footer-sec .footer-widget .footer-widget-info {
        padding-left: 305px !important;
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1399px) {
    .footer.style-three .footer-sec .footer-widget .footer-widget-info {
        padding-left: 500px !important;
        margin-bottom: 50px;
    }

}
@keyframes moveLeft {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-10px);
    }
}

@keyframes moveRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
}

