@media screen and (max-width: 990px) {

    /*.menu-sidebar-area {*/
    /*  left: 0;*/
    /*}*/

    .main-menu > ul {
        display: none;
    }
    .mobile-nav-menu > .main-menu > ul{
        display: flex;
        flex-direction: column;
    }



}
