.scanner {
    width: 400px;
    height: 200px;
    position: fixed;
    top: -50%;
    left: -50%;
    transform: translate(-50% , -50%);
    z-index: 9999;
    background-color: #fff;
}

.show-scanner {
    top: 50%;
    left: 50%;
}